import { Toast, ToastBody, ToastContainer, ToastHeader } from "react-bootstrap";
import { connect } from "react-redux";
import { DISMISS_ALERT } from "../../store/actions";
import { bootstrapSelector } from "../../store/selectors";
import { AlertData, AlertLevel, AppState } from "../../types";

const toastVariants: Record<AlertLevel, string> = {
  success: "success",
  error: "danger",
  warn: "warning",
  info: "primary",
};
const toastClasses: Record<AlertLevel, string> = {
  success: "text-white",
  error: "text-white",
  warn: "",
  info: "text-white",
};

interface AlertsProps {
  alerts: AlertData[];
  dismissAlert: (id: string) => void;
}

const Alerts = ({ alerts, dismissAlert }: AlertsProps) => {
  return (
    <ToastContainer
      id="toasts"
      containerPosition="fixed"
      position="bottom-center"
      className="p-3"
    >
      {alerts.map(({ id, title, message, level = "info" }) => (
        <Toast
          key={id}
          show={true}
          bg={toastVariants[level]}
          onClose={() => dismissAlert(id)}
          className={toastClasses[level]}
        >
          <ToastHeader>
            <strong className="me-auto">{title}</strong>
          </ToastHeader>
          <ToastBody>{message}</ToastBody>
        </Toast>
      ))}
    </ToastContainer>
  );
};

function mapStateToProps(state: AppState) {
  return {
    alerts: bootstrapSelector(state).alerts,
  };
}

function mapDispatchToProps(dispatch: (action: any) => void) {
  return {
    dismissAlert: (id: string) => dispatch({ type: DISMISS_ALERT, id }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Alerts);
