import { Middleware } from "redux";
import createReduxPromiseListener from "redux-promise-listener";
import createSagaMiddleware from "redux-saga";

export const loggerMiddleware: Middleware = (api) => (next) => (action) => {
  console.log("dispatching", action);
  const result = next(action);
  console.log("next state", action.type, api.getState());
  return result;
};

export const crashReporterMiddleware: Middleware =
  (api) => (next) => (action) => {
    try {
      return next(action);
    } catch (err) {
      console.error("Caught an exception!", err, action, api.getState());
      throw err;
    }
  };

export const sagaMiddleware = createSagaMiddleware();

export const reduxPromiseListener = createReduxPromiseListener();
