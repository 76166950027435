import { SetFundraisingConditionsAction } from "../../../store/actions";
import { formatAmount } from "../../../utils";

export type SetFundraisingConditionsSummaryProps = Omit<
  SetFundraisingConditionsAction,
  "type" | "navigate"
>;

export const SetFundraisingConditionsSummary = ({
  tokenAddress,
  fundraisingToken,
  fundraisingTokenSymbol,
  tokenPrice1e18,
}: SetFundraisingConditionsSummaryProps) => (
  <div className="d-grid gap-2 text-center">
    <h4 className="mb-5">
      Please review the following details before signing the transaction.
    </h4>
    <p>
      <b>Charged Token address :</b> {tokenAddress}
    </p>
    <p>
      <b>Fundraising Token address :</b> {fundraisingToken}
    </p>
    <p>
      <b>Fundraising Token symbol :</b> {fundraisingTokenSymbol}
    </p>
    <p>
      <b>Charged Token price :</b> {formatAmount(tokenPrice1e18, 18)}{" "}
      {fundraisingTokenSymbol}
    </p>
  </div>
);

export default SetFundraisingConditionsSummary;
