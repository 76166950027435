export const EMPTY_ADDRESS = "0x0000000000000000000000000000000000000000";

export const DEV_NETWORK = "1337";

export const LIBRARIES_PER_NETWORK: Record<string, Record<string, string>> = {
  // BSC mainnet
  "56": {
    SafeMath: "0xC45B7b316FBECf860e5BF8fD754566d43E49aD84",
    AddressSet: "0x91f4573b4014F7BC654194D0d891eD024A99a690",
    StringSet: "0x33B2A9BFA7c62CCece1C21f541B198F1fB74D829",
  },
  // Polygon Mainnet
  "137": {
    SafeMath: "0xC45B7b316FBECf860e5BF8fD754566d43E49aD84",
    AddressSet: "0x91f4573b4014F7BC654194D0d891eD024A99a690",
    StringSet: "0x33B2A9BFA7c62CCece1C21f541B198F1fB74D829",
  },
  // Sepolia
  "11155111": {
    SafeMath: "0xC45B7b316FBECf860e5BF8fD754566d43E49aD84",
    AddressSet: "0x91f4573b4014F7BC654194D0d891eD024A99a690",
    StringSet: "0x33B2A9BFA7c62CCece1C21f541B198F1fB74D829",
  },
  // Polygon Amoy
  "80002": {
    SafeMath: "0xC45B7b316FBECf860e5BF8fD754566d43E49aD84",
    AddressSet: "0x91f4573b4014F7BC654194D0d891eD024A99a690",
    StringSet: "0x33B2A9BFA7c62CCece1C21f541B198F1fB74D829",
  },
  // Ganache
  [DEV_NETWORK]: {
    SafeMath: "0xE89f84E2DD16E01125b792119598dAA5fcB5F330",
    AddressSet: "0x14BEFB15958f7FFb683fC02ca8c53F015eD90FF4",
    StringSet: "0x70e6A5c1D9C2fb6B4a8B2386f1841F2128749523",
  },
};

export const DIRECTORY_PER_CHAINID: Record<string, string> = {
  "56": "0xF79A6c67E99b2135E09C3Ba0d06AE60977C1f393",
  "137": "0xF79A6c67E99b2135E09C3Ba0d06AE60977C1f393",
  "11155111": "0xF79A6c67E99b2135E09C3Ba0d06AE60977C1f393",
  "80002": "0xF79A6c67E99b2135E09C3Ba0d06AE60977C1f393",
  [DEV_NETWORK]: "0xA8CA002BF4d8253b8493b1c92Fd3055F05A2DF6B",
};
