import { BigNumber, ethers, FixedNumber } from "ethers";

export function applyDecimalsNumber(
  value: BigNumber,
  decimals: number
): FixedNumber {
  return applyDecimals(value, BigNumber.from(10).pow(decimals));
}

export function applyDecimals(
  value: BigNumber,
  divider: BigNumber
): FixedNumber {
  return FixedNumber.from(value).divUnsafe(FixedNumber.from(divider));
}

export function formatDate(date: Date): string {
  return date.toLocaleString(navigator.language, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });
}

export function parseAmount(value: string, decimals: number): BigNumber {
  value = value.replaceAll(",", "");
  if (value.match(/\.0+$/)) {
    value = value.substring(0, value.indexOf("."));
  }
  return ethers.utils.parseUnits(value, decimals);
}

export function formatAmountForInput(
  value: string | BigNumber | undefined,
  decimals: number,
  precision?: number
): string {
  if (value === undefined || value === "") return "";

  return ethers.utils.formatUnits(value, decimals);
}

export function formatAmount(
  value: number | string | BigNumber | undefined,
  decimals: number,
  precision?: number
): string {
  if (value === undefined) return "";

  let result = ethers.utils.commify(ethers.utils.formatUnits(value, decimals));
  if (result.match(/\.0+$/)) {
    result = result.substring(0, result.indexOf("."));
  }

  const decimalPlaces =
    result.indexOf(".") >= 0 ? result.length - 1 - result.indexOf(".") : 0;
  if (precision !== undefined && decimalPlaces > precision) {
    result = result.substring(0, result.length - (decimalPlaces - precision));
  }

  return result;
}
