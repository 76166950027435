import { ResponsivePie } from "@nivo/pie";
import { getThemeEntry } from "charged-token-themes";
import { BigNumber } from "ethers";
import { useMemo } from "react";
import { Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { createCTSelector } from "../../../store/selectors";
import { AppState } from "../../../types";
import { applyDecimalsNumber, formatAmount } from "../../../utils";

interface LiquidTokenPieProps {
  loading: boolean;
  tokenAddress: string;
  // token constants
  symbol: string;
  decimals: number;
  maxAllocation: BigNumber;
  // token variables
  totalTokenAllocated: BigNumber;
  totalLocked: BigNumber;
  totalSupply: BigNumber;
  // staking
  totalStakingRewards: BigNumber;
  stakedLT: BigNumber;
}

export const LiquidTokenPie = ({
  loading,
  symbol,
  decimals,
  maxAllocation,
  totalTokenAllocated,
  totalStakingRewards,
  totalLocked,
  totalSupply,
  stakedLT,
}: LiquidTokenPieProps) => {
  if (loading) {
    return (
      <div className="text-center">
        <Spinner animation="border" role="status"></Spinner>
      </div>
    );
  }

  const theme = getThemeEntry();

  const {
    withdrawnLT,
    claimed,
    staked,
    pendingRewards,
    nWithdrawnLT,
    nClaimed,
    nStaked,
    nPendingRewards,
    nTotalAllocated,
    sTotalAllocated,
    sMaximumAllocated,
    sInitiallyAllocated,
    sStakingRewards,
  } = useMemo(() => {
    const initialTokenAllocated = totalTokenAllocated.sub(totalStakingRewards);

    const withdrawnLT = totalSupply.sub(totalLocked);
    const claimed = totalTokenAllocated.sub(totalSupply);
    const staked = stakedLT;
    const pendingRewards = totalLocked.sub(stakedLT);

    const nTotalAllocated = applyDecimalsNumber(
      totalTokenAllocated,
      decimals
    ).toUnsafeFloat();
    const sTotalAllocated = formatAmount(totalTokenAllocated, decimals, 2);
    const sMaximumAllocated = formatAmount(maxAllocation, decimals, 2);

    const sInitiallyAllocated = formatAmount(
      initialTokenAllocated,
      decimals,
      2
    );
    const sStakingRewards = formatAmount(totalStakingRewards, decimals, 2);

    const nWithdrawnLT = applyDecimalsNumber(
      withdrawnLT,
      decimals
    ).toUnsafeFloat();
    const nClaimed = applyDecimalsNumber(claimed, decimals).toUnsafeFloat();
    const nStaked = applyDecimalsNumber(staked, decimals).toUnsafeFloat();
    const nPendingRewards = applyDecimalsNumber(
      pendingRewards,
      decimals
    ).toUnsafeFloat();

    return {
      withdrawnLT,
      claimed,
      staked,
      pendingRewards,
      nWithdrawnLT,
      nClaimed,
      nStaked,
      nPendingRewards,
      nTotalAllocated,
      sTotalAllocated,
      sMaximumAllocated,
      sInitiallyAllocated,
      sStakingRewards,
    };
  }, [
    maxAllocation,
    totalTokenAllocated,
    totalStakingRewards,
    totalLocked,
    totalSupply,
    stakedLT,
  ]);

  const data = useMemo(() => {
    const _data = [];
    if (!withdrawnLT.isZero()) {
      _data.push({
        id: "Withdrawn",
        label: [`Within withdrawn ${symbol}`],
        value: nWithdrawnLT,
        color: "hsl(0, 70%, 70%)",
      });
    }
    if (!claimed.isZero()) {
      _data.push({
        id: "Claimed",
        label: "Claimed",
        value: nClaimed,
        color: "hsl(60, 70%, 65%)",
      });
    }
    if (!staked.isZero()) {
      _data.push({
        id: "Staked",
        label: `Within staked ${symbol}`,
        value: nStaked,
        color: "hsl(190, 70%, 65%)",
      });
    }
    if (!pendingRewards.isZero()) {
      _data.push({
        id: "Rewards",
        label: "Pending rewards",
        value: nPendingRewards,
        color: "hsl(120, 70%, 65%)",
      });
    }
    return _data;
  }, [
    withdrawnLT,
    claimed,
    staked,
    pendingRewards,
    nWithdrawnLT,
    nClaimed,
    nStaked,
    nPendingRewards,
  ]);

  return (
    <div className="d-flex flex-column align-items-center">
      <div style={{ width: "100%", height: "300px" }}>
        <ResponsivePie
          data={data}
          margin={{ top: 20, bottom: 50 }}
          colors={{ datum: "data.color" }}
          cornerRadius={3}
          borderWidth={1}
          borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
          enableArcLabels={false}
          arcLinkLabel="label"
          arcLinkLabelsColor={theme?.dark === true ? "white" : "black"}
          arcLinkLabelsTextColor={theme?.dark === true ? "white" : "black"}
          arcLinkLabelsSkipAngle={8}
          tooltip={({ datum: { id, label, value, color } }) => (
            <div
              style={{
                borderRadius: "5px",
                padding: 12,
                color,
                background: "rgba(1,1,1,0.7)",
              }}
            >
              <b>{label}</b>
              <br />
              {`${((100 * value) / nTotalAllocated).toFixed(1)} %`} (
              {value.toFixed(2)})
            </div>
          )}
        />
      </div>
      <div className="pt-2 text-start">
        Total project tokens allocated : {sTotalAllocated}
        <ul>
          <li>
            initially : {sInitiallyAllocated} (maximum allowed :{" "}
            {sMaximumAllocated})
          </li>
          <li>staking rewards : {sStakingRewards}</li>
        </ul>
      </div>
    </div>
  );
};

function mapStateToProps(state: AppState, ownProps: any) {
  const ct = createCTSelector(ownProps.tokenAddress)(state);

  return {
    ...ownProps,
    // token constants
    symbol: ct.symbol,
    decimals: ct.decimals,
    maxAllocation: ct.maxInitialTokenAllocation,
    // token variables
    totalTokenAllocated: ct.totalTokenAllocated,
    totalLocked: ct.totalLocked,
    totalSupply: ct.totalSupply,
    // staking
    totalStakingRewards: ct.totalStakingRewards,
    stakedLT: ct.stakedLT,
  };
}

export default connect(mapStateToProps)(LiquidTokenPie);
