import useAsyncFunction from "@studysync/react-redux-promise-listener-hook";
import { BigNumber } from "ethers";
import arrayMutators from "final-form-arrays";
import createDecorator from "final-form-calculate";
import { useCallback, useMemo } from "react";
import {
  Form as BForm,
  Button,
  Col,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { Field, Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { useNavigate } from "react-router-dom";
import { read as readXLSX, utils as utilsXLSX } from "xlsx";
import { promiseListener } from "../../store";
import {
  ALLOCATIONS_CREATED,
  ALLOCATIONS_ERROR,
  CREATE_ALLOCATIONS,
} from "../../store/actions";
import {
  formatAmount,
  parseAmount,
  required,
  requiredAddressValidator,
  requiredNullableIntegerValidator,
  requiredPositiveDecimalValidator,
} from "../../utils";
import { AddressInput } from "../forms";
import { AllocateLTSummary } from "../projects/tx-summary";

interface AllocateLTFormProps {
  show: boolean;
  decimals: number;
  symbol: string;
  tokenAddress: string;
  maxAllocationAvailable: BigNumber;
  handleClose: () => void;
}

const AllocateLTForm = ({
  show,
  handleClose,
  tokenAddress,
  maxAllocationAvailable,
  symbol,
  decimals,
}: AllocateLTFormProps) => {
  const navigate = useNavigate();
  const setPayload = useCallback(
    (
      action: any,
      {
        allocations,
        areAllocationsToBeStaked,
      }: {
        allocations: { address: string; amount: string }[];
        areAllocationsToBeStaked: string;
      }
    ) => {
      const addresses = allocations.map(({ address }) => address);
      const amounts = allocations.map(({ amount }) =>
        parseAmount(amount, decimals)
      );

      return {
        ...action,
        tokenAddress,
        addresses,
        amounts,
        areAllocationsToBeStaked: areAllocationsToBeStaked === "true",
        summary: (
          <AllocateLTSummary
            decimals={decimals}
            tokenAddress={tokenAddress}
            addresses={addresses}
            amounts={amounts.map((amount) => amount.toString())}
            areAllocationsToBeStaked={areAllocationsToBeStaked === "true"}
          />
        ),
        navigate,
      };
    },
    [decimals, tokenAddress]
  );

  const asyncFuncConfig = {
    start: CREATE_ALLOCATIONS,
    resolve: ALLOCATIONS_CREATED,
    reject: ALLOCATIONS_ERROR,
    setPayload,
  };
  const asyncFunc = useAsyncFunction(asyncFuncConfig, promiseListener);

  const sumCalculator = createDecorator({
    field: "allocations",
    updates: {
      availableTokens: (
        allocationsValue: { address: string; amount: string }[]
      ) => {
        try {
          const validEntries = allocationsValue.filter(
            ({ amount }) => amount.match(/^[0-9]+(.([0-9]+)?)?$/) !== null
          );
          const remaining: BigNumber = validEntries.reduce(
            (prev, cur) => prev.sub(parseAmount(cur.amount, 18)),
            maxAllocationAvailable
          );
          return remaining.toString();
        } catch (err) {
          console.warn(err);
          return "0";
        }
      },
    },
  });

  const initialValues = useMemo(() => {
    return {
      allocations: [{ address: "", amount: "" }],
      availableTokens: maxAllocationAvailable.toString(),
    };
  }, []);

  const batchAllocation = useCallback((fields: any) => {
    // creating an input for file selection
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".csv";

    // file selected
    input.onchange = (e) => {
      // getting a hold of the file reference
      const fileInput = e.target as HTMLInputElement;

      if (fileInput === null || fileInput.files === null) {
        console.error("event target or files list is null !");
        return;
      }

      const file = fileInput.files[0];

      // setting up the reader
      const reader = new FileReader();
      reader.readAsText(file, "ascii");

      // triggered once file is loaded
      reader.onload = (readerEvent) => {
        if (readerEvent !== null && readerEvent.target !== null) {
          // raw file content
          const content = readerEvent.target.result;
          // decoded workbook
          const workbook = readXLSX(content, { type: "binary", raw: true });
          // converting first sheet to json objects array
          const data = utilsXLSX.sheet_to_json(
            workbook.Sheets[workbook.SheetNames[0]]
          );

          // clear all existing fields
          for (let i = fields.length - 1; i >= 0; i--) {
            fields.remove(i);
          }

          // add fields from csv import
          data.map((item) => {
            const { address, amount } = item as any;
            fields.push({ address, amount });
          });
        } else {
          console.error("reader event or target is null !");
        }
      };
    };

    // triggering file selector dialog
    input.click();
  }, []);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="xl"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Allocate Charged Tokens</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form
          onSubmit={asyncFunc}
          decorators={[sumCalculator]}
          mutators={{
            ...arrayMutators,
          }}
          initialValues={initialValues}
          render={({ handleSubmit, form, submitting, pristine }) => (
            <form
              onSubmit={(event) => {
                return handleSubmit(event)!
                  .then(() => handleClose())
                  .catch(console.error);
              }}
              noValidate
              className="needs-validation d-grid gap-2"
            >
              <Field
                name="availableTokens"
                validate={requiredNullableIntegerValidator}
              >
                {({ input, meta }) => (
                  <p>
                    <span
                      className={meta.error !== undefined ? "is-invalid" : ""}
                    >
                      Maximum token amount that can still be allocated :{" "}
                      {formatAmount(input.value, 18)}
                    </span>
                    {meta.error !== undefined && (
                      <span className={"invalid-feedback"}>
                        Total amount exceeds maximum !
                      </span>
                    )}
                  </p>
                )}
              </Field>

              <Field name="areAllocationsToBeStaked" validate={required}>
                {({ input, meta }) => (
                  <div className="mb-3 text-center">
                    <InputGroup hasValidation>
                      <InputGroup.Text>
                        Are allocations to be staked ?
                      </InputGroup.Text>
                      <BForm.Select
                        {...input}
                        className={`text-center ${
                          meta.touched === true
                            ? meta.error !== undefined
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                      >
                        <option></option>
                        <option value="true">Stake allocated tokens</option>
                        <option value="false">
                          Allocate tokens to users' wallets
                        </option>
                      </BForm.Select>
                      {meta.error !== undefined && meta.touched === true && (
                        <BForm.Control.Feedback type="invalid">
                          {meta.error}
                        </BForm.Control.Feedback>
                      )}
                    </InputGroup>
                  </div>
                )}
              </Field>

              <FieldArray name="allocations">
                {({ fields }) => (
                  <div className="d-grid gap-2">
                    <Row>
                      <Col xs={6}>
                        <Button
                          type="button"
                          variant="secondary"
                          style={{ width: "100%" }}
                          onClick={() => batchAllocation(fields)}
                        >
                          Add multiple allocations{" "}
                          <i className="bi bi-people"></i>
                        </Button>
                      </Col>
                      <Col xs={6}>
                        <Button
                          type="button"
                          variant="secondary"
                          style={{ width: "100%" }}
                          onClick={() =>
                            fields.push({ address: "", amount: "" })
                          }
                        >
                          Add single allocation{" "}
                          <i className="bi bi-person-plus"></i>
                        </Button>
                      </Col>
                    </Row>

                    <h6>
                      <a href="/misc/sample.csv" className="download-link">
                        Click here to download multiple allocation example file
                      </a>{" "}
                      (semi-column separated CSV format)
                    </h6>

                    {fields.map((name, index) => (
                      <Row key={index}>
                        <Col xs={7}>
                          <Field
                            name={`${name}.address`}
                            validate={requiredAddressValidator}
                          >
                            {({ input, meta }) => (
                              <AddressInput meta={meta} {...input} />
                            )}
                          </Field>
                        </Col>
                        <Col xs={4}>
                          <Field
                            name={`${name}.amount`}
                            validate={requiredPositiveDecimalValidator}
                          >
                            {({ input, meta }) => (
                              <InputGroup hasValidation>
                                <InputGroup.Text>{symbol}</InputGroup.Text>
                                <BForm.Control
                                  type="text"
                                  className={`text-end ${
                                    meta.touched === true &&
                                    (meta.error !== undefined
                                      ? "is-invalid"
                                      : "is-valid")
                                  }`}
                                  {...input}
                                />
                                {meta.error !== undefined &&
                                  meta.touched === true && (
                                    <BForm.Control.Feedback type="invalid">
                                      {meta.error}
                                    </BForm.Control.Feedback>
                                  )}
                              </InputGroup>
                            )}
                          </Field>
                        </Col>
                        <Col xs={1}>
                          <Button
                            type="button"
                            variant="danger"
                            size="sm"
                            onClick={() => fields.remove(index)}
                          >
                            <i className="bi bi-trash"></i>
                          </Button>
                        </Col>
                      </Row>
                    ))}

                    <Button
                      type="submit"
                      variant="primary"
                      size="lg"
                      disabled={
                        pristine || submitting || form.getState().invalid
                      }
                    >
                      Create allocations
                    </Button>
                  </div>
                )}
              </FieldArray>
            </form>
          )}
        ></Form>
      </Modal.Body>
    </Modal>
  );
};

export default AllocateLTForm;
