import { getThemeThumbnailsList } from "charged-token-themes";
import { useCallback } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  createCTSelector,
  createInterfaceSelector,
} from "../../store/selectors";
import { AppState } from "../../types";
import { CopyButton } from "../common";
import { LiquidTokenBalancesTable } from "./includes";

export interface LiquidTokenCardProps {
  hasInterface: boolean;
  tokenAddress: string;
  name: string;
  symbol: string;
  isPreTGE: boolean;
  projectName: string;
}

export const LiquidTokenCard = (props: LiquidTokenCardProps) => {
  const { tokenAddress, name, isPreTGE, projectName } = props;
  const navigate = useNavigate();

  const thumbnails: string[] = getThemeThumbnailsList();

  const onCardClick = useCallback(
    () => navigate(`/lt/${tokenAddress}/schedule`),
    [navigate, tokenAddress]
  );

  const caption = isPreTGE ? "Pre-TGE" : "Post-TGE";

  return (
    <Card
      onClick={onCardClick}
      className="mb-4"
      style={{
        cursor: "pointer",
      }}
    >
      {
        <Card.Img
          src={`/dist/thumbnails/${
            thumbnails.includes(projectName) ? projectName : "default"
          }.jpg`}
          variant="top"
          style={{
            width: "100%",
            height: "150px",
          }}
        />
      }
      <Card.Body className="p-4 liquid-token-card-body">
        <Row className="align-items-center">
          <Col xs="12" as="h4" className="text-center mb-3">
            <div>
              <b>{name}</b>
            </div>
            <div>
              {`(${caption})`}
              <CopyButton
                msg="Address copied to clipboard"
                value={tokenAddress}
              />
            </div>
          </Col>

          <LiquidTokenBalancesTable tokenAddress={tokenAddress} />
        </Row>
      </Card.Body>
    </Card>
  );
};

function mapStateToProps(
  state: AppState,
  ownProps: Pick<LiquidTokenCardProps, "tokenAddress">
) {
  const ct = createCTSelector(ownProps.tokenAddress)(state);
  const iface = createInterfaceSelector(ct.interfaceProjectToken)(state);

  return {
    ...ownProps,
    hasInterface: iface !== undefined,
    name: ct.name,
    symbol: ct.symbol,
    projectName: ct.projectName,
    isPreTGE:
      iface === undefined ||
      iface.dateLaunch.isSet !== true ||
      iface.dateLaunch.isFuture(),
  };
}

export default connect(mapStateToProps)(LiquidTokenCard);
