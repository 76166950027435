import { BigNumber } from "ethers";
import React, { useCallback } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Action } from "redux";
import { ClaimTokensAction, CLAIM_TOKENS } from "../../../store/actions";
import { formatAmount } from "../../../utils";

export interface ClaimSummaryProps {
  tokenAddress: string;
  unlockedPT: BigNumber;
  rewardsAmount: BigNumber;
  stakedLT: BigNumber;
  projectSymbol: string;
  claimFeesPerThousandForPT: number;
  decimals: number;
  show: boolean;
  handleClose: () => void;
  doClaim: (tokenAddress: string, navigate: (path: string) => void) => void;
}

export const ClaimSummary = ({
  tokenAddress,
  unlockedPT,
  rewardsAmount,
  claimFeesPerThousandForPT,
  stakedLT,
  projectSymbol,
  decimals,
  show,
  handleClose,
  doClaim,
}: ClaimSummaryProps) => {
  const navigate = useNavigate();
  const onSubmit = useCallback(() => {
    doClaim(tokenAddress, navigate);
    handleClose();
  }, [doClaim, handleClose, tokenAddress]);

  const claimFees = stakedLT.eq(unlockedPT)
    ? BigNumber.from(0)
    : unlockedPT
        .mul(BigNumber.from(claimFeesPerThousandForPT))
        .div(BigNumber.from(1000));

  const total = unlockedPT.add(rewardsAmount).sub(claimFees);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header>
        <Modal.Title>Claim summary</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Col xs={{ span: 8, offset: 2 }}>
          <div className="d-grid gap-2">
            <p>
              <b>Unlocked {projectSymbol} :</b>{" "}
              {formatAmount(unlockedPT, decimals)} {projectSymbol}
            </p>
            {!stakedLT.eq(unlockedPT) && (
              <p>
                <b>
                  Claiming fee ({(claimFeesPerThousandForPT / 10.0).toFixed(1)}{" "}
                  %) :
                </b>{" "}
                {formatAmount(claimFees, decimals)} {projectSymbol}
              </p>
            )}
            <p>
              <b>Rewards :</b> {formatAmount(rewardsAmount, decimals)}{" "}
              {projectSymbol}
            </p>
            <p>
              <b>Total :</b> {formatAmount(total, decimals)} {projectSymbol}
            </p>
          </div>
        </Col>

        <Row>
          <Col className="d-grid gap-2">
            <button
              type="button"
              className="btn btn-lg btn-primary mt-3"
              onClick={onSubmit}
            >
              Confirm Claim
            </button>
          </Col>
          <Col className="d-grid gap-2">
            <button
              type="button"
              className="btn btn-lg btn-secondary mt-3"
              onClick={handleClose}
            >
              Cancel
            </button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

function mapDispatchToProps(dispatch: (action: Action<any>) => void) {
  return {
    doClaim: (tokenAddress: string, navigate: (path: string) => void) => {
      dispatch({
        type: CLAIM_TOKENS,
        tokenAddress,
        navigate,
      } as ClaimTokensAction);
    },
  };
}

export default connect(null, mapDispatchToProps)(ClaimSummary);
