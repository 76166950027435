import { BigNumber } from "ethers";
import createDecorator from "final-form-calculate";
import { useCallback, useMemo } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { Field, Form } from "react-final-form";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Action } from "redux";
import { NEW_STAKING_CAMPAIGN } from "../../store/actions";
import { createCTSelector } from "../../store/selectors";
import { AppState, DateWrapper } from "../../types";
import {
  OVERFLOW,
  buildMaxAmountValidator,
  buildMaxDecimalValidator,
  computeStakingAPRFromForm,
  formatAmount,
  parseAmount,
  requiredFutureDateValidator,
  requiredFutureOrEqualDateValidator,
  requiredPositiveDecimalValidator,
} from "../../utils";
import { AmountInput, NumberInput } from "../forms";
import DateInput from "../forms/DateInput";
import { NewStakingCampaignSummary } from "../projects/tx-summary";

interface NewStakingCampaignFormProps {
  tokenAddress: string;
  decimals: number;
  symbol: string;
  stakedLT: BigNumber;
  maxStakingTokenAmount: BigNumber;
  maxStakingAPR: number;
  show: boolean;
  network: string;
  handleClose: () => void;
  createCampain: (values: any, navigate: (path: string) => void) => void;
}

// TODO : add max rewards validator
// TODO : add max APR validator

const NewStakingCampaignForm = ({
  tokenAddress,
  decimals,
  symbol,
  stakedLT,
  maxStakingTokenAmount,
  maxStakingAPR,
  show,
  network,
  handleClose,
  createCampain,
}: NewStakingCampaignFormProps) => {
  const navigate = useNavigate();
  const onSubmit = useCallback(
    (values: any) => {
      const { startDate, duration, rewards } = values;

      const finalValues = {
        tokenAddress,
        startDate: DateWrapper.fromString(startDate),
        duration: DateWrapper.days(Number(duration)),
        rewards: parseAmount(rewards, decimals),
        network,
      };

      createCampain(finalValues, navigate);

      handleClose();
    },
    [createCampain, handleClose, tokenAddress, decimals]
  );

  const rewardsValidator = useMemo(
    () => buildMaxAmountValidator(maxStakingTokenAmount.toString(), decimals),
    [maxStakingTokenAmount, decimals]
  );

  const stakingAPRValidator = useMemo(
    () => buildMaxDecimalValidator(maxStakingAPR),
    [maxStakingAPR]
  );

  const aprCalculator = createDecorator(
    {
      field: "duration",
      updates: {
        stakingAPR: (durationValue: string, allValues?: any) => {
          return computeStakingAPRFromForm(
            stakedLT,
            decimals,
            durationValue,
            allValues["rewards"]
          ).toString();
        },
      },
    },
    {
      field: "rewards",
      updates: {
        stakingAPR: (rewardsValue: string, allValues?: any) => {
          return computeStakingAPRFromForm(
            stakedLT,
            decimals,
            allValues["duration"],
            rewardsValue
          ).toString();
        },
      },
    }
  );

  const initialValues = useMemo(() => {
    return {
      stakingAPR: "0",
    };
  }, []);

  return (
    <Modal show={show} onHide={handleClose} centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>New Staking Campaign</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form
          onSubmit={onSubmit}
          decorators={[aprCalculator]}
          initialValues={initialValues}
          render={({ handleSubmit, form, submitting, pristine }) => (
            <form
              onSubmit={handleSubmit}
              noValidate
              className="needs-validation d-grid gap-2"
            >
              <Row>
                <Col xs="6">
                  <Field
                    name="startDate"
                    validate={requiredFutureDateValidator}
                  >
                    {({ input, meta }) => (
                      <DateInput
                        validator={requiredFutureOrEqualDateValidator}
                        label="Start date"
                        meta={meta}
                        {...input}
                      />
                    )}
                  </Field>
                </Col>
                <Col xs="6">
                  <Field
                    name="duration"
                    validate={requiredPositiveDecimalValidator}
                  >
                    {({ input, meta }) => (
                      <NumberInput
                        label="Duration (days)"
                        meta={meta}
                        {...input}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
              <Field name="rewards" validate={rewardsValidator}>
                {({ input, meta }) => (
                  <AmountInput
                    label="Total campain rewards"
                    showMaxBtn={false}
                    symbol={symbol}
                    decimals={0}
                    meta={meta}
                    {...input}
                  />
                )}
              </Field>

              <Field name="stakingAPR" validate={stakingAPRValidator}>
                {({ input, meta }) => (
                  <>
                    <p>
                      Maximum campaign rewards :{" "}
                      {`${formatAmount(
                        maxStakingTokenAmount,
                        decimals
                      )} ${symbol}`}
                    </p>
                    <p>Maximum APR : {`${maxStakingAPR.toFixed(2)} %`}</p>
                    <p>
                      <span
                        className={meta.error !== undefined ? "is-invalid" : ""}
                      >
                        Projected APR : {`${Number(input.value).toFixed(2)} %`}
                      </span>
                      {meta.error !== undefined && meta.error === OVERFLOW && (
                        <span className={"invalid-feedback"}>
                          Maximum APR exceeded !
                        </span>
                      )}
                    </p>
                  </>
                )}
              </Field>

              <button
                type="submit"
                className="btn btn-lg btn-primary mt-3"
                disabled={pristine || submitting || form.getState().invalid}
              >
                Create
              </button>
            </form>
          )}
        ></Form>
      </Modal.Body>
    </Modal>
  );
};

function mapStateToProps(
  state: AppState,
  ownProps: Pick<NewStakingCampaignFormProps, "tokenAddress">
) {
  const ct = createCTSelector(ownProps.tokenAddress)(state);

  return {
    stakedLT: ct.stakedLT,
    maxStakingTokenAmount: ct.maxStakingTokenAmount,
    maxStakingAPR: ct.maxStakingAPR,
  };
}

function mapDispatchToProps(dispatch: (action: Action<any>) => void) {
  return {
    createCampain: (values: any, navigate: (path: string) => void) => {
      dispatch({
        type: NEW_STAKING_CAMPAIGN,
        ...values,
        summary: <NewStakingCampaignSummary {...values} />,
        navigate,
      });
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewStakingCampaignForm);
