import React from "react";
import { NewStakingCampaignAction } from "../../../store/actions";
import { formatAmount } from "../../../utils";

export type NewStakingCampaignSummaryProps = Omit<
  NewStakingCampaignAction,
  "type"
> & {
  decimals: number;
  symbol: string;
};

export const NewStakingCampaignSummary = ({
  tokenAddress,
  startDate,
  duration,
  rewards,
  symbol,
  decimals,
}: NewStakingCampaignSummaryProps) => (
  <div className="d-grid gap-2 text-center">
    <h4 className="mb-5">
      Please review the following details before signing the transaction.
    </h4>
    <p>
      <b>Charged Token address :</b> {tokenAddress}
    </p>
    <p>
      <b>Staking campaign start :</b> {startDate.toString()}
    </p>
    <p>
      <b>Campaign duration :</b> {duration.toDurationString()}
    </p>
    <p>
      <b>Rewards amount :</b> {formatAmount(rewards, decimals)} {symbol}
    </p>
  </div>
);

export default NewStakingCampaignSummary;
