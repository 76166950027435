import React from "react";
import { Form, FormGroup, InputGroup } from "react-bootstrap";
import { FieldMetaState } from "react-final-form";

interface AddressInputProps {
  label?: string;
  placeholder?: string;
  meta: FieldMetaState<any>;
  name: string;
  value?: string;
  onChange: (event: any) => void;
  onBlur: (event: any) => void;
  onFocus: (event: any) => void;
}

const AddressInput = ({
  name,
  label,
  placeholder,
  value,
  meta,
  onChange,
  onBlur,
  onFocus,
}: AddressInputProps) => {
  return (
    <FormGroup>
      {label !== undefined && <Form.Label>{label}</Form.Label>}
      <InputGroup hasValidation>
        <InputGroup.Text>@</InputGroup.Text>
        <Form.Control
          id={name}
          name={name}
          htmlSize={22}
          maxLength={42}
          className={`text-end ${
            meta.touched === true &&
            (meta.error !== undefined ? "is-invalid" : "is-valid")
          }`}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
        />
        {meta.error !== undefined && meta.touched === true && (
          <Form.Control.Feedback type="invalid">
            {meta.error}
          </Form.Control.Feedback>
        )}
      </InputGroup>
    </FormGroup>
  );
};

export default AddressInput;
