import { Table } from "react-bootstrap";
import { CreateAllocationsAction } from "../../../store/actions";
import { formatAmount } from "../../../utils";

export type AllocateLTSummaryProps = Omit<
  CreateAllocationsAction,
  "type" | "navigate" | "summary"
> & {
  decimals: number;
};

export const AllocateLTSummary = ({
  tokenAddress,
  addresses,
  amounts,
  areAllocationsToBeStaked,
  decimals,
}: AllocateLTSummaryProps) => (
  <div className="d-grid gap-2">
    <h4 className="mb-5">
      Please review the following details before signing the transaction.
    </h4>
    <p className="text-center">
      <b>Charged Token address :</b> {tokenAddress}
    </p>
    <p className="text-center">
      <b>Stake allocations :</b> {areAllocationsToBeStaked ? "Yes" : "No"}
    </p>
    <Table striped bordered hover>
      <thead>
        <tr>
          <th colSpan={3} className="text-center">
            Allocations list
          </th>
        </tr>
        <tr>
          <th className="text-center">#</th>
          <th className="text-center">Investor's address</th>
          <th className="text-center">Token amount</th>
        </tr>
      </thead>
      <tbody>
        {addresses.map((address, index) => (
          <tr key={index}>
            <td className="text-center">{index + 1}</td>
            <td className="text-center">{address}</td>
            <td className="text-end">
              {formatAmount(amounts[index], decimals)}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  </div>
);

export default AllocateLTSummary;
