import { all } from "redux-saga/effects";
import { bootstrapSagas } from "./bootstrap";
import { contractsSagas } from "./contractsActions";
import { directoryActionsSagas } from "./directoryActions";
import { interfaceProjectActionsSagas } from "./interfaceProjectActions";
import { liquidityTokenActionsSagas } from "./liquidityTokenActions";
import { projectTokenActionsSagas } from "./projectTokenActions";

export function* rootSaga() {
  yield all([
    ...bootstrapSagas,
    ...directoryActionsSagas,
    ...liquidityTokenActionsSagas,
    ...interfaceProjectActionsSagas,
    ...projectTokenActionsSagas,
    ...contractsSagas,
  ]);
}
