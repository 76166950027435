import { BigNumber } from "ethers";
import { useEffect, useRef, useState } from "react";
import { Card, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { connect } from "react-redux";
import { UPDATE_CURRENT_REWARDS } from "../../../store/actions";
import {
  advancedModeSelector,
  createCTSelector,
  createInterfaceSelector,
  createStakingSelector,
} from "../../../store/selectors";
import { AppState, DateWrapper } from "../../../types";
import { EMPTY_ADDRESS, formatAmount } from "../../../utils";
import { FeesView, StakingView } from "./includes";

interface FeesAndRewardsProps {
  tokenAddress: string;
  symbol: string;
  decimals: number;
  stakingStart: DateWrapper;
  stakingEnd: DateWrapper;
  currentRewards: BigNumber;
  advancedMode: boolean;
  claimFeesPerThousandForPT?: number;
  hasInterface: boolean;
  updateCurrentRewards: (address: string) => void;
}

export const FeesAndRewards = ({
  tokenAddress,
  symbol,
  decimals,
  stakingStart,
  stakingEnd,
  currentRewards,
  advancedMode,
  claimFeesPerThousandForPT,
  hasInterface,
  updateCurrentRewards,
}: FeesAndRewardsProps) => {
  const [finalComputationDone, setFinalComputationDone] = useState(false);
  const timerIdRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    updateCurrentRewards(tokenAddress);

    const timerId = setInterval(() => {
      if (
        stakingStart.isSet &&
        stakingEnd.isSet &&
        stakingStart.isPast() &&
        stakingEnd.isFuture()
      ) {
        updateCurrentRewards(tokenAddress);
      } else if (
        stakingEnd.isSet &&
        stakingEnd.isPast() &&
        !finalComputationDone
      ) {
        updateCurrentRewards(tokenAddress);
        setFinalComputationDone(true);
      }
    }, 10000);
    timerIdRef.current = timerId;

    return () => {
      if (timerIdRef.current !== undefined) {
        clearInterval(timerIdRef.current);
      }
    };
  }, [stakingStart, stakingEnd]);

  return (
    <>
      <Col sm={12}>
        <Row className="gy-4 align-items-stretch">
          {advancedMode ||
          (hasInterface && claimFeesPerThousandForPT !== undefined) ? (
            <>
              <Col xs={12} md={6}>
                <FeesView tokenAddress={tokenAddress} />
              </Col>

              <Col xs={12} md={6}>
                <StakingView tokenAddress={tokenAddress} />
              </Col>
            </>
          ) : (
            <Col xs={12} md={{ span: 8, offset: 2 }}>
              <StakingView tokenAddress={tokenAddress} />
            </Col>
          )}

          <Col xs={12} md={{ span: 6, offset: 3 }}>
            <Card style={{ height: "100%" }}>
              <Card.Body className="text-center">
                {advancedMode && (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip>
                        Rewards are obtained automatically when an action is
                        performed by the user : withdraw, deposit, claim,
                        recharge.
                        <br />
                        Claim and recharge are only possible when the Project
                        token has launched (after TGE).
                      </Tooltip>
                    }
                  >
                    <h4>
                      Current rewards :{" "}
                      {`${formatAmount(currentRewards, decimals, 2)} ${symbol}`}
                    </h4>
                  </OverlayTrigger>
                )}

                {!advancedMode && (
                  <h4>
                    Current rewards :{" "}
                    {`${formatAmount(currentRewards, decimals, 2)} ${symbol}`}
                  </h4>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Col>
    </>
  );
};

function mapStateToProps(
  state: AppState,
  ownProps: Pick<FeesAndRewardsProps, "tokenAddress">
) {
  const ct = createCTSelector(ownProps.tokenAddress)(state);
  const staking = createStakingSelector(ownProps.tokenAddress)(state);

  const iface =
    ct.interfaceProjectToken === EMPTY_ADDRESS
      ? undefined
      : createInterfaceSelector(ct.interfaceProjectToken)(state);

  return {
    ...ownProps,
    symbol: ct.symbol,
    decimals: ct.decimals,
    currentRewards: staking.currentRewards,
    stakingStart: ct.stakingStartDate,
    stakingEnd: ct.stakingEndDate,
    hasInterface: ct.interfaceProjectToken !== EMPTY_ADDRESS,
    advancedMode: advancedModeSelector(state),
    claimFeesPerThousandForPT:
      iface !== undefined ? iface.claimFeesPerThousandForPT : 0,
  };
}

function mapDispatchToProps(dispatch: (action: any) => void) {
  return {
    updateCurrentRewards: (address: string) =>
      dispatch({ type: UPDATE_CURRENT_REWARDS, address }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FeesAndRewards);
