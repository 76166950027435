import { BigNumber } from "ethers";
import { Action } from "redux";
import { DateWrapper } from "../../types";

export const WHITELIST_PROJECT = "WHITELIST_PROJECT";
export interface WhitelistProjectAction extends Action<"WHITELIST_PROJECT"> {
  owner: string;
  project: string;
}

export const PROJECT_WHITELISTED = "PROJECT_WHITELISTED";
export const PROJECT_WHITELIST_ERROR = "PROJECT_WHITELIST_ERROR";

export const REMOVE_PROJECT_FROM_WHITELIST = "REMOVE_PROJECT_FROM_WHITELIST";
export interface RemoveProjectAction
  extends Action<"REMOVE_PROJECT_FROM_WHITELIST"> {
  name: string;
  owner: string;
  navigate: (path: string) => void;
}

export const DEPLOY_LT = "DEPLOY_LT";
export interface DeployLTAction extends Action<"DEPLOY_LT"> {
  navigate: (path: string) => void;
  name: string;
  symbol: string;
  fractionInitialUnlockPerThousand: string;
  durationCliff: DateWrapper;
  durationLinearVesting: DateWrapper;
  maxWithdrawFeesPerThousand: string;
  maxClaimFeesPerThousand: string;
  maxStakingAPR: string;
  maxStakingTokenAmount: string;
  maxInitialTokenAllocation: string;
  summary: React.ReactElement;
}

export const SET_FUNDRAISING_CONDITIONS = "SET_FUNDRAISING_CONDITIONS";
export interface SetFundraisingConditionsAction
  extends Action<"SET_FUNDRAISING_CONDITIONS"> {
  navigate: (path: string) => void;
  tokenAddress: string;
  fundraisingToken: string;
  fundraisingTokenSymbol: string;
  tokenPrice1e18: BigNumber;
  summary: React.ReactElement;
}

export const TOGGLE_FUNDRAISING = "TOGGLE_FUNDRAISING";
export interface ToggleFundraisingAction extends Action<"TOGGLE_FUNDRAISING"> {
  tokenAddress: string;
  value: boolean;
}

export const INVEST_BY_USER = "INVEST_BY_USER";
export interface InvestByUserAction extends Action<"INVEST_BY_USER"> {
  navigate: (path: string) => void;
  tokenAddress: string;
  amount: BigNumber;
}

export const INVEST_BY_USER_ERROR = "INVEST_BY_USER_ERROR";
export const INVEST_BY_USER_SUCCESS = "INVEST_BY_USER_SUCCESS";

export const CREATE_ALLOCATIONS = "CREATE_ALLOCATIONS";
export interface CreateAllocationsAction extends Action<"CREATE_ALLOCATIONS"> {
  navigate: (path: string) => void;
  tokenAddress: string;
  addresses: string[];
  amounts: string[];
  areAllocationsToBeStaked: boolean;
  summary: React.ReactElement;
}

export const ALLOCATIONS_CREATED = "ALLOCATIONS_CREATED";
export const ALLOCATIONS_ERROR = "ALLOCATIONS_ERROR";

export const TERMINATE_ALLOCATIONS = "TERMINATE_ALLOCATIONS";
export interface TerminateAllocationsAction
  extends Action<"TERMINATE_ALLOCATIONS"> {
  navigate: (path: string) => void;
  tokenAddress: string;
}

export const CREATE_INTERFACE = "CREATE_INTERFACE";
export interface CreateInterfaceAction extends Action<"CREATE_INTERFACE"> {
  navigate: (path: string) => void;
  deployNewProjectToken: boolean;
  tokenAddress: string;
  projectTokenAddress?: string;
  symbol?: string;
}

export const INTERFACE_CREATED = "INTERFACE_CREATED";
export interface InterfaceCreatedAction extends Action<"CREATE_INTERFACE"> {
  tokenAddress: string;
  interfaceAddress: string;
  projectTokenAddress: string;
}

export const INTERFACE_CREATION_ERROR = "INTERFACE_CREATION_ERROR";

export const START_INTERFACE = "START_INTERFACE";
export interface StartInterfaceAction extends Action<"START_INTERFACE"> {
  navigate: (path: string) => void;
  tokenAddress: string;
  startDate: DateWrapper;
}

export const INTERFACE_STARTED = "INTERFACE_STARTED";
export const INTERFACE_START_ERROR = "INTERFACE_START_ERROR";

export const LOCK_INTERFACE = "LOCK_INTERFACE";
export interface LockInterfaceAction extends Action<"LOCK_INTERFACE"> {
  navigate: (path: string) => void;
  tokenAddress: string;
}

export const WITHDRAW_LT = "WITHDRAW_LT";
export interface WithdrawLTAction extends Action<"WITHDRAW_LT"> {
  navigate: (path: string) => void;
  tokenAddress: string;
  amount: BigNumber;
}

export const LT_WITHDRAWN = "LT_WITHDRAWN";
export const LT_WITHDRAW_ERROR = "LT_WITHDRAW_ERROR";

export const DEPOSIT_LT = "DEPOSIT_LT";
export interface DepositLTAction extends Action<"DEPOSIT_LT"> {
  navigate: (path: string) => void;
  tokenAddress: string;
  amount: BigNumber;
}

export const LT_DEPOSITED = "LT_DEPOSITED";
export const LT_DEPOSIT_ERROR = "LT_DEPOSIT_ERROR";

export const CLAIM_TOKENS = "CLAIM_TOKENS";
export interface ClaimTokensAction extends Action<"CLAIM_TOKENS"> {
  navigate: (path: string) => void;
  tokenAddress: string;
  totalClaimable: BigNumber;
  currentRewards: BigNumber;
}

export const RECHARGE_TOKENS = "RECHARGE_TOKENS";
export interface RechargeTokensAction extends Action<"RECHARGE_TOKENS"> {
  navigate: (path: string) => void;
  tokenAddress: string;
  amount: BigNumber;
}

export const LT_RECHARGED = "LT_RECHARGED";
export const LT_RECHARGE_ERROR = "LT_RECHARGE_ERROR";

export const TOGGLE_USER_FUNCTIONS = "TOGGLE_USER_FUNCTIONS";
export interface ToggleUserFunctionsAction
  extends Action<"TOGGLE_USER_FUNCTIONS"> {
  tokenAddress: string;
}

export const USER_FUNCTIONS_UPDATED = "USER_FUNCTIONS_UPDATED";
export const USER_FUNCTIONS_TOGGLE_ERROR = "USER_FUNCTIONS_TOGGLE_ERROR";

export const LT_SET_WITHDRAW_FEE = "LT_SET_WITHDRAW_FEE";
export const LT_SET_RATIO_FEE = "LT_SET_RATIO_FEE";
export const SET_CLAIM_FEE = "SET_CLAIM_FEE";
export const FEE_SCHEDULE_UPDATED = "FEE_SCHEDULE_UPDATED";
export const FEE_SCHEDULE_UPDATE_ERROR = "FEE_SCHEDULE_UPDATE_ERROR";

type SET_FEE =
  | typeof LT_SET_WITHDRAW_FEE
  | typeof LT_SET_RATIO_FEE
  | typeof SET_CLAIM_FEE;

export interface SetFeeAction extends Action<SET_FEE> {
  navigate: (path: string) => void;
  tokenAddress: string;
  valuePerThousand: number;
}

export const NEW_STAKING_CAMPAIGN = "NEW_STAKING_CAMPAIGN";
export interface NewStakingCampaignAction
  extends Action<"NEW_STAKING_CAMPAIGN"> {
  navigate: (path: string) => void;
  tokenAddress: string;
  startDate: DateWrapper;
  duration: DateWrapper;
  rewards: string;
  network: string;
  summary: React.ReactElement;
}

export const STAKING_CAMPAIGN_CREATED = "STAKING_CAMPAIGN_CREATED";
export const STAKING_CAMPAIGN_CREATE_ERROR = "STAKING_CAMPAIGN_CREATE_ERROR";

export const REMOVE_LT = "REMOVE_LT";
export interface RemoveLTAction extends Action<"REMOVE_LT"> {
  navigate: (path: string) => void;
  address: string;
}

export const REMOVE_LT_FROM_STATE = "REMOVE_LT_FROM_STATE";
export interface RemoveLTFromStateAction
  extends Action<"REMOVE_LT_FROM_STATE"> {
  tokenAddress: string;
  interfaceAddress?: string;
  projectToken?: string;
}

export const LT_CONTRACT_REMOVED = "LT_CONTRACT_REMOVED";
export const LT_CONTRACT_REMOVE_ERROR = "LT_CONTRACT_REMOVE_ERROR";

export const ADD_INTERFACE_TO_PROJECT = "ADD_INTERFACE_TO_PROJECT";
export interface AddInterfaceToProjectWhitelistAction
  extends Action<"ADD_INTERFACE_TO_PROJECT"> {
  navigate: (path: string) => void;
  tokenAddress: string;
  projectToken: string;
  interfaceAddress: string;
}

export const REMOVE_INTERFACE_FROM_TOKEN = "REMOVE_INTERFACE_FROM_TOKEN";
export interface RemoveInterfaceFromProjectWhitelistAction
  extends Action<"REMOVE_INTERFACE_FROM_TOKEN"> {
  navigate: (path: string) => void;
  tokenAddress: string;
  projectToken: string;
  interfaceAddress: string;
}

export const FINALIZE_INVESTMENT_ROUNDS = "FINALIZE_INVESTMENT_ROUNDS";
export interface FinalizeInvestmentRoundsAction
  extends Action<"FINALIZE_INVESTMENT_ROUNDS"> {
  projectToken: string;
}

export const FINALIZE_INVESTMENT_ROUNDS_ERROR =
  "FINALIZE_INVESTMENT_ROUNDS_ERROR";
export const INVESTMENT_ROUNDS_FINALIZED = "INVESTMENT_ROUNDS_FINALIZED";
