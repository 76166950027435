import { BigNumber } from "ethers";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { Field, Form } from "react-final-form";
import {
  buildMaxAmountValidator,
  formatAmount,
  parseAmount,
} from "../../../../utils";
import { Loading } from "../../../common";
import { AmountInput } from "../../../forms";
import RechargeSummary from "../../tx-summary/RechargeSummary";

interface RechargeProps {
  max: string;
  ptNeededForFullRecharge: string;
  ptAvailable: string;
  symbol: string;
  projectSymbol: string;
  decimals: number;
  disabled: boolean;
  tokenAddress: string;
  currentRewards: BigNumber;
  loading: boolean;
  validatedInterface: boolean;
  convert: (amount: string) => string;
}

const Recharge = ({
  symbol,
  projectSymbol,
  decimals,
  disabled,
  tokenAddress,
  max,
  ptNeededForFullRecharge,
  ptAvailable,
  currentRewards,
  loading,
  validatedInterface,
  convert,
}: RechargeProps) => {
  const [showSummary, setShowSummary] = useState(false);
  const [amount, setAmount] = useState(BigNumber.from(0));

  return (
    <>
      <Form
        onSubmit={({ amount }) => {
          setAmount(parseAmount(amount, decimals));
          setShowSummary(true);
        }}
        render={({ form, handleSubmit, pristine, submitting }) => (
          <form
            onSubmit={handleSubmit}
            noValidate
            className="needs-validation d-grid gap-3"
          >
            <div>
              Tokens needed to recharge all {symbol} :
              <div className="text-center mt-3 mb-2">
                <Loading value={loading}>{ptNeededForFullRecharge} </Loading>
                {projectSymbol}
              </div>
            </div>
            <div>
              Tokens available (wallet) :
              <div className="text-center mt-3 mb-2">
                <Loading value={loading}>
                  <>
                    {ptAvailable} {projectSymbol}
                  </>
                </Loading>
              </div>
            </div>
            <Field
              name="amount"
              validate={buildMaxAmountValidator(max, decimals)}
            >
              {({ input, meta }) => (
                <>
                  <AmountInput
                    placeholder="Amount to use for recharge"
                    symbol={projectSymbol}
                    decimals={decimals}
                    max={max}
                    meta={meta}
                    {...input}
                  >
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={
                        disabled ||
                        !validatedInterface ||
                        pristine ||
                        submitting ||
                        form.getState().invalid
                      }
                    >
                      Recharge
                    </Button>
                  </AmountInput>
                  <div className="text-center">
                    You will recharge{" "}
                    {input.value !== undefined && input.value !== ""
                      ? formatAmount(
                          convert(
                            parseAmount(input.value, decimals).toString()
                          ),
                          decimals,
                          2
                        )
                      : "0.00"}{" "}
                    {symbol}
                  </div>
                </>
              )}
            </Field>
          </form>
        )}
      />
      <RechargeSummary
        tokenAddress={tokenAddress}
        amount={amount}
        currentRewards={currentRewards}
        handleClose={() => setShowSummary(false)}
        decimals={decimals}
        symbol={symbol}
        projectSymbol={projectSymbol}
        show={showSummary}
        convert={convert}
      />
    </>
  );
};

export default Recharge;
