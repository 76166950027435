import { getThemeProject, isThemeProjectFiltered } from "charged-token-themes";
import { ethers } from "ethers";
import { v4 as uuidv4 } from "uuid";
import { AlertData, BootstrapState } from "../../types";
import {
  DIRECTORY_PER_CHAINID,
  LIBRARIES_PER_NETWORK,
  getCurrentAdvancedMode,
  saveCurrentAdvancedMode,
} from "../../utils";
import {
  ACCOUNT_CHANGE_SUBSCRIBED,
  AccountChangeSubscribedAction,
  BOOTSTRAP_ERROR,
  BootstrapErrorAction,
  CHANGE_DISPLAYED_PROJECT,
  CLEAR_BOOTSTRAP_ERROR,
  CONTRACTS_LOADED,
  ChangeDisplayedProject,
  DISMISS_ALERT,
  DismissAlertAction,
  ETH_ACCOUNTS_LISTED,
  EthAccountsListedAction,
  LIBRARY_DEPLOYED,
  LibraryDeployedAction,
  METAMASK_DETECTED,
  METAMASK_DETECTION_REQUESTED,
  MetaMaskDetectedAction,
  SET_API_VERSION,
  SET_NETWORK,
  SHOW_ALERT,
  SetApiVersionAction,
  SetNetworkAction,
  ShowAlertAction,
  TOGGLE_ADVANCED_MODE,
} from "../actions";

const themeProject = getThemeProject();
const filterProject = isThemeProjectFiltered();

export const initialState: BootstrapState = {
  bootstrapLoading: true,
  loading: true,
  contractsLoading: true,
  libraries: {},
  connecting: false,
  accounts: [],
  account: "",
  network: "",
  apiVersion: "unknown",
  accountSubscription: undefined,
  chainChangeSubscription: undefined,
  metamaskMessageSubscription: undefined,
  newBlockSubscription: undefined,
  metamask: undefined,
  provider: undefined,
  selectedProjectName:
    themeProject !== undefined && filterProject === true
      ? themeProject
      : undefined,
  advancedMode: getCurrentAdvancedMode(),
  alerts: [],
};

function bootstrapError(
  prevState: BootstrapState,
  action: BootstrapErrorAction
) {
  return {
    ...prevState,
    bootstrapLoading: false,
    connecting: false,
    error: action.error,
    chainId: action.chainId,
  };
}

function clearBootstrapError(prevState: BootstrapState) {
  return {
    ...prevState,
    error: undefined,
  };
}

function metamaskDetectionRequested(prevState: BootstrapState) {
  return {
    ...prevState,
    connecting: true,
  };
}

function metamaskDetected(
  prevState: BootstrapState,
  action: MetaMaskDetectedAction
) {
  console.warn("CREATING NEW PROVIDER !!! WATCH SUBSCRIPTIONS");

  const provider = new ethers.providers.Web3Provider(
    action.metamask as ethers.providers.ExternalProvider,
    "any"
  );
  provider.on("network", (newNetwork, oldNetwork) => {
    // When a Provider makes its initial connection, it emits a "network"
    // event with a null oldNetwork along with the newNetwork. So, if the
    // oldNetwork exists, it represents a changing network
    if (oldNetwork !== null && oldNetwork !== newNetwork) {
      console.warn(
        "Blockchain change detected, the current page will be reloaded",
        oldNetwork,
        newNetwork
      );
      alert("Blockchain change detected, the current page will be reloaded");
      window.location.reload();
    }
  });

  const newState = {
    ...prevState,
    metamask: action.metamask,
    connecting: false,
    bootstrapLoading: false,
    provider,
  };

  console.warn("Provider created :", newState);

  return newState;
}

function setApiVersion(prevState: BootstrapState, action: SetApiVersionAction) {
  return {
    ...prevState,
    apiVersion: action.version,
  };
}

function ethAccountsListed(
  prevState: BootstrapState,
  action: EthAccountsListedAction
) {
  if (action.accounts.length === 0) {
    throw new Error("No account found !");
  }

  console.log("ethAccountsListed, new account :", action.accounts[0]);

  return {
    ...prevState,
    accounts: action.accounts.map(ethers.utils.getAddress),
    account: ethers.utils.getAddress(action.accounts[0]),
  };
}

function setNetwork(prevState: BootstrapState, action: SetNetworkAction) {
  return {
    ...prevState,
    network: action.network,
    chainId: Number(action.network),
    directory: DIRECTORY_PER_CHAINID[action.network],
    libraries:
      LIBRARIES_PER_NETWORK[action.network] !== undefined
        ? LIBRARIES_PER_NETWORK[action.network]
        : {},
  };
}

function contractsLoaded(prevState: BootstrapState) {
  if (prevState.loading || prevState.contractsLoading) {
    return {
      ...prevState,
      loading: false,
      contractsLoading: false,
    };
  }
  return prevState;
}

function accountChangeSubscribed(
  prevState: BootstrapState,
  action: AccountChangeSubscribedAction
) {
  const { type, ...subscriptions } = action;

  return {
    ...prevState,
    ...subscriptions,
  };
}

function changeDisplayedProject(
  prevState: BootstrapState,
  action: ChangeDisplayedProject
) {
  return {
    ...prevState,
    selectedProjectName: action.name,
  };
}

function toggleAdvancedMode(prevState: BootstrapState) {
  const newAdvancedMode = prevState.advancedMode === false;
  saveCurrentAdvancedMode(newAdvancedMode);

  return {
    ...prevState,
    advancedMode: newAdvancedMode,
  };
}

function libraryDeployed(
  prevState: BootstrapState,
  action: LibraryDeployedAction
) {
  return {
    ...prevState,
    libraries: {
      ...prevState.libraries,
      [action.name]: action.address,
    },
  };
}

function showAlert(prevState: BootstrapState, action: ShowAlertAction) {
  const { type, ...rest } = action;

  return {
    ...prevState,
    alerts: [
      ...prevState.alerts,
      {
        ...rest,
        id: uuidv4(),
      } as AlertData,
    ],
  };
}

function dismissAlert(prevState: BootstrapState, action: DismissAlertAction) {
  const { id } = action;

  let index = 0;
  for (; index < prevState.alerts.length; index++) {
    if (prevState.alerts[index].id === id) break;
  }

  if (index >= prevState.alerts.length) return prevState;

  return {
    ...prevState,
    alerts: [
      ...prevState.alerts.slice(0, index),
      ...prevState.alerts.slice(index + 1),
    ],
  };
}

export function bootstrapReducer(
  prevState: BootstrapState | undefined,
  action: any
): BootstrapState {
  if (prevState === undefined) return initialState;

  switch (action.type) {
    case BOOTSTRAP_ERROR:
      return bootstrapError(prevState, action);

    case CLEAR_BOOTSTRAP_ERROR:
      return clearBootstrapError(prevState);

    case LIBRARY_DEPLOYED:
      return libraryDeployed(prevState, action);

    case METAMASK_DETECTION_REQUESTED:
      return metamaskDetectionRequested(prevState);

    case METAMASK_DETECTED:
      return metamaskDetected(prevState, action);

    case SET_API_VERSION:
      return setApiVersion(prevState, action);

    case ETH_ACCOUNTS_LISTED:
      return ethAccountsListed(prevState, action);

    case ACCOUNT_CHANGE_SUBSCRIBED:
      return accountChangeSubscribed(prevState, action);

    case SET_NETWORK:
      return setNetwork(prevState, action);

    case CONTRACTS_LOADED:
      return contractsLoaded(prevState);

    case CHANGE_DISPLAYED_PROJECT:
      return changeDisplayedProject(prevState, action);

    case TOGGLE_ADVANCED_MODE:
      return toggleAdvancedMode(prevState);

    case SHOW_ALERT:
      return showAlert(prevState, action);

    case DISMISS_ALERT:
      return dismissAlert(prevState, action);

    default:
      return prevState;
  }
}
