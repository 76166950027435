import useAsyncFunction from "@studysync/react-redux-promise-listener-hook";
import { Button, Modal } from "react-bootstrap";
import BForm from "react-bootstrap/Form";
import { Field, Form, FormSpy } from "react-final-form";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { promiseListener } from "../../store";
import {
  CREATE_INTERFACE,
  INTERFACE_CREATED,
  INTERFACE_CREATION_ERROR,
} from "../../store/actions";
import { required, requiredAddressValidator } from "../../utils";
import { AddressInput, TextInput } from "../forms";

interface AddInterfaceFormProps {
  show: boolean;
  handleClose: () => void;
  tokenAddress: string;
  createInterface: (tokenAddress: string, projectTokenAddress?: string) => void;
}

const AddInterfaceForm = ({
  show,
  handleClose,
  tokenAddress,
}: AddInterfaceFormProps) => {
  const navigate = useNavigate();

  const setPayload = (action: any, values: any) => {
    const projectTokenAddress =
      values !== undefined ? values.projectTokenAddress : undefined;
    const symbol = values !== undefined ? values.symbol : undefined;
    const deployNewProjectToken = values.deployNewProjectToken === true;

    return {
      ...action,
      navigate,
      deployNewProjectToken,
      tokenAddress,
      projectTokenAddress,
      symbol,
    };
  };

  const asyncFuncConfig = {
    start: CREATE_INTERFACE,
    resolve: INTERFACE_CREATED,
    reject: INTERFACE_CREATION_ERROR,
    setPayload,
  };
  const asyncFunc = useAsyncFunction(asyncFuncConfig, promiseListener);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header>
        <Modal.Title>Create Interface with Project Token</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form
          onSubmit={asyncFunc}
          initialValues={{ deployNewProjectToken: false }}
          render={({ handleSubmit, form, submitting }) => (
            <form
              onSubmit={(event) => handleSubmit(event)}
              noValidate
              className="needs-validation d-grid gap-3"
            >
              <Field name="deployNewProjectToken" type="checkbox">
                {({ input, meta }) => (
                  <BForm.Check
                    type={"checkbox" as any}
                    label="I don't have a Project Token yet"
                    {...input}
                  />
                )}
              </Field>

              <FormSpy>
                {({ values }) => (
                  <>
                    {values["deployNewProjectToken"] !== true && (
                      <>
                        <Field
                          name="projectTokenAddress"
                          validate={requiredAddressValidator}
                        >
                          {({ input, meta }) => (
                            <AddressInput
                              label="Address of the Project Token contract"
                              meta={meta}
                              {...input}
                            />
                          )}
                        </Field>
                      </>
                    )}

                    {values["deployNewProjectToken"] === true && (
                      <>
                        <Field name="symbol" validate={required}>
                          {({ input, meta }) => (
                            <TextInput
                              label="Symbol of the Token to deploy"
                              meta={meta}
                              {...input}
                            ></TextInput>
                          )}
                        </Field>
                      </>
                    )}

                    <Button
                      type="submit"
                      variant="primary"
                      size="lg"
                      disabled={submitting || form.getState().invalid}
                    >
                      {values["deployNewProjectToken"] === true
                        ? "Deploy new Project Token and Interface"
                        : "Create interface"}
                    </Button>
                  </>
                )}
              </FormSpy>
            </form>
          )}
        ></Form>
      </Modal.Body>
    </Modal>
  );
};

function mapDispatchToProps(dispatch: (action: any) => void) {
  return {
    createInterface: (
      tokenAddress: string,
      projectTokenAddress: string | undefined
    ) =>
      dispatch({
        type: CREATE_INTERFACE,
        tokenAddress,
        projectTokenAddress,
      }),
  };
}

export default connect(null, mapDispatchToProps)(AddInterfaceForm);
