import { ethers } from "ethers";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { DelegableToLT } from "../../contracts";
import { getContractError } from "../../utils";
import {
  ADD_INTERFACE_TO_PROJECT,
  AddInterfaceToProjectWhitelistAction,
  FINALIZE_INVESTMENT_ROUNDS,
  FINALIZE_INVESTMENT_ROUNDS_ERROR,
  FinalizeInvestmentRoundsAction,
  INVESTMENT_ROUNDS_FINALIZED,
  REMOVE_INTERFACE_FROM_TOKEN,
  RemoveInterfaceFromProjectWhitelistAction,
  SHOW_ALERT,
} from "../actions";
import { CLEAR_TRANSACTION, SET_TRANSACTION } from "../actions/transaction";
import { bootstrapSelector } from "../selectors";
import { waitForTransaction } from "./waitForTransaction";

function* removeInterfaceFromWhitelist(
  action: RemoveInterfaceFromProjectWhitelistAction
): any {
  const { provider, chainId } = yield select(bootstrapSelector);
  const { tokenAddress, projectToken, interfaceAddress } = action;

  console.warn("removing interface", interfaceAddress, "from pt", projectToken);

  try {
    const PT = new ethers.Contract(
      projectToken,
      DelegableToLT.abi,
      provider.getSigner()
    );

    yield put({
      type: SET_TRANSACTION,
      index: 1,
      total: 2,
      msg: "Disabling Project Token interface",
      returnTo: `/lt/${tokenAddress}/troubleshooting`,
      navigate: action.navigate,
    });

    const tx = yield call(
      [PT, PT.removeInterfaceProjectToken],
      interfaceAddress
    );

    yield put({
      type: SET_TRANSACTION,
      index: 2,
      tx,
      msg: "Waiting for the transaction to be mined",
      navigate: action.navigate,
    });

    yield call(waitForTransaction, chainId, tx.hash);

    yield put({ type: CLEAR_TRANSACTION });

    yield put({
      type: SHOW_ALERT,
      title: "Contract interface removed from project token",
      message: "The changes should take effect in a few seconds",
      level: "success",
    });
  } catch (err) {
    console.error("Couldn't remove Interface contract !", err);
    yield put({ type: CLEAR_TRANSACTION });

    yield put({
      type: SHOW_ALERT,
      title: "Interface removal error",
      message: getContractError(err),
      level: "error",
    });
  }
}

function* addInterfaceToWhitelist(
  action: AddInterfaceToProjectWhitelistAction
): any {
  const { provider, chainId } = yield select(bootstrapSelector);
  const { tokenAddress, projectToken, interfaceAddress } = action;

  try {
    const PT = new ethers.Contract(
      projectToken,
      DelegableToLT.abi,
      provider.getSigner()
    );

    yield put({
      type: SET_TRANSACTION,
      index: 1,
      total: 2,
      msg: "Enabling Project Token interface",
      returnTo: `/lt/${tokenAddress}/troubleshooting`,
      navigate: action.navigate,
    });

    const tx = yield call([PT, PT.addInterfaceProjectToken], interfaceAddress);

    yield put({
      type: SET_TRANSACTION,
      index: 2,
      tx,
      msg: "Waiting for the transaction to be mined",
      navigate: action.navigate,
    });

    yield call(waitForTransaction, chainId, tx.hash);

    yield put({ type: CLEAR_TRANSACTION });

    yield put({
      type: SHOW_ALERT,
      title: "Contract interface whitelisted",
      message: "The changes should take effect in a few seconds",
      level: "success",
    });
  } catch (err) {
    console.error("Couldn't add Interface contract !", err);
    yield put({ type: CLEAR_TRANSACTION });
    yield put({
      type: SHOW_ALERT,
      title: "Interface whitelisting error",
      message: getContractError(err),
      level: "error",
    });
  }
}

function* finalizeInvestmentRounds(
  action: FinalizeInvestmentRoundsAction
): any {
  const { provider, chainId } = yield select(bootstrapSelector);
  const { projectToken } = action;

  try {
    const PT = new ethers.Contract(
      projectToken,
      DelegableToLT.abi,
      provider.getSigner()
    );

    const tx = yield call([
      PT,
      PT.finalizeListOfValidatedInterfaceProjectToken,
    ]);
    yield call(waitForTransaction, chainId, tx.hash);

    yield put({
      type: INVESTMENT_ROUNDS_FINALIZED,
    });
    yield put({
      type: SHOW_ALERT,
      title: "Rounds and Interfaces locked",
      message: `Rounds and Interfaces locked on token ${projectToken}`,
      level: "info",
    });
  } catch (err) {
    yield put({
      type: FINALIZE_INVESTMENT_ROUNDS_ERROR,
      err,
    });
    yield put({
      type: SHOW_ALERT,
      title: "Error locking rounds and interfaces",
      message: getContractError(err),
      level: "error",
    });
  }
}

export const projectTokenActionsSagas = [
  takeEvery(REMOVE_INTERFACE_FROM_TOKEN, removeInterfaceFromWhitelist),
  takeEvery(ADD_INTERFACE_TO_PROJECT, addInterfaceToWhitelist),
  takeEvery(FINALIZE_INVESTMENT_ROUNDS, finalizeInvestmentRounds),
];
