import { ethers } from "ethers";
import { findLinkReferences, linkBytecode } from "solc/linker";
import {
  AddressSet,
  ContractsDirectory,
  DelegableToLT,
  InterfaceProjectToken,
  LiquidityToken,
  ProjectToken,
  SafeMath,
  StringSet,
} from "../../contracts";
import { LIBRARIES_PER_NETWORK } from "./addresses";

const CONTRACT_MAP: Record<string, any> = {
  ProjectToken: ProjectToken,
  DelegableToLT: DelegableToLT,
  InterfaceProjectToken: InterfaceProjectToken,
  LiquidityToken: LiquidityToken,
  ContractsDirectory: ContractsDirectory,
};

const LIBRARIES_MAP: Record<string, any> = {
  SafeMath: SafeMath,
  AddressSet: AddressSet,
  StringSet: StringSet,
};

const SOURCE_MAP: Record<string, string> = {
  AddressSet: "library/AddressSet.sol:AddressSet",
  StringSet: "library/StringSet.sol:StringSet",
  SafeMath: "openzeppelin-solidity-2.0.1/contracts/math/SafeMath.sol:SafeMath",
};

const HASH_SOURCE: Record<string, string> = {};

function getSourceHashMapping(source: string): string {
  const sourceBytes = source.split("").map((c) => c.charCodeAt(0));
  return `$${ethers.utils.keccak256(sourceBytes).substr(2, 34)}$`;
}

Object.entries(SOURCE_MAP).forEach(([key, value]) => {
  const hash = getSourceHashMapping(value);
  HASH_SOURCE[hash] = value;
  HASH_SOURCE[value] = hash;
  SOURCE_MAP[value] = key;
});

export async function deployLibraryContract(
  name: string,
  provider: ethers.providers.Web3Provider,
  ...args: any[]
): Promise<string> {
  const { chainId } = await provider.getNetwork();

  console.log("deploying library", name);

  const contract = LIBRARIES_MAP[name];
  const bytecode = contract.evm.bytecode.object;

  const contractFactory = new ethers.ContractFactory(
    contract.abi,
    bytecode,
    provider.getSigner()
  );
  console.log("contract instantiated, sending deploy tx");

  let instance;
  try {
    instance = await contractFactory.deploy(...args);
    console.log("deploy tx:", instance.deployTransaction);
    await instance.deployTransaction.wait();
    const address = instance.address;
    console.warn("Deployed library", name, "at", address);
    if (LIBRARIES_PER_NETWORK[chainId] === undefined)
      LIBRARIES_PER_NETWORK[chainId] = {};
    LIBRARIES_PER_NETWORK[chainId][name] = address;
    return address;
  } catch (err) {
    if (instance) {
      console.warn("deploy tx:", instance.deployTransaction);
    }
    console.error("Couldn't deploy contract !", err);
    throw new Error("DEPLOYMENT_ERROR");
  }
}

export async function deployContract(
  name: string,
  provider: ethers.providers.Web3Provider,
  ...args: any[]
): Promise<string> {
  const { chainId } = await provider.getNetwork();
  const libraries =
    LIBRARIES_PER_NETWORK[`${chainId}`] !== undefined
      ? LIBRARIES_PER_NETWORK[`${chainId}`]
      : {};
  const finalLibraries = { ...libraries };

  console.log("deploying contract", name, "using libraries :", libraries);

  const contract = CONTRACT_MAP[name];
  const bytecode = contract.evm.bytecode.object;
  const references = findLinkReferences(bytecode);

  const uniqueRefs = new Set(Object.keys(references));

  for (const ref of uniqueRefs) {
    const refSource = HASH_SOURCE[ref];
    const refName = SOURCE_MAP[refSource];

    if (libraries[refName] !== undefined) {
      finalLibraries[refSource] = libraries[refName];
      // finalLibraries[ref] = libraries[refName];
      uniqueRefs.delete(ref);
      continue;
    }

    console.error(
      "could not resolve",
      refSource,
      "against available libraries"
    );
    // throw new Error("Link error !");
    // await deployContract(refName, provider);

    // uniqueRefs.delete(ref);
  }

  if (uniqueRefs.size > 0) {
    throw new Error("UNMATCHED_CONTRACT_DEPENDENCIES");
  }

  console.log("linking", name, "against", finalLibraries);

  const linkedBytecode = linkBytecode(
    contract.evm.bytecode.object,
    finalLibraries
  );
  console.log("deploying", name);

  const contractFactory = new ethers.ContractFactory(
    contract.abi,
    linkedBytecode,
    provider.getSigner()
  );
  console.log("contract instantiated, sending deploy tx");

  let instance;
  try {
    instance = await contractFactory.deploy(...args);
    console.log("deploy tx:", instance.deployTransaction);
    await instance.deployTransaction.wait();
    const address = instance.address;
    if (SOURCE_MAP[name] !== undefined) {
      const contractSource = SOURCE_MAP[name];
      const contractHash = HASH_SOURCE[contractSource];
      libraries[contractSource] = address;
      libraries[contractHash] = address;
    }
    console.warn("Deployed contract", name, "at", address);
    return address;
  } catch (err) {
    if (instance) {
      console.warn("deploy tx:", instance.deployTransaction);
    }
    console.error("Couldn't deploy contract !", err);
    throw new Error("DEPLOYMENT_ERROR");
  }
}
