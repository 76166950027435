import { BigNumber } from "ethers";
import { useMemo, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import {
  bootstrapSelector,
  createCTSelector,
  userAddressSelector,
} from "../../../../store/selectors";
import { AppState, DateWrapper } from "../../../../types";
import { computeStakingAPR, formatAmount } from "../../../../utils";
import { NewStakingCampaignForm } from "../../../admin";

interface StakingViewProps {
  tokenAddress: string;
  network: string;
  account: string;
  owner: string;
  symbol: string;
  decimals: number;
  stakedLT: BigNumber;
  stakingStartDate: DateWrapper;
  stakingEndDate: DateWrapper;
  stakingDuration: DateWrapper;
  campaignStakingRewards: BigNumber;
}

export const StakingView = ({
  tokenAddress,
  network,
  account,
  owner,
  symbol,
  decimals,
  stakedLT,
  stakingStartDate,
  stakingEndDate,
  stakingDuration,
  campaignStakingRewards,
}: StakingViewProps) => {
  const [showStakingForm, setShowStakingForm] = useState(false);

  const apr = useMemo(() => {
    return computeStakingAPR(stakedLT, stakingDuration, campaignStakingRewards);
  }, [campaignStakingRewards, stakingDuration, stakedLT]);

  return (
    <>
      <Card style={{ height: "100%" }}>
        <Card.Header as="h5" className="text-center">
          Current staking campaign
        </Card.Header>

        <Card.Body>
          <Row>
            <Col xs={12} lg={2}>
              Start :
            </Col>
            <Col xs={12} lg={10}>
              {stakingStartDate.isSet ? (
                <b>{stakingStartDate.toString()}</b>
              ) : (
                "N/A"
              )}
            </Col>
          </Row>

          <Row className="mt-4">
            <Col xs={12} lg={2}>
              End :
            </Col>
            <Col xs={12} lg={10}>
              {stakingEndDate.isSet ? (
                <b>{stakingEndDate.toString()}</b>
              ) : (
                "N/A"
              )}
            </Col>
          </Row>

          <Col xs={12} className="mt-4">
            Total campaign rewards :{" "}
            <b>{`${formatAmount(
              campaignStakingRewards,
              decimals,
              1
            )} ${symbol}`}</b>
          </Col>

          {stakingStartDate.isSet &&
            stakingStartDate.isPast() &&
            stakingEndDate.isFuture() && (
              <Col xs={12} className="mt-4">
                Current APR : <b>{`${apr.toFixed(2)} %`}</b>
              </Col>
            )}

          {account === owner && (
            <Col xs={12} className="mt-4 text-center">
              <Button
                type="button"
                size="lg"
                onClick={() => setShowStakingForm(true)}
                disabled={stakingStartDate.isSet && stakingEndDate.isFuture()}
              >
                <p className="h1 mb-2">
                  <i className="bi bi-graph-up-arrow"></i>
                </p>
                New Staking Campaign
              </Button>
            </Col>
          )}
        </Card.Body>
      </Card>

      <NewStakingCampaignForm
        show={showStakingForm}
        handleClose={() => setShowStakingForm(false)}
        tokenAddress={tokenAddress}
        symbol={symbol}
        decimals={decimals}
        network={network}
      />
    </>
  );
};

function mapStateToProps(
  state: AppState,
  ownProps: Pick<StakingViewProps, "tokenAddress">
) {
  const ct = createCTSelector(ownProps.tokenAddress)(state);

  return {
    ...ownProps,
    account: userAddressSelector(state),
    owner: ct.owner,
    network: bootstrapSelector(state).network,
    symbol: ct.symbol,
    decimals: ct.decimals,
    stakedLT: ct.stakedLT,
    stakingStartDate: ct.stakingStartDate,
    stakingEndDate: ct.stakingEndDate,
    stakingDuration: ct.stakingDuration,
    campaignStakingRewards: ct.campaignStakingRewards,
  };
}

export default connect(mapStateToProps)(StakingView);
