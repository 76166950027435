import React from "react";

interface FlexScreenProps {
  children?: React.ReactElement | React.ReactElement[];
}

export const FlexScreen = ({ children }: FlexScreenProps) => (
  <div className="fullpage d-flex flex-column justify-content-center align-items-center">
    {children}
  </div>
);

export default FlexScreen;
