import { BigNumber } from "ethers";
import { Card, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import {
  createBalanceSelector,
  createCTSelector,
  createClaimsSelector,
  createStakingSelector,
} from "../../../../store/selectors";
import { AppState } from "../../../../types";
import { formatAmount } from "../../../../utils";
import Deposit from "./Deposit";
import Withdraw from "./Withdraw";

export interface FullyChargedLTSummaryProps {
  tokenAddress: string;
  decimals: number;
  symbol: string;
  disabled: boolean;
  stakedLT: BigNumber;
  balanceLT: BigNumber;
  fullyChargedLT: BigNumber;
  currentRewards: BigNumber;
  withdrawFeesPerThousandForLT: number;
}

export const FullyChargedLTSummary = ({
  tokenAddress,
  decimals,
  symbol,
  disabled,
  balanceLT,
  fullyChargedLT,
  stakedLT,
  currentRewards,
  withdrawFeesPerThousandForLT,
}: FullyChargedLTSummaryProps) => {
  const maxWithdrawableLT = fullyChargedLT.toString();
  const maxDepositLT = balanceLT.toString();

  const balanceLTLocked = formatAmount(
    BigNumber.from(maxWithdrawableLT),
    decimals,
    2
  );
  const balanceLTWallet = formatAmount(
    BigNumber.from(maxDepositLT),
    decimals,
    2
  );

  return (
    <Card style={{ height: "100%" }}>
      <Card.Header as="h5" className="text-center">
        Fully charged {symbol}
      </Card.Header>

      <Card.Body>
        <Row className="gy-3 align-items-center">
          <Col xs={12}>
            <b>Staked :</b>{" "}
            <>
              {balanceLTLocked} {symbol}
            </>
          </Col>
          <Col xs={12}>
            <Withdraw
              max={maxWithdrawableLT}
              tokenAddress={tokenAddress}
              symbol={symbol}
              decimals={decimals}
              withdrawFeesPerThousandForLT={withdrawFeesPerThousandForLT}
              stakedLT={stakedLT}
              currentRewards={currentRewards}
              disabled={disabled}
            />
          </Col>
          <Col xs={12}>
            <b>Wallet :</b>{" "}
            <>
              {balanceLTWallet} {symbol}
            </>
          </Col>
          <Col xs={12}>
            <Deposit
              max={maxDepositLT}
              tokenAddress={tokenAddress}
              symbol={symbol}
              decimals={decimals}
              currentRewards={currentRewards}
              disabled={disabled}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

function mapStateToProps(
  state: AppState,
  ownProps: Pick<FullyChargedLTSummaryProps, "tokenAddress">
) {
  const ct = createCTSelector(ownProps.tokenAddress)(state);
  const staking = createStakingSelector(ownProps.tokenAddress)(state);

  const balances = createBalanceSelector(ownProps.tokenAddress)(state);
  const claims = createClaimsSelector(ownProps.tokenAddress)(state);

  const balance =
    balances !== undefined
      ? {
          balanceLT: balances.balance,
          fullyChargedLT: balances.fullyChargedBalance,
        }
      : {
          balanceLT: BigNumber.from(0),
          fullyChargedLT: BigNumber.from(0),
        };

  return {
    ...ownProps,

    decimals: ct.decimals,
    symbol: ct.symbol,
    disabled: ct.areUserFunctionsDisabled,
    stakedLT: ct.stakedLT,
    currentRewards: staking.currentRewards,
    ...balance,
    withdrawFeesPerThousandForLT: ct.withdrawFeesPerThousandForLT,
    chargedClaimableProjectToken:
      claims !== undefined
        ? claims.chargedClaimableProjectToken
        : BigNumber.from(0),
  };
}

export default connect(mapStateToProps)(FullyChargedLTSummary);
