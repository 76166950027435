import { BigNumber, ethers } from "ethers";
import { DateWrapper, Validator } from "../types";
import { EMPTY_ADDRESS } from "./blockchain";
import { parseAmount } from "./format";

export const INVALID_REQUIRED = "Required";
export const INVALID_VALUE = "Invalid value";
export const INVALID_INTEGER = "Decimals not allowed";
export const INVALID_SINGLE_DECIMAL = "Limited to one decimal";
export const INVALID_PERCENTAGE = "Invalid percentage";
export const INVALID_ADDRESS = "Invalid address";
export const INVALID_DATE = "Invalid date";
export const PASSED_DATE = "Date must be in the future";
export const OVERFLOW = "Maximum value exceeded";

export const required: Validator = (value) => {
  if (value === undefined || value.trim() === "") return INVALID_REQUIRED;
};

export const validInteger: Validator = (value) => {
  if (value !== undefined && !value.match(/^[0-9]+$/)) return INVALID_INTEGER;
};

export const validDecimal: Validator = (value) => {
  if (value !== undefined && !value.match(/^[0-9]+(\.[0-9]+)?$/))
    return INVALID_VALUE;
};

export const nonZeroInteger: Validator = (value) => {
  if (value !== undefined && value.match(/^0+$/)) return INVALID_VALUE;
};

export const nonZeroDecimal: Validator = (value) => {
  if (value !== undefined && value.match(/^0+(\.0+)?$/)) return INVALID_VALUE;
};

export const validSingleDecimal: Validator = (value) => {
  if (value !== undefined && !value.match(/^[0-9]+(\.[0-9]*)?$/))
    return INVALID_SINGLE_DECIMAL;
};

export const validPercentage: Validator = (value) => {
  if (value !== undefined && Number(value) > 100) return INVALID_PERCENTAGE;
};

export const validAddress: Validator = (value) => {
  if (value === EMPTY_ADDRESS) return INVALID_ADDRESS;

  try {
    if (value !== undefined) ethers.utils.getAddress(value);
  } catch (err) {
    return INVALID_ADDRESS;
  }
};

export const validDate: Validator = (value) => {
  if (value !== undefined && isNaN(new Date(value).getTime()))
    return INVALID_DATE;
};

export const futureDate: Validator = (value) => {
  if (
    value !== undefined &&
    !DateWrapper.fromString(value).isFuture()
    // TODO restore the minimum delta
    // DateWrapper.fromString(value).isAfter(DateWrapper.now(86400 * 1000))
  )
    return PASSED_DATE;
};

export const futureOrEqualDate: Validator = (value) => {
  if (
    value !== undefined &&
    !DateWrapper.fromString(value).isFutureOrEqualDate()
    // TODO restore the minimum delta
    // DateWrapper.fromString(value).isAfter(DateWrapper.now(86400 * 1000))
  )
    return PASSED_DATE;
};

export const composeValidators =
  (...validators: Validator[]): Validator =>
  (value) =>
    validators.reduce(
      (error: string | undefined, validator) =>
        error !== undefined ? error : validator(value),
      undefined
    );

export const requiredNullableIntegerValidator = composeValidators(
  required,
  validDecimal,
  validInteger
);
export const requiredPositiveIntegerValidator = composeValidators(
  requiredNullableIntegerValidator,
  nonZeroInteger
);
export const requiredDecimalValidator = composeValidators(
  required,
  validDecimal
);
export const requiredPositiveDecimalValidator = composeValidators(
  required,
  validDecimal,
  nonZeroDecimal
);
export const requiredSingleDecimalValidator = composeValidators(
  requiredDecimalValidator,
  validSingleDecimal
);
export const requiredPositiveSingleDecimalValidator = composeValidators(
  requiredPositiveDecimalValidator,
  validSingleDecimal
);
export const requiredSingleDecimalPercentValidator = composeValidators(
  requiredSingleDecimalValidator,
  validPercentage
);
export const requiredAddressValidator = composeValidators(
  required,
  validAddress
);
export const requiredFutureDateValidator = composeValidators(
  required,
  validDate,
  futureDate
);
export const requiredFutureOrEqualDateValidator = composeValidators(
  required,
  validDate,
  futureOrEqualDate
);

export function buildMaxAmountValidator(
  max: string,
  decimals: number,
  nullable = false
): Validator {
  return composeValidators(
    nullable ? requiredDecimalValidator : requiredPositiveDecimalValidator,
    (value) => {
      if (
        value !== undefined &&
        BigNumber.from(max).lt(parseAmount(value, decimals))
      ) {
        return OVERFLOW;
      }
    }
  );
}

export function buildMaxDecimalValidator(
  max: number,
  nullable = false
): Validator {
  return composeValidators(
    nullable ? requiredDecimalValidator : requiredPositiveDecimalValidator,
    (value) => {
      if (value !== undefined && max < Number(value)) {
        return OVERFLOW;
      }
    }
  );
}
