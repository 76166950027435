import { ethers } from "ethers";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { ContractsDirectory } from "../../contracts";
import { AppState, TransactionState } from "../../types";
import { EMPTY_ADDRESS, getContractError } from "../../utils";
import {
  PROJECT_WHITELIST_ERROR,
  REMOVE_LT,
  REMOVE_LT_FROM_STATE,
  REMOVE_PROJECT_FROM_WHITELIST,
  RemoveLTAction,
  RemoveProjectAction,
  SHOW_ALERT,
  WHITELIST_PROJECT,
  WhitelistProjectAction,
} from "../actions";
import {
  CLEAR_TRANSACTION,
  SET_TRANSACTION,
  SetTransactionAction,
} from "../actions/transaction";
import { appStateSelector, bootstrapSelector } from "../selectors";
import { transactionSelector } from "../selectors/transaction";
import { waitForTransaction } from "./waitForTransaction";

function* whitelistProject(action: WhitelistProjectAction): any {
  const { owner, project } = action;
  const { provider, directory, chainId } = yield select(bootstrapSelector);

  const Directory = new ethers.Contract(
    directory,
    ContractsDirectory.abi,
    provider.getSigner(0)
  );
  try {
    const tx = yield call(
      [Directory, Directory.whitelistProjectOwner],
      owner,
      project
    );
    yield call(waitForTransaction, chainId, tx.hash);

    yield put({
      type: SHOW_ALERT,
      title: "Whitelisting successful",
      message: `Whitelisted ${owner} to handle project ${project}`,
      level: "success",
    });
  } catch (err) {
    console.error("exception during whitelisting :", err);
    yield put({ type: PROJECT_WHITELIST_ERROR, error: err });
    yield put({
      type: SHOW_ALERT,
      title: "Whitelisting failed",
      message: getContractError(err),
      level: "error",
    });
  }
}

function* removeLTFromDirectory(action: RemoveLTAction): any {
  const { provider, directory, directoryOwner, account, chainId } =
    yield select(bootstrapSelector);
  const { address } = action;
  const state: AppState = yield select(appStateSelector);

  try {
    let tx;

    console.log(
      "removing LT",
      address,
      "from directory",
      directory,
      "by",
      account
    );

    const Directory = new ethers.Contract(
      directory,
      ContractsDirectory.abi,
      provider.getSigner()
    );

    yield put({
      type: SET_TRANSACTION,
      index: 1,
      total: 2,
      msg: "Removing Liquidity Token contract from the platform",
      returnTo: "/",
      navigate: action.navigate,
    });

    if (account === directoryOwner) {
      tx = yield call([Directory, Directory.removeLTContractByAdmin], address);
    } else {
      tx = yield call(
        [Directory, Directory.removeLTContractByProjectOwner],
        address
      );
    }

    yield put({
      type: SET_TRANSACTION,
      index: 2,
      msg: "Waiting for the transaction to be mined",
      tx,
      navigate: action.navigate,
    });

    yield call(waitForTransaction, chainId, tx.hash);

    yield put({ type: CLEAR_TRANSACTION });

    const interfaceAddress =
      state.chargedTokens[address].interfaceProjectToken !== EMPTY_ADDRESS
        ? state.chargedTokens[address].interfaceProjectToken
        : undefined;
    const projectToken =
      interfaceAddress !== undefined &&
      state.interfaces[interfaceAddress].projectToken !== EMPTY_ADDRESS
        ? state.interfaces[interfaceAddress].projectToken
        : undefined;

    yield put({
      type: REMOVE_LT_FROM_STATE,
      tokenAddress: address,
      interfaceAddress,
      projectToken,
    });
  } catch (err) {
    console.error("Couldn't remove LT contract !", err);
    yield put({ type: CLEAR_TRANSACTION });
    yield put({
      type: SHOW_ALERT,
      title: "Contract removal error",
      message: getContractError(err),
      level: "error",
    });
  }
}

function* removeProjectFromDirectory(action: RemoveProjectAction): any {
  const { provider, directory, chainId } = yield select(bootstrapSelector);
  const { name, owner } = action;

  try {
    console.log("removing project", name, "from directory's whitelist");

    const Directory = new ethers.Contract(
      directory,
      ContractsDirectory.abi,
      provider.getSigner()
    );

    yield put({
      type: SET_TRANSACTION,
      index: 1,
      total: 2,
      msg: `Removing Project ${name} from the platform's whitelist`,
      returnTo: "/",
      navigate: action.navigate,
    });

    const tx = yield call([Directory, Directory.removeProjectByAdmin], owner);

    yield put({
      type: SET_TRANSACTION,
      index: 2,
      msg: "Waiting for the transaction to be mined",
      tx,
      returnTo: "/admin/directory",
      navigate: action.navigate,
    });

    yield call(waitForTransaction, chainId, tx.hash);

    yield put({ type: CLEAR_TRANSACTION });
    yield put({
      type: SHOW_ALERT,
      title: "Project removed",
      message: `Project ${name} has been removed from the platform.`,
      level: "success",
    });
  } catch (err) {
    console.error("Couldn't remove LT contract !", err);
    yield put({ type: CLEAR_TRANSACTION });
    yield put({
      type: SHOW_ALERT,
      title: "Project removal error",
      message: getContractError(err),
      level: "error",
    });
  }
}

function* redirectIfNeeded(action: SetTransactionAction) {
  const transaction: TransactionState | null = (yield select(
    transactionSelector
  )) as unknown as TransactionState | null;

  if (transaction === null || transaction.index === 1) {
    yield call(action.navigate, "/transaction");
  }
}

export const directoryActionsSagas = [
  takeEvery(SET_TRANSACTION, redirectIfNeeded),
  takeEvery(WHITELIST_PROJECT, whitelistProject),
  takeEvery(REMOVE_LT, removeLTFromDirectory),
  takeEvery(REMOVE_PROJECT_FROM_WHITELIST, removeProjectFromDirectory),
];
