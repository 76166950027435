import { BigNumber } from "ethers";
import { Col, Table } from "react-bootstrap";
import { DispatchProp, connect } from "react-redux";
import { AnyAction } from "redux";
import {
  advancedModeSelector,
  createBalanceSelector,
  createCTSelector,
  createDelegableSelector,
  createInterfaceSelector,
} from "../../../store/selectors";
import { AppState } from "../../../types";
import { EMPTY_ADDRESS, formatAmount } from "../../../utils";

interface LiquidTokenBalancesTableProps extends DispatchProp<AnyAction> {
  tokenAddress: string;
  hasInterface: boolean;
  symbol: string;
  decimals: number;
  projectSymbol?: string;
  balanceLT: BigNumber;
  balancePT: BigNumber;
  fullyChargedLT: BigNumber;
  partiallyChargedLT: BigNumber;
  advancedMode: boolean;
}

const LiquidTokenBalancesTable = ({
  symbol,
  decimals,
  projectSymbol,
  hasInterface,
  balanceLT,
  balancePT,
  fullyChargedLT,
  partiallyChargedLT,
  advancedMode,
}: LiquidTokenBalancesTableProps) => {
  return (
    <>
      <Col xs="12">
        <Table>
          <thead>
            <tr>
              <th colSpan={2}>Staked</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Fully charged</th>
              <td>
                {`${formatAmount(fullyChargedLT, decimals, 2)} ${symbol}`}
              </td>
            </tr>
            <tr>
              <th>Partially charged</th>
              <td>
                {`${formatAmount(partiallyChargedLT, decimals, 2)} ${symbol}`}
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>

      <Col xs="12">
        <Table>
          <thead>
            <tr>
              <th colSpan={2}>Wallet</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {advancedMode && (
                <td>{`${formatAmount(balanceLT, decimals, 2)} ${symbol}`}</td>
              )}
              <td colSpan={advancedMode ? 2 : 1}>
                {hasInterface &&
                  projectSymbol !== "" &&
                  `${formatAmount(balancePT, decimals, 2)} ${projectSymbol}`}
                {!hasInterface && "N/A"}
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </>
  );
};

function mapStateToProps(
  state: AppState,
  ownProps: Pick<LiquidTokenBalancesTableProps, "tokenAddress">
) {
  const ct = createCTSelector(ownProps.tokenAddress)(state);
  const iface =
    ct.interfaceProjectToken === EMPTY_ADDRESS
      ? undefined
      : createInterfaceSelector(ct.interfaceProjectToken)(state);
  const delegable =
    iface === undefined || iface.projectToken === EMPTY_ADDRESS
      ? undefined
      : createDelegableSelector(iface.projectToken)(state);

  const balances = createBalanceSelector(ownProps.tokenAddress)(state);

  const balance =
    balances !== undefined
      ? {
          balanceLT: balances.balance,
          balancePT: balances.balancePT,
          fullyChargedLT: balances.fullyChargedBalance,
          partiallyChargedLT: balances.partiallyChargedBalance,
        }
      : {
          balanceLT: BigNumber.from(0),
          balancePT: BigNumber.from(0),
          fullyChargedLT: BigNumber.from(0),
          partiallyChargedLT: BigNumber.from(0),
        };

  return {
    ...ownProps,
    hasInterface: ct.interfaceProjectToken !== EMPTY_ADDRESS,
    symbol: ct.symbol,
    decimals: ct.decimals,
    projectSymbol: delegable !== undefined ? delegable.symbol : "",
    ...balance,
    advancedMode: advancedModeSelector(state),
  };
}

export default connect(mapStateToProps)(LiquidTokenBalancesTable);
