import React, { useCallback, useState } from "react";
import { Button, Form } from "react-bootstrap";
import FlexScreen from "./FlexScreen";

interface TermsProps {
  children?: React.ReactElement | React.ReactElement[];
}

export const Terms = ({ children }: TermsProps) => {
  const termsAccepted = localStorage.getItem("termsAccepted");

  const [submitted, setSubmitted] = useState(termsAccepted !== null);
  const [accepted, setAccepted] = useState(submitted);

  const submitTermsAcceptance = useCallback(() => {
    localStorage.setItem("termsAccepted", "true");
    setSubmitted(true);
  }, [setSubmitted]);

  if (accepted && submitted) {
    return <>{children}</>;
  }

  return (
    <FlexScreen>
      <Form.Check // prettier-ignore
        type="checkbox"
        id="terms-accepted"
        label="I acknowledge that I have read the terms and condition"
        onChange={(event) => setAccepted(event.target.checked)}
      />
      <div>
        <a
          href="https://www.yz-network.com/terms-conditions.html"
          target="_blank"
        >
          Click here to read the terms and conditions.
        </a>
      </div>
      <Button
        size="lg"
        className="mt-3"
        variant="primary"
        disabled={!accepted}
        onClick={submitTermsAcceptance}
      >
        Accept terms and conditions
      </Button>
    </FlexScreen>
  );
};

export default Terms;
