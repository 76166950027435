import { BigNumber } from "ethers";
import { Action } from "redux";
import { call, fork, take } from "redux-saga/effects";
import { DateWrapper } from "../types";
import { validAddress } from "./validators";

export const takeFirst = function <T extends Action>(
  patternOrChannel: string,
  saga: (action: T) => any
) {
  return fork(function* () {
    const action = (yield take(patternOrChannel)) as T;
    yield call(saga, action);
  });
};

export function computeStakingAPR(
  stakedLT: BigNumber,
  stakingDuration: DateWrapper,
  campaignStakingRewards: BigNumber
): number {
  return stakedLT.eq(BigNumber.from(0)) || !stakingDuration.isSet
    ? 0
    : campaignStakingRewards
        .mul(BigNumber.from(100 * 100 * 365 * 86400)) // 100%, 365d, 86400s in a day
        .div(stakedLT.mul(BigNumber.from(stakingDuration.blockchainTimestamp)))
        .toNumber() / 100; // div 100 to get 2 decimal precision
}

export function computeStakingAPRFromForm(
  stakedLT: BigNumber,
  decimals: number,
  stakingDuration: string | undefined,
  campaignStakingRewards: string | undefined
): number {
  if (stakingDuration === undefined || campaignStakingRewards === undefined)
    return 0;

  try {
    const stakingAPR = computeStakingAPR(
      stakedLT,
      DateWrapper.fromBlockchainTimestamp(
        Math.round(Number(stakingDuration) * 86400)
      ),
      BigNumber.from(campaignStakingRewards).mul(
        BigNumber.from(10).pow(decimals)
      )
    );
    return stakingAPR;
  } catch (err) {
    return 0;
  }
}

export function convertToCT(
  fundraisingAmount: BigNumber,
  fundraisingDecimals: number,
  priceTokenPer1e18: BigNumber,
  ctDecimals: number
): BigNumber {
  return fundraisingAmount
    .mul(BigNumber.from(10).pow(ctDecimals))
    .mul(BigNumber.from(10).pow(18))
    .div(priceTokenPer1e18.mul(BigNumber.from(10).pow(fundraisingDecimals)));
}

export function convertFromCT(
  ctAmount: BigNumber,
  ctDecimals: number,
  priceTokenPer1e18: BigNumber,
  fundraisingDecimals: number
): BigNumber {
  return ctAmount
    .mul(BigNumber.from(10).pow(fundraisingDecimals))
    .mul(priceTokenPer1e18)
    .div(BigNumber.from(10).pow(18))
    .div(BigNumber.from(10).pow(ctDecimals));
}

export function isLibraryDeployed(
  name: string,
  libraries?: Record<string, string>
): boolean {
  return (
    libraries !== undefined &&
    libraries[name] !== undefined &&
    validAddress(libraries[name]) === undefined
  );
}

export function allLibrariesDeployed(
  libraries?: Record<string, string>
): boolean {
  return ["SafeMath", "AddressSet", "StringSet"].reduce(
    (prev, cur) => prev && isLibraryDeployed(cur, libraries),
    true as boolean
  );
}

export function isDirectoryDeployed(directory?: string): boolean {
  return directory !== undefined && validAddress(directory) === undefined;
}
