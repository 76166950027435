import { BigNumber } from "ethers";
import {
  gqlChargedToken,
  gqlDelegableToLT,
  gqlEntry,
  gqlInterfaceProjectToken,
  gqlUserBalancesEntry,
} from "../graphql";
import {
  ChargedToken,
  DateWrapper,
  Delegable,
  Interface,
  StakingEntry,
  UserBalancesEntry,
  UserClaimsEntry,
} from "../types";
import {
  getHodlRewards,
  getUpdatedRewardPerShare1e18,
  getValueProjectTokenPerVestingSchedule,
} from "./blockchain";

const numberConverter = (value: string) => Number(value);
const bigNumberConverter = (value: string) => BigNumber.from(value);
const dateConverter = (value: string) =>
  DateWrapper.fromBlockchainTimestamp(Number(value));
const entryListConverter = (values: gqlEntry[]) => Object.assign({}, ...values);

type FieldConverter =
  | typeof numberConverter
  | typeof bigNumberConverter
  | typeof dateConverter
  | typeof entryListConverter;

const fieldsMapping: Record<string, FieldConverter> = {};

// numbers
[
  // charged token
  "decimals",
  "fractionInitialUnlockPerThousand",
  "withdrawFeesPerThousandForLT",
  "maxWithdrawFeesPerThousandForLT",
  "maxClaimFeesPerThousandForPT",
  "maxStakingAPR",
  "withdrawFeesPerThousandForLT",
  "ratioFeesToRewardHodlersPerThousand",
  // interface
  "claimFeesPerThousandForPT",
].forEach((field) => (fieldsMapping[field] = numberConverter));

// big numbers
[
  // charged token
  "totalSupply",
  "maxInitialTokenAllocation",
  "maxStakingTokenAmount",
  "campaignStakingRewards",
  "totalStakingRewards",
  "currentRewardPerShare1e18",
  "stakedLT",
  "totalLocked",
  "totalTokenAllocated",
  "priceTokenPer1e18",
  // balances
  "balance",
  "balancePT",
  "fullyChargedBalance",
  "partiallyChargedBalance",
  "claimedRewardPerShare1e18",
  "valueProjectTokenToFullRecharge",
].forEach((field) => (fieldsMapping[field] = bigNumberConverter));

// duration and dates
[
  // charged token
  "durationCliff",
  "durationLinearVesting",
  "stakingStartDate",
  "stakingDuration",
  "stakingDateLastCheckpoint",
  // interface
  "dateLaunch",
  "dateEndCliff",
  // balances
  "dateOfPartiallyCharged",
].forEach((field) => (fieldsMapping[field] = dateConverter));

// records
/*
[
  // interface
  "valueProjectTokenToFullRecharge",
].forEach((field) => (fieldsMapping[field] = entryListConverter));
*/

const excludes = ["__typename", "balances"];

export function buildChargedTokenState(
  projectName: string,
  ct: gqlChargedToken
): ChargedToken {
  const newCT = Object.assign({ projectName }, gql2state(ct));

  newCT.stakingEndDate = DateWrapper.fromBlockchainTimestamp(
    newCT.stakingStartDate.blockchainTimestamp +
      newCT.stakingDuration.blockchainTimestamp
  );

  return newCT;
}

export function buildInterfaceState(
  iface: gqlInterfaceProjectToken,
  durationLinearVesting: number
): Interface {
  const copy: Interface = gql2state(iface);

  copy.vestingEnd = DateWrapper.fromBlockchainTimestamp(
    copy.dateEndCliff.blockchainTimestamp + durationLinearVesting
  );

  return copy;
}

export function buildDelegableState(delegable: gqlDelegableToLT): Delegable {
  return gql2state(delegable);
}

export function buildBalanceState(
  balance: gqlUserBalancesEntry
): UserBalancesEntry {
  if (balance === null) {
    console.warn("converting null balance from gql !");
  }
  return gql2state(balance);
}

export function buildStakingState(
  ct: ChargedToken,
  balance?: UserBalancesEntry
): StakingEntry {
  return {
    currentRewards:
      balance !== undefined
        ? getHodlRewards(
            ct,
            balance,
            getUpdatedRewardPerShare1e18(
              ct,
              new DateWrapper().blockchainTimestamp
            )
          )
        : BigNumber.from(0),
  };
}

export function buildClaimsState(
  iface: Interface,
  ct: ChargedToken,
  balances?: UserBalancesEntry
): UserClaimsEntry {
  const timestamp = DateWrapper.now().blockchainTimestamp;
  const durationLinearVesting = ct.durationLinearVesting.blockchainTimestamp;
  const dateEndCliff = iface.dateEndCliff.blockchainTimestamp;
  const dateLaunch = iface.dateLaunch.blockchainTimestamp;

  return {
    chargedClaimableProjectToken:
      balances !== undefined
        ? getValueProjectTokenPerVestingSchedule(
            ct.fractionInitialUnlockPerThousand,
            balances.fullyChargedBalance,
            Math.min(timestamp, dateEndCliff + durationLinearVesting),
            dateEndCliff,
            timestamp >= dateLaunch,
            durationLinearVesting
          )
        : BigNumber.from(0),
    claimableProjectToken:
      balances !== undefined
        ? getValueProjectTokenPerVestingSchedule(
            ct.fractionInitialUnlockPerThousand,
            balances.partiallyChargedBalance,
            Math.min(timestamp, dateEndCliff + durationLinearVesting),
            Math.max(
              balances.dateOfPartiallyCharged.blockchainTimestamp,
              dateEndCliff
            ),
            false,
            durationLinearVesting
          )
        : BigNumber.from(0),
    ptNeededToRecharge:
      balances !== undefined
        ? balances.valueProjectTokenToFullRecharge
        : BigNumber.from(0),
  };
}

export function gql2state(input: Record<string, any>): any {
  return Object.assign(
    {},
    ...Object.entries(input)
      .filter(([field]) => !excludes.includes(field))
      .map(([field, value]) => ({
        [field]:
          fieldsMapping[field] !== undefined
            ? fieldsMapping[field](value)
            : value,
      }))
  );
}
