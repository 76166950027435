/* eslint-disable max-lines */
import { ethers } from "ethers";
import { call, delay, put, select, takeEvery } from "redux-saga/effects";
import {
  ContractsDirectory,
  DelegableToLT,
  InterfaceProjectToken,
  LiquidityToken,
} from "../../contracts";
import { AppState, ChargedToken, Delegable, Interface } from "../../types";
import { deployContract, formatAmount, getContractError } from "../../utils";
import {
  CLAIM_TOKENS,
  CREATE_INTERFACE,
  ClaimTokensAction,
  CreateInterfaceAction,
  RECHARGE_TOKENS,
  RechargeTokensAction,
  SET_CLAIM_FEE,
  SHOW_ALERT,
  START_INTERFACE,
  SetFeeAction,
  StartInterfaceAction,
} from "../actions";
import { CLEAR_TRANSACTION, SET_TRANSACTION } from "../actions/transaction";
import {
  appStateSelector,
  bootstrapSelector,
  createCTSelector,
  createDelegableSelector,
  createInterfaceSelector,
} from "../selectors";
import { waitForTransaction } from "./waitForTransaction";

function* createInterface(action: CreateInterfaceAction): any {
  const { provider, directory, account, chainId } = yield select(
    bootstrapSelector
  );
  const { deployNewProjectToken, tokenAddress, symbol } = action;
  let projectTokenAddress = action.projectTokenAddress;
  const ct: ChargedToken = yield select(createCTSelector(tokenAddress));

  let total = 3;
  let index = 1;

  if (!deployNewProjectToken) {
    // checking given project token address owner to detect wrong copy/paste
    const PT = new ethers.Contract(
      projectTokenAddress!,
      DelegableToLT.abi,
      provider
    );

    try {
      // checking for valid DelegableToLT contract
      yield PT.countValidatedInterfaceProjectToken();

      // checking token ownership
      const owner = yield PT.owner();
      if (owner !== account) {
        yield call(action.navigate, `/lt/${tokenAddress}/schedule`);

        yield put({
          type: SHOW_ALERT,
          title: "Interface deployment error",
          message:
            "Only the owner of the given project token can add it to the platform.",
          level: "error",
        });
        return;
      }
    } catch (err) {
      yield call(action.navigate, `/lt/${tokenAddress}/schedule`);

      yield put({
        type: SHOW_ALERT,
        title: "Interface deployment error",
        message: "The given project token address is not a valid token.",
        level: "error",
      });
      return;
    }
  }

  try {
    if (deployNewProjectToken) {
      total += 2;

      yield put({
        type: SET_TRANSACTION,
        msg: `Deploying ProjectToken ${symbol}`,
        index,
        total,
        returnTo: `/lt/${tokenAddress}/schedule`,
        navigate: action.navigate,
      });

      projectTokenAddress = (yield call(
        deployContract,
        "ProjectToken",
        provider,
        ct.projectName,
        symbol
      )) as string;

      index++;
    }

    const Directory = new ethers.Contract(
      directory,
      ContractsDirectory.abi,
      provider
    );

    const PT = new ethers.Contract(
      projectTokenAddress!,
      DelegableToLT.abi,
      provider.getSigner()
    );

    const projectName = yield call([PT, PT.name]);
    const ltProjectName = yield call(
      [Directory, Directory.projectRelatedToLT],
      tokenAddress
    );

    if (ltProjectName !== projectName) {
      const msg = `Invalid token address : project name mismatch (${projectName})`;
      yield put({ type: CLEAR_TRANSACTION });

      yield put({
        type: SHOW_ALERT,
        title: "Interface deployment error",
        message: msg,
        level: "error",
      });

      return;
    }
  } catch (err) {
    yield put({ type: CLEAR_TRANSACTION });

    yield put({
      type: SHOW_ALERT,
      title: "Interface deployment error",
      message: "The given address is not a valid token",
      level: "error",
    });
    return;
  }

  try {
    const PT = new ethers.Contract(
      projectTokenAddress!,
      DelegableToLT.abi,
      provider.getSigner()
    );

    yield put({
      type: SET_TRANSACTION,
      msg: "Deploying InterfaceProjectToken contract",
      index,
      total,
      returnTo: `/lt/${tokenAddress}/schedule`,
      navigate: action.navigate,
    });

    const interfaceAddress = yield call(
      deployContract,
      "InterfaceProjectToken",
      provider,
      tokenAddress,
      projectTokenAddress
    );
    index++;

    const LT = new ethers.Contract(
      tokenAddress,
      LiquidityToken.abi,
      provider.getSigner()
    );

    yield put({
      type: SET_TRANSACTION,
      msg: "Setting InterfaceProjectToken address on Charged Token",
      index,
      total,
      navigate: action.navigate,
    });

    let tx = yield call([LT, LT.setInterfaceProjectToken], interfaceAddress);
    yield call(waitForTransaction, chainId, tx.hash);

    yield put({
      type: SET_TRANSACTION,
      msg: "Waiting for tx to be mined",
      tx,
      navigate: action.navigate,
    });

    index++;

    if (deployNewProjectToken) {
      yield put({
        type: SET_TRANSACTION,
        msg: "Waiting for Project Token loading",
        navigate: action.navigate,
      });

      while (true) {
        const state: AppState = yield select(appStateSelector);
        if (state.delegableToLTs[projectTokenAddress!] !== undefined) {
          break;
        }
        yield delay(500);
      }

      index++;
    }

    yield put({
      type: SET_TRANSACTION,
      msg: "Adding InterfaceProjectToken address on ProjectToken's whitelist",
      index,
      total,
      tx: undefined,
      navigate: action.navigate,
    });

    tx = yield call([PT, PT.addInterfaceProjectToken], interfaceAddress);
    yield call(waitForTransaction, chainId, tx.hash);

    yield put({
      type: SET_TRANSACTION,
      msg: "Waiting for tx to be mined",
      tx,
      navigate: action.navigate,
    });

    yield put({ type: CLEAR_TRANSACTION });

    yield put({
      type: SHOW_ALERT,
      title: "Deployment successful",
      message: "Project token Interface deployed",
      level: "success",
    });
  } catch (err) {
    yield put({ type: CLEAR_TRANSACTION });
    yield put({
      type: SHOW_ALERT,
      title: "Interface deployment error",
      message: getContractError(err),
      level: "error",
    });
  }
}

function* setInterfaceStart(action: StartInterfaceAction): any {
  const { provider, chainId } = yield select(bootstrapSelector);
  const { tokenAddress, startDate } = action;
  const { interfaceProjectToken } = yield select(
    createCTSelector(tokenAddress)
  );

  try {
    const Interface = new ethers.Contract(
      interfaceProjectToken,
      InterfaceProjectToken.abi,
      provider.getSigner(0)
    );

    yield put({
      type: SET_TRANSACTION,
      msg: "Setting Token Generation Event (TGE)",
      index: 1,
      total: 2,
      returnTo: `/lt/${tokenAddress}/schedule`,
      navigate: action.navigate,
    });

    const tx = yield call(
      [Interface, Interface.setStart],
      startDate.blockchainTimestamp
    );

    yield put({
      type: SET_TRANSACTION,
      msg: "Waiting for transaction to be mined",
      index: 2,
      tx,
    });
    yield call(waitForTransaction, chainId, tx.hash);

    yield put({ type: CLEAR_TRANSACTION });
  } catch (err) {
    yield put({ type: CLEAR_TRANSACTION });
    yield put({
      type: SHOW_ALERT,
      title: "Interface start error",
      message: getContractError(err),
      level: "error",
    });
  }
}

function* claimProjectTokens(action: ClaimTokensAction): any {
  const { tokenAddress } = action;
  const { provider, advancedMode, chainId } = yield select(bootstrapSelector);
  const { interfaceProjectToken } = yield select(
    createCTSelector(tokenAddress)
  );

  const Interface = new ethers.Contract(
    interfaceProjectToken,
    InterfaceProjectToken.abi,
    provider.getSigner(0)
  );
  try {
    yield put({
      type: SET_TRANSACTION,
      msg: `Claiming tokens`,
      index: 1,
      total: 2,
      returnTo:
        advancedMode === true
          ? `/lt/${tokenAddress}/balancesAndTokenMgmt`
          : `/lt/${tokenAddress}/schedule`,
      navigate: action.navigate,
    });

    const tx = yield call([Interface, Interface.claimProjectToken]);

    yield put({
      type: SET_TRANSACTION,
      index: 2,
      tx,
      msg: "Waiting for claim to be processed...",
      navigate: action.navigate,
    });
    yield call(waitForTransaction, chainId, tx.hash);

    yield put({ type: CLEAR_TRANSACTION });
    yield put({
      type: SHOW_ALERT,
      title: "Claim success",
      message: "The available Project tokens have been claimed.",
      level: "success",
    });
  } catch (err) {
    yield put({ type: CLEAR_TRANSACTION });
    yield put({
      type: SHOW_ALERT,
      title: "Error during claim",
      message: getContractError(err),
      level: "error",
    });
  }
}

function* rechargeTokens(action: RechargeTokensAction): any {
  const { tokenAddress, amount } = action;
  const { provider, chainId } = yield select(bootstrapSelector);
  const { decimals, interfaceProjectToken } = yield select(
    createCTSelector(tokenAddress)
  );
  const iface: Interface = yield select(
    createInterfaceSelector(interfaceProjectToken)
  );
  const delegable: Delegable = yield select(
    createDelegableSelector(iface.projectToken)
  );

  const Interface = new ethers.Contract(
    interfaceProjectToken,
    InterfaceProjectToken.abi,
    provider.getSigner(0)
  );
  try {
    yield put({
      type: SET_TRANSACTION,
      msg: `Recharging tokens`,
      index: 1,
      total: 2,
      returnTo: `/lt/${tokenAddress}/balancesAndTokenMgmt`,
      navigate: action.navigate,
    });

    const tx = yield call([Interface, Interface.rechargeLT], amount);

    yield put({
      type: SET_TRANSACTION,
      index: 2,
      tx,
      msg: "Waiting for recharge to be processed...",
      navigate: action.navigate,
    });
    yield call(waitForTransaction, chainId, tx.hash);

    yield put({ type: CLEAR_TRANSACTION });

    yield put({
      type: SHOW_ALERT,
      title: "Recharge successful",
      message: `Recharged ${formatAmount(amount, decimals)} ${
        delegable.symbol
      }`,
      level: "success",
    });
  } catch (err) {
    yield put({ type: CLEAR_TRANSACTION });
    yield put({
      type: SHOW_ALERT,
      title: "Recharge failed",
      message: getContractError(err),
      level: "error",
    });
  }
}

function* updateFee(action: SetFeeAction): any {
  const { provider, chainId } = yield select(bootstrapSelector);
  const { tokenAddress, valuePerThousand } = action;

  const { interfaceProjectToken } = yield select(
    createCTSelector(tokenAddress)
  );

  const Interface = new ethers.Contract(
    interfaceProjectToken,
    InterfaceProjectToken.abi,
    provider.getSigner(0)
  );

  try {
    yield put({
      type: SET_TRANSACTION,
      msg: `Updating claim fee to ${formatAmount(valuePerThousand, 1, 1)} %`,
      index: 1,
      total: 2,
      returnTo: `/lt/${tokenAddress}/feesAndRewards`,
      navigate: action.navigate,
    });

    const tx = yield call(
      [Interface, Interface.updateClaimFeesForPT],
      valuePerThousand
    );

    yield put({
      type: SET_TRANSACTION,
      msg: "Waiting for transaction to be mined",
      index: 2,
      tx,
      navigate: action.navigate,
    });

    yield call(waitForTransaction, chainId, tx.hash);

    yield put({
      type: CLEAR_TRANSACTION,
    });
  } catch (err) {
    yield put({
      type: CLEAR_TRANSACTION,
    });
    yield put({
      type: SHOW_ALERT,
      title: "Fees update error",
      message: getContractError(err),
      level: "error",
    });
  }
}

export const interfaceProjectActionsSagas = [
  takeEvery(CREATE_INTERFACE, createInterface),
  takeEvery(START_INTERFACE, setInterfaceStart),
  takeEvery(CLAIM_TOKENS, claimProjectTokens),
  takeEvery(RECHARGE_TOKENS, rechargeTokens),
  takeEvery(SET_CLAIM_FEE, updateFee),
];
