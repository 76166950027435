export function getContractError(err: any): string {
  if (
    err.data !== undefined &&
    err.data.message !== undefined &&
    err.data.message !== ""
  )
    return err.data.message.replace(
      "VM Exception while processing transaction: revert ",
      ""
    );
  if (
    err.message !== undefined &&
    (err.message as string).includes("MetaMask - RPC Error: ")
  )
    return err.message.replace("MetaMask - RPC Error: ", "");
  if (err !== undefined) {
    console.error(err);
    return "An unexpected error occured, please retry later.";
  }
  throw new Error("Don't know how to handle undefined error");
}

export function showContractError(err: any): void {
  const message = getContractError(err);
  alert(message);
}
