import { isThemeDark } from "charged-token-themes";
import { default as moment, Moment } from "moment";
import { useCallback } from "react";
import { Form } from "react-bootstrap";
import Datetime from "react-datetime";
import { FieldMetaState } from "react-final-form";
import { Validator } from "../../types";

interface TextInputProps {
  label: string;
  placeholder?: string;
  meta: FieldMetaState<any>;
  name: string;
  value?: string;
  validator?: Validator;
  onChange: (event: any) => void;
  onBlur: (event: any) => void;
  onFocus: (event: any) => void;
}

function isMoment(date: string | Moment): date is Moment {
  return (date as Moment).calendar !== undefined;
}

const DateInput = ({
  name,
  label,
  placeholder,
  value,
  validator,
  meta,
  onChange,
  onBlur,
  onFocus,
}: TextInputProps) => {
  const darkTheme: boolean = isThemeDark();

  const wrappedValidator = (value: Moment) => {
    if (validator === undefined) return true;
    const strValue = value !== undefined ? value.format() : undefined;
    return validator(strValue) === undefined;
  };

  const wrappedOnChange = useCallback(
    (value: string | Moment) => {
      if (isMoment(value)) {
        onChange(value.format());
      } else {
        onChange(value);
      }
    },
    [onChange]
  );

  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <Datetime
        value={value !== undefined && value !== "" ? moment(value) : undefined}
        isValidDate={wrappedValidator}
        inputProps={{
          id: name,
          name,
          placeholder,
          autoComplete: "off",
          className: `text-end form-control has-validation ${
            meta.touched === true &&
            (meta.error !== undefined ? "is-invalid" : "is-valid")
          }`,
        }}
        closeOnSelect={true}
        onChange={wrappedOnChange}
        onClose={onBlur}
        onOpen={onFocus}
        className={darkTheme ? "dark" : ""}
      />
      {meta.error !== undefined && meta.touched === true && (
        <Form.Control.Feedback type="invalid">
          {meta.error}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

export default DateInput;
