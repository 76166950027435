import { initThemes } from "charged-token-themes";

// TODO : uncomment this block when developing to define the theme statically
// setCurrentTheme("yz");
// initThemes(false, false);

// TODO : comment this block when developing, it will detect and apply the theme depending on the hostname
initThemes(true, false);

import moment from "moment";
import "moment/min/locales";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import AppBootstrap from "./components/AppBootstrap";
import { Terms } from "./components/common";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store";

moment.locale(navigator.language);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <AppBootstrap>
          <Terms>
            <App />
          </Terms>
        </AppBootstrap>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
