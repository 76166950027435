import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { FieldMetaState } from "react-final-form";

interface NumberInputProps {
  label: string;
  placeholder?: string;
  meta: FieldMetaState<any>;
  name: string;
  value?: string;
  autoFocus?: boolean;
  onChange: (event: any) => void;
  onBlur: (event: any) => void;
  onFocus: (event: any) => void;
}

const NumberInput: React.FC<NumberInputProps> = ({
  name,
  label,
  placeholder,
  value = "",
  meta,
  autoFocus,
  children,
  onChange,
  onBlur,
  onFocus,
}) => {
  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <InputGroup>
        <Form.Control
          name={name}
          className={`text-end ${
            meta.touched === true &&
            (meta.error !== undefined ? "is-invalid" : "is-valid")
          }`}
          placeholder={placeholder}
          value={value}
          autoFocus={autoFocus}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
        />
        {children}
        {meta.error !== undefined && meta.touched === true && (
          <Form.Control.Feedback type="invalid">
            {meta.error}
          </Form.Control.Feedback>
        )}
      </InputGroup>
    </Form.Group>
  );
};

export default NumberInput;
