import { BigNumber } from "ethers";
import React, { useCallback } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Action } from "redux";
import { RechargeTokensAction, RECHARGE_TOKENS } from "../../../store/actions";
import { formatAmount } from "../../../utils";

export interface RechargeSummaryProps {
  tokenAddress: string;
  amount: BigNumber;
  currentRewards: BigNumber;
  symbol: string;
  projectSymbol: string;
  decimals: number;
  show: boolean;
  handleClose: () => void;
  doRecharge: (
    tokenAddress: string,
    amount: BigNumber,
    navigate: (path: string) => void
  ) => void;
  convert: (amount: string) => string;
}

export const RechargeSummary = ({
  tokenAddress,
  amount,
  currentRewards,
  symbol,
  projectSymbol,
  decimals,
  show,
  handleClose,
  doRecharge,
  convert,
}: RechargeSummaryProps) => {
  const navigate = useNavigate();
  const onSubmit = useCallback(() => {
    doRecharge(tokenAddress, amount, navigate);
    handleClose();
  }, [amount, doRecharge, handleClose, tokenAddress]);

  const rechargedAmount = BigNumber.from(convert(amount.toString()));
  const total = rechargedAmount.add(currentRewards);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header>
        <Modal.Title>Recharge summary</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Col xs={{ span: 8, offset: 2 }}>
          <div className="d-grid gap-2">
            <div className="text-center">
              <b>Amount used to recharge :</b> {formatAmount(amount, decimals)}{" "}
              {projectSymbol}
            </div>
            <hr />
            <p>
              <b>You will recharge :</b>{" "}
              {formatAmount(rechargedAmount, decimals)} {symbol}
            </p>
            <p>
              <b>Rewards :</b> {formatAmount(currentRewards, decimals)} {symbol}
            </p>
            <p>
              <b>Total :</b> {formatAmount(total, decimals)} {symbol}
            </p>
          </div>
        </Col>

        <Row>
          <Col className="d-grid gap-2">
            <button
              type="button"
              className="btn btn-lg btn-primary mt-3"
              onClick={onSubmit}
            >
              Confirm Recharge
            </button>
          </Col>
          <Col className="d-grid gap-2">
            <button
              type="button"
              className="btn btn-lg btn-secondary mt-3"
              onClick={handleClose}
            >
              Cancel
            </button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

function mapDispatchToProps(dispatch: (action: Action<any>) => void) {
  return {
    doRecharge: (
      tokenAddress: string,
      amount: BigNumber,
      navigate: (path: string) => void
    ) => {
      dispatch({
        type: RECHARGE_TOKENS,
        tokenAddress,
        amount,
        navigate,
      } as RechargeTokensAction);
    },
  };
}

export default connect(null, mapDispatchToProps)(RechargeSummary);
