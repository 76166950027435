import useAsyncFunction from "@studysync/react-redux-promise-listener-hook";
import { Button, Modal } from "react-bootstrap";
import { Field, Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import { promiseListener } from "../../store";
import {
  INTERFACE_STARTED,
  INTERFACE_START_ERROR,
  START_INTERFACE,
} from "../../store/actions";
import { DateWrapper } from "../../types";
import {
  requiredFutureDateValidator,
  requiredFutureOrEqualDateValidator,
} from "../../utils";
import DateInput from "../forms/DateInput";

interface StartInterfaceFormProps {
  show: boolean;
  handleClose: () => void;
  tokenAddress: string;
}

const StartInterfaceForm = ({
  show,
  handleClose,
  tokenAddress,
}: StartInterfaceFormProps) => {
  const navigate = useNavigate();
  const setPayload = (action: any, { startDate }: any) => {
    return {
      ...action,
      tokenAddress,
      startDate: DateWrapper.fromString(startDate),
      navigate,
    };
  };

  const asyncFuncConfig = {
    start: START_INTERFACE,
    resolve: INTERFACE_STARTED,
    reject: INTERFACE_START_ERROR,
    setPayload,
  };
  const asyncFunc = useAsyncFunction(asyncFuncConfig, promiseListener);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header>
        <Modal.Title>Set Token Generation Event (TGE)</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form
          onSubmit={asyncFunc}
          render={({ handleSubmit, form, submitting, pristine }) => (
            <form
              onSubmit={(event) => {
                return handleSubmit(event)!
                  .then(() => handleClose())
                  .catch(() => handleClose());
              }}
              noValidate
              className="needs-validation d-grid gap-3"
            >
              <Field name="startDate" validate={requiredFutureDateValidator}>
                {({ input, meta }) => (
                  <DateInput
                    label="Start date"
                    meta={meta}
                    validator={requiredFutureOrEqualDateValidator}
                    {...input}
                  />
                )}
              </Field>

              <Button
                type="submit"
                variant="primary"
                size="lg"
                disabled={pristine || submitting || form.getState().invalid}
              >
                Set TGE
              </Button>
            </form>
          )}
        ></Form>
      </Modal.Body>
    </Modal>
  );
};

export default StartInterfaceForm;
