import { Form, InputGroup } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import CopyButton from "./CopyButton";

interface WalletProps {
  network: string;
  address: string;
}

export const Wallet = ({ address }: WalletProps) => {
  const shortAddress = `${address.substr(0, 6)}...${address.substr(-4)}`;
  return (
    <InputGroup>
      <InputGroup.Text>
        <Icon.Wallet2 />
      </InputGroup.Text>
      <Form.Control value={shortAddress} readOnly htmlSize={9} />
      <CopyButton msg="Address copied to clipboard" value={address} />
    </InputGroup>
  );
};

export default Wallet;
