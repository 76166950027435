import { useEffect, useRef } from "react";
import { Spinner, Stack } from "react-bootstrap";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bootstrapSelector, transactionSelector } from "../../store/selectors";
import { AppState, TransactionState } from "../../types";
import { ETHERSCAN_TX_PREFIX } from "../../utils/environment";
import FlexScreen from "./FlexScreen";

interface TransactionSummaryProps extends TransactionState {
  chainId: string;
}

export const TransactionSummary = ({
  index,
  total,
  tx,
  msg,
  chainId,
  summary,
  msgStack,
}: TransactionSummaryProps) => {
  return (
    <FlexScreen>
      <h3 className="m-4">Step {total !== 0 && `${index}/${total}`}</h3>

      <h4 className="m-4">{msg}</h4>

      {summary ? summary : <></>}

      {msgStack.length > 0 ? (
        <div className="m-4">
          <Stack>
            {msgStack.map((oldMsg, index) => (
              <div key={index}>
                <i className="bi bi-check text-primary"></i>{" "}
                {`${msgStack.length - index}. ${oldMsg}`}
              </div>
            ))}
          </Stack>
        </div>
      ) : (
        <></>
      )}

      <Spinner animation="border" className="m-4" />
      {tx !== undefined && tx.hash !== undefined ? (
        <span className="m-4">
          See transaction status on{" "}
          <a
            href={ETHERSCAN_TX_PREFIX[chainId](tx.hash)}
            target="_blank"
            rel="noreferrer"
          >
            Blockchain Explorer
          </a>
        </span>
      ) : (
        <></>
      )}
    </FlexScreen>
  );
};

export const TransactionSummaryWrapper = ({
  chainId,
  transaction,
}: {
  chainId: string;
  transaction: TransactionState | null;
}) => {
  const navigate = useNavigate();

  const returnTo = useRef("/");

  useEffect(() => {
    if (transaction === null) {
      navigate(returnTo.current);
    } else {
      returnTo.current = transaction.returnTo;
    }
  }, [navigate, returnTo, transaction]);

  return transaction === null ? (
    <FlexScreen>
      <h1>There is no pending transaction at the moment.</h1>
    </FlexScreen>
  ) : (
    <div className="transaction-summary">
      <TransactionSummary chainId={chainId} {...transaction} />
    </div>
  );
};

function mapStateToProps(state: AppState) {
  const chainId = bootstrapSelector(state).network;
  const transaction = transactionSelector(state);

  return {
    chainId,
    transaction,
  };
}

export default connect(mapStateToProps)(TransactionSummaryWrapper);
