import { useMemo } from "react";
import { Deploy } from "./admin";

interface UnknownNetworkProps {
  chainId: number;
}

export const UnknownNetwork = ({ chainId }: UnknownNetworkProps) => {
  const isTestEnv = useMemo(
    () =>
      ["test.app.yz-network.com", "localhost"].includes(
        document.location.hostname
      ),
    [document.location.hostname]
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h3 className="mb-5">Unknown network : Chain id {chainId}</h3>
      <div className="mt-5" style={{ textAlign: "left" }}>
        Supported networks :<br />
        <br />
        {!isTestEnv && (
          <>
            <b>Mainnets :</b>{" "}
            <ul>
              <li>
                <a
                  href="https://wiki.polygon.technology/docs/develop/metamask/config-polygon-on-metamask/"
                  target="_blank"
                >
                  Polygon
                </a>{" "}
                (id:137)
              </li>
              <li>
                <a href="https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain">
                  Binance Smart Chain
                </a>{" "}
                (id:56)
              </li>
            </ul>
          </>
        )}
        {isTestEnv && (
          <>
            <b>Testnets :</b>
            <ul>
              <li>
                Ethereum Sepolia Testnet (
                <a href="https://faucetlink.to/sepolia" target="_blank">
                  faucets links
                </a>
                ) (id:11155111)
              </li>
              <li>
                <a
                  href="https://polygon.technology/blog/introducing-the-amoy-testnet-for-polygon-pos"
                  target="_blank"
                >
                  Polygon Amoy Testnet
                </a>{" "}
                (
                <a
                  href="https://faucet.polygon.technology/?__cf_chl_tk=4iuRc7uMiKTnM7h1iI0AX1zmgMvixsklRC6p16udMoU-1710939044-0.0.1.1-1514"
                  target="_blank"
                >
                  faucet link
                </a>
                ) (id:80002)
              </li>
            </ul>
          </>
        )}
      </div>

      <Deploy />
    </div>
  );
};

export default UnknownNetwork;
