import { DeployLTAction } from "../../../store/actions";
import { formatAmount } from "../../../utils";

export type DeployLTSummaryProps = Omit<DeployLTAction, "type">;

export const DeployLTSummary = ({
  name,
  symbol,
  fractionInitialUnlockPerThousand,
  durationCliff,
  durationLinearVesting,
  maxWithdrawFeesPerThousand,
  maxClaimFeesPerThousand,
  maxStakingAPR,
  maxStakingTokenAmount,
  maxInitialTokenAllocation,
}: DeployLTSummaryProps) => (
  <div className="d-grid gap-2 text-center">
    <h4 className="mb-5">
      Please review the following details before signing the transaction.
    </h4>
    <p>
      <b>Name :</b> {name}
    </p>
    <p>
      <b>Symbol :</b> {symbol}
    </p>
    <p>
      <b>Maximum initial token allocation :</b>
      {` ${formatAmount(maxInitialTokenAllocation, 18)}`}
    </p>
    <p>
      <b>Initial unlock :</b>{" "}
      {`${formatAmount(fractionInitialUnlockPerThousand, 1, 1)} %`}
    </p>
    <p>
      <b>Cliff duration :</b>{" "}
      {durationCliff.isSet ? durationCliff.toDurationString() : "none"}
    </p>
    <p>
      <b>Vesting duration :</b>{" "}
      {durationLinearVesting.isSet
        ? durationLinearVesting.toDurationString()
        : "none"}
    </p>
    <p>
      <b>Maximum withdrawal fee :</b>{" "}
      {`${(Number(maxWithdrawFeesPerThousand) / 10).toFixed(1)} %`}
    </p>
    <p>
      <b>Maximum claiming fee :</b>{" "}
      {`${(Number(maxClaimFeesPerThousand) / 10).toFixed(1)} %`}
    </p>
    <p>
      <b>Maximum staking token amount :</b>
      {` ${formatAmount(maxStakingTokenAmount, 18)}`} {symbol}
    </p>
    <p>
      <b>Maximum staking APR :</b>
      {` ${formatAmount(maxStakingAPR, 0)} %`}
    </p>
  </div>
);

export default DeployLTSummary;
