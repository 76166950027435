import { Button, Container, Table } from "react-bootstrap";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { REMOVE_PROJECT_FROM_WHITELIST } from "../../store/actions";
import { AppState } from "../../types";
import { EMPTY_ADDRESS } from "../../utils";

interface Project {
  name: string;
  owner: string;
  contracts: string[];
  contractNames: string[];
}

interface ProjectsListProps {
  projects: Record<string, Project>;
  removeProject: (
    name: string,
    owner: string,
    navigate: (path: string) => void
  ) => void;
}

const ProjectsList = ({ projects, removeProject }: ProjectsListProps) => {
  const navigate = useNavigate();

  return (
    <Container>
      <h3>All contracts</h3>
      <Table>
        <thead>
          <tr>
            <th>Project name</th>
            <th>Project owner's address</th>
            <th>Liquidity tokens list</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(projects).length > 0 &&
            Object.values(projects).map(
              ({ name, owner, contracts, contractNames }, index) => (
                <tr key={index}>
                  <td>{name}</td>
                  <td>{owner}</td>
                  <td>
                    {contracts.length > 0 && (
                      <ul>
                        {contracts.map((contract, index) => (
                          <li key={index}>
                            {contractNames[index]} ({contract})
                          </li>
                        ))}
                      </ul>
                    )}
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      onClick={() => removeProject(name, owner, navigate)}
                      disabled={contracts.length !== 0}
                    >
                      <i className="bi bi-trash"></i>
                    </Button>
                  </td>
                </tr>
              )
            )}
          {Object.keys(projects).length === 0 && (
            <tr>
              <td colSpan={4} className="text-center">
                <i>There is no projects in the platform whitelist.</i>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
};

function mapStateToProps(state: AppState) {
  const directory = state.directory;
  const cts = state.chargedTokens;

  const projects: Record<string, Project> = {};

  if (directory === undefined) return { projects };

  directory.projects.forEach((name: string, index: number) => {
    projects[name] = {
      name,
      owner: directory.whitelistedProjectOwners[index],
      contracts: [],
      contractNames: [],
    };
  });

  Object.keys(cts).forEach((address) => {
    const ct = cts[address];
    const name = ct.projectName;
    if (projects[name] !== undefined) {
      projects[name].contracts.push(address);
      projects[name].contractNames.push(ct.name);
    } else {
      const unknownName = "Unknown";
      if (projects[unknownName] === undefined) {
        projects[unknownName] = {
          name: unknownName,
          owner: EMPTY_ADDRESS,
          contracts: [],
          contractNames: [],
        };
      }
      projects[unknownName].contracts.push(address);
      projects[unknownName].contractNames.push(ct.name);
    }
  });

  console.log("projects after contracts init :", JSON.stringify(projects));

  return {
    projects,
  };
}

function mapDispatchToProps(dispatch: (action: any) => void) {
  return {
    removeProject: (
      name: string,
      owner: string,
      navigate: (path: string) => void
    ) => {
      dispatch({
        type: REMOVE_PROJECT_FROM_WHITELIST,
        name,
        owner,
        navigate,
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsList);
