import { useMemo } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ADD_INTERFACE_TO_PROJECT,
  REMOVE_INTERFACE_FROM_TOKEN,
  REMOVE_LT,
  TOGGLE_USER_FUNCTIONS,
} from "../../../store/actions";
import {
  createCTSelector,
  createDelegableSelector,
  createInterfaceSelector,
} from "../../../store/selectors";
import { AppState } from "../../../types";
import { EMPTY_ADDRESS } from "../../../utils";

interface TroubleshootingProps {
  tokenAddress: string;
  projectToken: string;
  interfaceAddress: string;
  disabled?: boolean;
  whitelist: string[];
  toggleInterface: (
    tokenAddress: string,
    projectToken: string,
    interfaceAddress: string,
    interfaceDisabled: boolean,
    navigate: (path: string) => void
  ) => void;
  toggleUserFunctions: (
    tokenAddress: string,
    navigate: (path: string) => void
  ) => void;
  removeLT: (address: string, navigate: (path: string) => void) => void;
}

export const Troubleshooting = ({
  tokenAddress,
  projectToken,
  interfaceAddress,
  disabled,
  whitelist,
  toggleInterface,
  toggleUserFunctions,
  removeLT,
}: TroubleshootingProps) => {
  const navigate = useNavigate();
  const interfaceDisabled = useMemo(() => {
    return !whitelist.includes(interfaceAddress);
  }, [interfaceAddress, whitelist]);

  return (
    <Card>
      <Card.Header as="h5" className="text-center">
        Troubleshooting
      </Card.Header>
      <Card.Body>
        <Row>
          <Col
            xl={{ span: 6, offset: 3 }}
            md={{ span: 6, offset: 3 }}
            sm={{ span: 8, offset: 2 }}
            xs={12}
            className="d-grid gap-2"
          >
            {disabled !== undefined && (
              <Button
                className="text-center"
                type="button"
                variant={disabled ? "success" : "danger"}
                onClick={() => toggleUserFunctions(tokenAddress, navigate)}
              >
                {disabled ? "Enable" : "Disable"} user interactions
              </Button>
            )}
            {interfaceAddress !== EMPTY_ADDRESS &&
              projectToken !== EMPTY_ADDRESS && (
                <Button
                  className="text-center"
                  variant={interfaceDisabled ? "success" : "danger"}
                  type="button"
                  onClick={() =>
                    toggleInterface(
                      tokenAddress,
                      projectToken,
                      interfaceAddress,
                      interfaceDisabled,
                      navigate
                    )
                  }
                >
                  {interfaceDisabled ? "Enable" : "Disable"} Interface to
                  Project Token
                </Button>
              )}
            <Button
              variant="danger"
              type="button"
              className="text-center"
              onClick={() => removeLT(tokenAddress, navigate)}
            >
              <i className="bi bi-trash"></i>Remove contract from the platform
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

function mapStateToProps(
  state: AppState,
  ownProps: Pick<TroubleshootingProps, "tokenAddress">
) {
  const ct = createCTSelector(ownProps.tokenAddress)(state);
  const iface = createInterfaceSelector(ct.interfaceProjectToken)(state);
  const delegable =
    iface !== undefined
      ? createDelegableSelector(iface.projectToken)(state)
      : undefined;

  return {
    ...ownProps,
    interfaceAddress: ct.interfaceProjectToken,
    projectToken: iface !== undefined ? iface.projectToken : EMPTY_ADDRESS,
    disabled: ct.areUserFunctionsDisabled,
    whitelist:
      delegable !== undefined ? delegable.validatedInterfaceProjectToken : [],
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    toggleUserFunctions: (
      tokenAddress: string,
      navigate: (path: string) => void
    ) =>
      dispatch({
        type: TOGGLE_USER_FUNCTIONS,
        tokenAddress,
        navigate,
      }),
    toggleInterface: (
      tokenAddress: string,
      projectToken: string,
      interfaceAddress: string,
      interfaceDisabled: boolean,
      navigate: (path: string) => void
    ) =>
      dispatch({
        type: interfaceDisabled
          ? ADD_INTERFACE_TO_PROJECT
          : REMOVE_INTERFACE_FROM_TOKEN,
        tokenAddress,
        projectToken,
        interfaceAddress,
        navigate,
      }),
    removeLT: (address: string, navigate: (path: string) => void) =>
      dispatch({
        type: REMOVE_LT,
        address,
        navigate,
      }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Troubleshooting);
