/* eslint-disable max-lines */
import { ethers } from "ethers";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { ContractsDirectory, ERC20, LiquidityToken } from "../../contracts";
import {
  convertToCT,
  deployContract,
  formatAmount,
  getContractError,
} from "../../utils";
import {
  CREATE_ALLOCATIONS,
  CreateAllocationsAction,
  DEPLOY_LT,
  DEPOSIT_LT,
  DeployLTAction,
  DepositLTAction,
  INVEST_BY_USER,
  INVEST_BY_USER_ERROR,
  INVEST_BY_USER_SUCCESS,
  InvestByUserAction,
  LOCK_INTERFACE,
  LT_SET_RATIO_FEE,
  LT_SET_WITHDRAW_FEE,
  LockInterfaceAction,
  NEW_STAKING_CAMPAIGN,
  NewStakingCampaignAction,
  SET_FUNDRAISING_CONDITIONS,
  SHOW_ALERT,
  SetFeeAction,
  SetFundraisingConditionsAction,
  TERMINATE_ALLOCATIONS,
  TOGGLE_FUNDRAISING,
  TOGGLE_USER_FUNCTIONS,
  TerminateAllocationsAction,
  ToggleFundraisingAction,
  ToggleUserFunctionsAction,
  WITHDRAW_LT,
  WithdrawLTAction,
} from "../actions";
import { CLEAR_TRANSACTION, SET_TRANSACTION } from "../actions/transaction";
import { bootstrapSelector, createCTSelector } from "../selectors";
import { waitForTransaction } from "./waitForTransaction";

function* deployLiquidityToken(action: DeployLTAction): any {
  const { provider, directory, chainId } = yield select(bootstrapSelector);

  const { summary } = action;

  try {
    yield put({
      type: SET_TRANSACTION,
      index: 1,
      total: 3,
      summary,
      msg: "Deploying new Charged Token contract",
      returnTo: "/",
      navigate: action.navigate,
    });

    const address = yield call(
      deployContract,
      "LiquidityToken",
      provider,
      action.name,
      action.symbol,
      action.fractionInitialUnlockPerThousand,
      action.durationCliff.blockchainTimestamp,
      action.durationLinearVesting.blockchainTimestamp,
      action.maxWithdrawFeesPerThousand,
      action.maxClaimFeesPerThousand,
      action.maxStakingAPR,
      action.maxStakingTokenAmount,
      action.maxInitialTokenAllocation
    );

    const Directory = new ethers.Contract(
      directory,
      ContractsDirectory.abi,
      provider.getSigner()
    );

    yield put({
      type: SET_TRANSACTION,
      index: 2,
      msg: "Adding new Charged Token contract to directory",
      summary: undefined,
      navigate: action.navigate,
    });

    const tx = yield call([Directory, Directory.addLTContract], address);

    yield put({
      type: SET_TRANSACTION,
      index: 3,
      msg: "Waiting for transaction to be mined",
      tx,
      navigate: action.navigate,
    });

    yield call(waitForTransaction, chainId, tx.hash);

    yield put({ type: CLEAR_TRANSACTION });

    yield put({
      type: SHOW_ALERT,
      title: "Deployment successful",
      message: "Charged Token deployed",
      level: "success",
    });
  } catch (err) {
    yield put({ type: CLEAR_TRANSACTION });

    yield put({
      type: SHOW_ALERT,
      title: "Charged Token deployment error",
      message: getContractError(err),
      level: "error",
    });
  }
}

function* setFundraisingConditions(
  action: SetFundraisingConditionsAction
): any {
  const { provider, chainId } = yield select(bootstrapSelector);
  const { tokenAddress, fundraisingToken, summary, tokenPrice1e18 } = action;

  console.log("setting fundraising conditions from", action);

  try {
    const LT = new ethers.Contract(
      tokenAddress,
      LiquidityToken.abi,
      provider.getSigner()
    );

    yield put({
      type: SET_TRANSACTION,
      msg: "Setting fundraising conditions",
      index: 1,
      total: 2,
      summary,
      returnTo: `/lt/${tokenAddress}/allocations`,
      navigate: action.navigate,
    });

    const tx = yield call(
      [LT, LT.setFundraisingConditions],
      fundraisingToken,
      tokenPrice1e18
    );

    yield put({
      type: SET_TRANSACTION,
      msg: "Waiting for fundraising conditions transaction to be mined...",
      summary: undefined,
      tx,
      index: 2,
      navigate: action.navigate,
    });

    yield call(waitForTransaction, chainId, tx.hash);

    yield put({ type: CLEAR_TRANSACTION });

    yield put({
      type: SHOW_ALERT,
      title: "Fundraising conditions set",
      message: "The fundraising conditions have been set",
      level: "success",
    });
  } catch (err) {
    yield put({ type: CLEAR_TRANSACTION });

    yield put({
      type: SHOW_ALERT,
      title: "Fundraising conditions error",
      message: getContractError(err),
      level: "error",
    });
  }
}

function* toggleFundraising(action: ToggleFundraisingAction): any {
  const { provider, chainId } = yield select(bootstrapSelector);
  const { tokenAddress } = action;

  try {
    const LT = new ethers.Contract(
      tokenAddress,
      LiquidityToken.abi,
      provider.getSigner()
    );

    const tx = yield call([LT, LT.changeFundraisingStatus]);
    yield call(waitForTransaction, chainId, tx.hash);
  } catch (err) {
    yield put({
      type: SHOW_ALERT,
      title: "Error changing fundraising status",
      message: getContractError(err),
      level: "error",
    });
  }
}

function* investByUser(action: InvestByUserAction): any {
  const { tokenAddress, amount, navigate } = action;
  const { provider, chainId } = yield select(bootstrapSelector);
  const {
    decimals,
    symbol,
    fundraisingToken,
    fundraisingTokenSymbol,
    priceTokenPer1e18,
  } = yield select(createCTSelector(tokenAddress));

  const FT = new ethers.Contract(
    fundraisingToken,
    ERC20.abi,
    provider.getSigner(0)
  );

  const fundraisingDecimals: number = yield call([FT, FT.decimals]);

  const LT = new ethers.Contract(
    tokenAddress,
    LiquidityToken.abi,
    provider.getSigner(0)
  );

  try {
    yield put({
      type: SET_TRANSACTION,
      msg: `Approving spending`,
      index: 1,
      total: 4,
      returnTo: `/lt/${tokenAddress}/allocations`,
      navigate,
    });

    let tx = yield call([FT, FT.approve], tokenAddress, amount);

    yield put({
      type: SET_TRANSACTION,
      index: 2,
      tx,
      msg: "Waiting for approve to be processed...",
      navigate,
    });

    // this case is specific, the fundraising contract is not watched by the api
    yield call([tx, tx.wait]);

    yield put({
      type: SET_TRANSACTION,
      msg: `Investing`,
      index: 3,
      returnTo: `/lt/${tokenAddress}/allocations`,
      navigate,
    });

    tx = yield call([LT, LT.investByUser], amount);

    yield put({
      type: SET_TRANSACTION,
      index: 4,
      tx,
      msg: "Waiting for investment to be processed...",
      navigate,
    });

    yield call(waitForTransaction, chainId, tx.hash);

    yield put({ type: CLEAR_TRANSACTION });
    yield put({ type: INVEST_BY_USER_SUCCESS });
    yield put({
      type: SHOW_ALERT,
      title: "Investment successful",
      message: `Invested ${formatAmount(
        amount,
        fundraisingDecimals
      )} ${fundraisingTokenSymbol} for ${formatAmount(
        convertToCT(amount, fundraisingDecimals, priceTokenPer1e18, decimals),
        decimals
      )} ${symbol}`,
      level: "success",
    });
  } catch (err) {
    yield put({ type: CLEAR_TRANSACTION });
    yield put({ type: INVEST_BY_USER_ERROR });
    yield put({
      type: SHOW_ALERT,
      title: "Fundraising investment error",
      message: getContractError(err),
      level: "error",
    });
  }
}

function* createAllocations(action: CreateAllocationsAction): any {
  const { provider, chainId } = yield select(bootstrapSelector);
  const { tokenAddress, addresses, amounts, areAllocationsToBeStaked } = action;

  const { summary } = action;

  try {
    const LT = new ethers.Contract(
      tokenAddress,
      LiquidityToken.abi,
      provider.getSigner()
    );

    yield put({
      type: SET_TRANSACTION,
      msg: "Creating allocations",
      index: 1,
      total: 2,
      summary,
      returnTo: `/lt/${tokenAddress}/allocations`,
      navigate: action.navigate,
    });

    const tx = yield call(
      [LT, LT.allocateLTByOwner],
      addresses,
      amounts,
      areAllocationsToBeStaked
    );

    yield put({
      type: SET_TRANSACTION,
      msg: "Waiting for allocation creation to be mined...",
      summary: undefined,
      tx,
      index: 2,
      navigate: action.navigate,
    });

    yield call(waitForTransaction, chainId, tx.hash);

    yield put({ type: CLEAR_TRANSACTION });

    yield put({
      type: SHOW_ALERT,
      title: "Allocation successful",
      message: "The allocations have been created",
      level: "success",
    });
  } catch (err) {
    yield put({ type: CLEAR_TRANSACTION });

    yield put({
      type: SHOW_ALERT,
      title: "Allocation error",
      message: getContractError(err),
      level: "error",
    });
  }
}

function* withdrawTokens(action: WithdrawLTAction): any {
  const { tokenAddress, amount } = action;
  const { provider, chainId } = yield select(bootstrapSelector);
  const { decimals, symbol } = yield select(createCTSelector(tokenAddress));

  const LT = new ethers.Contract(
    tokenAddress,
    LiquidityToken.abi,
    provider.getSigner(0)
  );
  try {
    yield put({
      type: SET_TRANSACTION,
      msg: `Withdrawing tokens`,
      index: 1,
      total: 2,
      returnTo: `/lt/${tokenAddress}/balancesAndTokenMgmt`,
      navigate: action.navigate,
    });

    const tx = yield call([LT, LT.withdrawLT], amount);

    yield put({
      type: SET_TRANSACTION,
      index: 2,
      tx,
      msg: "Waiting for withdraw to be processed...",
      navigate: action.navigate,
    });

    yield call(waitForTransaction, chainId, tx.hash);

    yield put({ type: CLEAR_TRANSACTION });
    yield put({
      type: SHOW_ALERT,
      title: "Withdraw successful",
      message: `Withdrew ${formatAmount(amount, decimals)} ${symbol}`,
      level: "success",
    });
  } catch (err) {
    yield put({ type: CLEAR_TRANSACTION });
    yield put({
      type: SHOW_ALERT,
      title: "Withdraw error",
      message: getContractError(err),
      level: "error",
    });
  }
}

function* depositTokens(action: DepositLTAction): any {
  const { tokenAddress, amount } = action;
  const { provider, chainId } = yield select(bootstrapSelector);
  const { decimals, symbol } = yield select(createCTSelector(tokenAddress));

  const LT = new ethers.Contract(
    tokenAddress,
    LiquidityToken.abi,
    provider.getSigner(0)
  );
  try {
    yield put({
      type: SET_TRANSACTION,
      msg: `Depositing tokens`,
      index: 1,
      total: 2,
      returnTo: `/lt/${tokenAddress}/balancesAndTokenMgmt`,
      navigate: action.navigate,
    });

    const tx = yield call([LT, LT.depositLT], amount);

    yield put({
      type: SET_TRANSACTION,
      index: 2,
      tx,
      msg: "Waiting for deposit to be processed...",
      navigate: action.navigate,
    });
    yield call(waitForTransaction, chainId, tx.hash);

    yield put({ type: CLEAR_TRANSACTION });

    yield put({
      type: SHOW_ALERT,
      title: "Deposit successful",
      message: `Deposited ${formatAmount(amount, decimals)} ${symbol}`,
      level: "success",
    });
  } catch (err) {
    yield put({ type: CLEAR_TRANSACTION });

    yield put({
      type: SHOW_ALERT,
      title: "Deposit error",
      message: getContractError(err),
      level: "error",
    });
  }
}

function* toggleUserFunctions(action: ToggleUserFunctionsAction): any {
  const { provider, chainId } = yield select(bootstrapSelector);
  const { tokenAddress } = action;

  try {
    const LT = new ethers.Contract(
      tokenAddress,
      LiquidityToken.abi,
      provider.getSigner()
    );

    const tx = yield call([LT, LT.enableOrDisableUserFunctions]);
    yield call(waitForTransaction, chainId, tx.hash);
  } catch (err) {
    yield put({
      type: SHOW_ALERT,
      title: "Error toggling user functions",
      message: getContractError(err),
      level: "error",
    });
  }
}

function* terminateAllocations(action: TerminateAllocationsAction): any {
  const { provider, chainId } = yield select(bootstrapSelector);
  const { tokenAddress } = action;

  try {
    yield put({
      type: SET_TRANSACTION,
      msg: "Terminating allocations",
      index: 1,
      total: 2,
      returnTo: `/lt/${tokenAddress}/schedule`,
      navigate: action.navigate,
    });

    const LT = new ethers.Contract(
      tokenAddress,
      LiquidityToken.abi,
      provider.getSigner()
    );

    const tx = yield call([LT, LT.terminateAllocations]);

    yield put({
      type: SET_TRANSACTION,
      msg: "Waiting for transaction to be mined",
      index: 2,
      navigate: action.navigate,
    });

    yield call(waitForTransaction, chainId, tx.hash);
    yield put({ type: CLEAR_TRANSACTION });
  } catch (err) {
    yield put({ type: CLEAR_TRANSACTION });
    yield put({
      type: SHOW_ALERT,
      title: "Error terminating allocations",
      message: getContractError(err),
      level: "error",
    });
  }
}

function* lockInterface(action: LockInterfaceAction): any {
  const { provider, chainId } = yield select(bootstrapSelector);
  const { tokenAddress } = action;

  try {
    const LT = new ethers.Contract(
      tokenAddress,
      LiquidityToken.abi,
      provider.getSigner()
    );

    const tx = yield call([LT, LT.lockInterfaceProjectToken]);
    yield call(waitForTransaction, chainId, tx.hash);
  } catch (err) {
    yield put({
      type: SHOW_ALERT,
      title: "Error locking interface",
      message: getContractError(err),
      level: "error",
    });
  }
}

function* updateFee(action: SetFeeAction): any {
  const { provider, chainId } = yield select(bootstrapSelector);
  const { type: actionType, tokenAddress, valuePerThousand } = action;

  const LT = new ethers.Contract(
    tokenAddress,
    LiquidityToken.abi,
    provider.getSigner()
  );

  let estimateGasPassed = false;

  try {
    yield call(
      [
        LT,
        actionType === LT_SET_WITHDRAW_FEE
          ? LT.estimateGas.updateWithdrawalFeesForLT
          : LT.estimateGas.updateRatioFeesToRewardHodlers,
      ],
      valuePerThousand
    );
    estimateGasPassed = true;

    yield put({
      type: SET_TRANSACTION,
      msg: `Updating ${
        actionType === LT_SET_WITHDRAW_FEE
          ? "withdraw fee"
          : "ratio of withdrawal fees for stakers"
      } to ${formatAmount(valuePerThousand, 1, 1)} %`,
      index: 1,
      total: 2,
      returnTo: `/lt/${tokenAddress}/feesAndRewards`,
      navigate: action.navigate,
    });

    const tx = yield call(
      [
        LT,
        actionType === LT_SET_WITHDRAW_FEE
          ? LT.updateWithdrawalFeesForLT
          : LT.updateRatioFeesToRewardHodlers,
      ],
      valuePerThousand
    );

    yield put({
      type: SET_TRANSACTION,
      msg: "Waiting for transaction to be mined",
      index: 2,
      tx,
      navigate: action.navigate,
    });

    yield call(waitForTransaction, chainId, tx.hash);

    yield put({
      type: CLEAR_TRANSACTION,
    });
  } catch (err) {
    yield put({
      type: SHOW_ALERT,
      title: "Fees update error",
      message: getContractError(err),
      level: "error",
    });

    if (estimateGasPassed) {
      yield put({
        type: CLEAR_TRANSACTION,
      });
    }
  }
}

function* createStakingCampaign(action: NewStakingCampaignAction): any {
  const { provider, chainId } = yield select(bootstrapSelector);
  const { tokenAddress, startDate, duration, rewards } = action;

  const { summary } = action;

  try {
    yield put({
      type: SET_TRANSACTION,
      index: 1,
      total: 2,
      summary,
      msg: "Creating new staking campaign",
      returnTo: `/lt/${tokenAddress}/feesAndRewards`,
      navigate: action.navigate,
    });

    const LT = new ethers.Contract(
      tokenAddress,
      LiquidityToken.abi,
      provider.getSigner()
    );

    const tx = yield call(
      [LT, LT.setStakingRewards],
      startDate.blockchainTimestamp,
      duration.blockchainTimestamp,
      rewards
    );

    yield put({
      type: SET_TRANSACTION,
      index: 2,
      summary: undefined,
      tx,
      navigate: action.navigate,
    });

    yield call(waitForTransaction, chainId, tx.hash);

    yield put({ type: CLEAR_TRANSACTION });
  } catch (err) {
    yield put({ type: CLEAR_TRANSACTION });
    yield put({
      type: SHOW_ALERT,
      title: "Staking campaign creation error",
      message: getContractError(err),
      level: "error",
    });
  }
}

export const liquidityTokenActionsSagas = [
  takeEvery(DEPLOY_LT, deployLiquidityToken),
  takeEvery(SET_FUNDRAISING_CONDITIONS, setFundraisingConditions),
  takeEvery(TOGGLE_FUNDRAISING, toggleFundraising),
  takeEvery(INVEST_BY_USER, investByUser),
  takeEvery(CREATE_ALLOCATIONS, createAllocations),
  takeEvery(WITHDRAW_LT, withdrawTokens),
  takeEvery(DEPOSIT_LT, depositTokens),
  takeEvery(TOGGLE_USER_FUNCTIONS, toggleUserFunctions),
  takeEvery(LT_SET_WITHDRAW_FEE, updateFee),
  takeEvery(LT_SET_RATIO_FEE, updateFee),
  takeEvery(NEW_STAKING_CAMPAIGN, createStakingCampaign),
  takeEvery(TERMINATE_ALLOCATIONS, terminateAllocations),
  takeEvery(LOCK_INTERFACE, lockInterface),
];
