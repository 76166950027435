import React from "react";
import { Card } from "react-bootstrap";
import LiquidTokenPie from "./LiquidTokenPie";

interface TokenDistributionProps {
  tokenAddress: string;
  symbol: string;
}

export const TokenDistribution = ({
  tokenAddress,
  symbol,
}: TokenDistributionProps) => (
  <Card>
    <Card.Header as="h5" className="text-center">
      Project Token distribution via {symbol}
    </Card.Header>
    <Card.Body>
      <LiquidTokenPie tokenAddress={tokenAddress} />
    </Card.Body>
  </Card>
);

export default TokenDistribution;
