import React from "react";
import { Spinner } from "react-bootstrap";

interface LoadingProps {
  value: boolean;
}

export const Loading: React.FC<LoadingProps> = ({ value, children }) =>
  value ? <Spinner animation="border"></Spinner> : <>{children}</>;

export default Loading;
