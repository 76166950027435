import { Action } from "redux";
import { EthereumProvider } from "../../../@types/global";

export const SHOW_ALERT = "SHOW_ALERT";
export interface ShowAlertAction extends Action<"SHOW_ALERT"> {
  title: string;
  message: string;
  level?: string;
}

export const DISMISS_ALERT = "DISMISS_ALERT";
export interface DismissAlertAction extends Action<"DISMISS_ALERT"> {
  id: string;
}

export const DEPLOY_LIBRARY = "DEPLOY_LIBRARY";
export interface DeployLibraryAction extends Action<"DEPLOY_LIBRARY"> {
  name: string;
  navigate: (path: string) => void;
}

export const LIBRARY_DEPLOYED = "LIBRARY_DEPLOYED";
export interface LibraryDeployedAction extends Action<"LIBRARY_DEPLOYED"> {
  name: string;
  address: string;
}

export const DEPLOY_DIRECTORY = "DEPLOY_DIRECTORY";
export interface DeployDirectoryAction extends Action<"DEPLOY_DIRECTORY"> {
  navigate: (path: string) => void;
}

export const METAMASK_DETECTION_REQUESTED = "METAMASK_DETECTION_REQUESTED";

export const METAMASK_DETECTED = "METAMASK_DETECTED";
export interface MetaMaskDetectedAction extends Action<"METAMASK_DETECTED"> {
  metamask: EthereumProvider;
}

export const QUERY_API_VERSION = "QUERY_API_VERSION";
export const SET_API_VERSION = "SET_API_VERSION";
export interface SetApiVersionAction extends Action<"SET_API_VERSION"> {
  version: string;
}

export const LIST_ETH_ACCOUNTS = "LIST_ETH_ACCOUNTS";

export const ETH_ACCOUNTS_LISTED = "ETH_ACCOUNTS_LISTED";
export interface EthAccountsListedAction extends Action<"ETH_ACCOUNTS_LISTED"> {
  accounts: string[];
}

export const SET_NETWORK = "SET_NETWORK";
export interface SetNetworkAction extends Action<"SET_NETWORK"> {
  network: string;
}

export const BOOTSTRAP_ERROR = "BOOTSTRAP_ERROR";
export interface BootstrapErrorAction extends Action<"BOOTSTRAP_ERROR"> {
  error: string;
  chainId?: number;
}

export const CLEAR_BOOTSTRAP_ERROR = "CLEAR_BOOTSTRAP_ERROR";

export const SUBSCRIBE_ACCOUNT_CHANGE = "SUBSCRIBE_ACCOUNT_CHANGE";
export const ACCOUNT_CHANGE_SUBSCRIBED = "ACCOUNT_CHANGE_SUBSCRIBED";
export interface AccountChangeSubscribedAction
  extends Action<"ACCOUNT_CHANGE_SUBSCRIBED"> {
  accountSubscription: any;
  chainChangeSubscription: any;
  metamaskMessageSubscription: any;
}

export const CHANGE_DISPLAYED_PROJECT = "CHANGE_DISPLAYED_PROJECT";
export interface ChangeDisplayedProject
  extends Action<"CHANGE_DISPLAYED_PROJECT"> {
  name?: string;
}

export const TOGGLE_ADVANCED_MODE = "TOGGLE_ADVANCED_MODE";
