import { ethers } from "ethers";
import { EthereumProvider } from "../../../@types/global";
import { AppState, BootstrapState } from "../../types";

export const bootstrapSelector = (state: AppState): BootstrapState =>
  state.bootstrap;

export const metamaskSelector = (
  state: AppState
): EthereumProvider | undefined => state.bootstrap.metamask;

export const providerSelector = (
  state: AppState
): ethers.providers.Web3Provider | undefined => state.bootstrap.provider;

export const userAddressSelector = (state: AppState): string =>
  state.bootstrap.account;

export const selectedProjectSelector = (state: AppState): string | undefined =>
  state.bootstrap.selectedProjectName;

export const networkSelector = (state: AppState): string =>
  state.bootstrap.network;

export const advancedModeSelector = (state: AppState): boolean =>
  state.bootstrap.advancedMode;
