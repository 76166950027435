import React, { useMemo, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  bootstrapSelector,
  contractsListSelector,
  projectNamesMapSelector,
  providerSelector,
  selectedProjectSelector,
  userAddressSelector,
} from "../../store/selectors";
import { AppState, ChargedToken, Delegable } from "../../types";
import { EMPTY_ADDRESS } from "../../utils";
import {
  CONTRACTS_COMMIT,
  DAPP_COMMIT,
  DAPP_VERSION,
} from "../../utils/environment";
import { FinalizeRoundsForm } from "../admin";
import { CopyButton } from "../common";
import LoadingScreen from "../common/LoadingScreen";
import LiquidTokenCard from "./LiquidTokenCard";

interface ListLTContractsProps {
  contractsLoading: boolean;
  account: string;
  selectedProject?: string;
  contracts: string[];
  projectNames: Record<string, string>;
  chargedTokens: Record<string, ChargedToken>;
  delegableToLT: Record<string, Delegable>;
  owners: Record<string, string>;
  error?: string;
}

const ListLTContracts = (props: ListLTContractsProps) => {
  const {
    account,
    selectedProject,
    contracts,
    projectNames,
    chargedTokens,
    delegableToLT,
    owners,
    contractsLoading,
    error,
  } = props;

  if (contractsLoading)
    return <LoadingScreen msg="Loading contracts list..." />;

  if (error !== undefined) {
    return (
      <div
        className="d-flex flex-column justify-content-center"
        style={{ minHeight: "25em" }}
      >
        {typeof error === "string" ? <h3>{error}</h3> : error}
      </div>
    );
  }

  if (contracts.length === 0) {
    return (
      <div
        className="d-flex flex-column justify-content-center"
        style={{ minHeight: "25em" }}
      >
        <h3>The directory is empty, please whitelist a project.</h3>
      </div>
    );
  }

  if (contracts.length === 0) {
    return (
      <div
        className="d-flex flex-column justify-content-center"
        style={{ minHeight: "25em" }}
      >
        <h3>There is no Charged Token for the moment.</h3>
      </div>
    );
  }

  const [showFinalizePopup, setShowFinalizePopup] = useState(false);

  // List of unique project names
  const projectsList = useMemo(() => {
    const projectsOrdered: string[] = [];
    const uniqueNames = [...new Set(Object.values(projectNames))];
    uniqueNames
      .filter(
        (projectName) =>
          selectedProject === undefined || projectName === selectedProject
      )
      .map((projectName) =>
        contracts
          .filter((contract) => projectNames[contract] === projectName)
          .forEach((contract) => projectsOrdered.push(contract))
      );
    return projectsOrdered;
  }, [selectedProject, projectNames, contracts]);

  // Map of CT address to project name
  const projectTokens: Record<string, string> = useMemo(() => {
    const dict: Record<string, string> = {};

    Object.entries(delegableToLT).forEach(([projectToken, pt]) => {
      if (
        dict[pt.name] === undefined &&
        !pt.isListOfInterfaceProjectTokenComplete
      ) {
        dict[pt.name] = projectToken;
      }
    });

    return dict;
  }, [delegableToLT]);

  // Map to know if a project is ready to be finalized (no CT without interface)
  const canFinalizeProject: Record<string, boolean> = useMemo(() => {
    const dict: Record<string, boolean> = {};

    Object.entries(projectNames).forEach(([contract, project]) => {
      if (dict[project] === undefined) {
        if (chargedTokens[contract].interfaceProjectToken !== EMPTY_ADDRESS) {
          dict[project] = true;
        } else {
          dict[project] = false;
        }
      } else if (
        dict[project] === true &&
        chargedTokens[contract].interfaceProjectToken === EMPTY_ADDRESS
      ) {
        dict[project] = false;
      }
    });

    return dict;
  }, [projectNames]);

  let prevProject: string | undefined;

  return (
    <Row className="mt-4">
      {projectsList.map((contract) => {
        const projectName = projectNames[contract];
        const projectToken = projectTokens[projectName];
        const projectSymbol =
          projectToken !== undefined
            ? delegableToLT[projectToken].symbol
            : undefined;
        const projectOwner = owners[projectName];
        const projectChanged = prevProject !== projectName;
        prevProject = projectNames[contract];

        return (
          <React.Fragment key={contract}>
            {projectChanged && (
              <>
                <Col as="h1" xs="12" className="mb-4">
                  <b>{projectName}</b>
                </Col>
                {projectToken !== undefined &&
                  projectSymbol !== undefined &&
                  canFinalizeProject[projectName] &&
                  account === projectOwner && (
                    <>
                      <Col xs="12">
                        <Button
                          type="button"
                          size="sm"
                          variant="danger"
                          className="mb-4"
                          onClick={() => setShowFinalizePopup(true)}
                        >
                          Lock current list of Rounds and Interfaces on token{" "}
                          {projectSymbol}
                        </Button>
                      </Col>

                      <FinalizeRoundsForm
                        projectToken={projectToken}
                        projectSymbol={projectSymbol}
                        show={showFinalizePopup}
                        handleClose={() => setShowFinalizePopup(false)}
                      />
                    </>
                  )}
              </>
            )}
            <Col
              xs="12"
              sm="12"
              md={{ span: 8, offset: 2 }}
              lg={{ span: 6, offset: 0 }}
              xl="4"
            >
              <LiquidTokenCard tokenAddress={contract} />
            </Col>
          </React.Fragment>
        );
      })}
    </Row>
  );
};

interface ListLTContractsWrapperProps extends ListLTContractsProps {
  account: string;
  directory: string;
  directoryOwner: string;
  apiVersion: string;
}

const ListLTContractsWrapper = (props: ListLTContractsWrapperProps) => {
  const navigate = useNavigate();

  const { directory, directoryOwner, account, apiVersion } = props;

  if (account === directoryOwner) {
    return (
      <Container fluid className="ps-5 pe-5 pb-5">
        <Row className="gy-4 text-center">
          <Col xs="12" as={ListLTContracts} {...props} />

          <Col xs="12" as="h4" className="text-center">
            Directory address : {directory}{" "}
            <CopyButton msg="Address copied to clipboard" value={directory} />
            <Button
              size="sm"
              variant="warning"
              onClick={() => navigate("/admin/directory")}
              className="ms-3"
            >
              Manage
            </Button>
          </Col>
          <Col xs="12" as="p" className="text-center">
            Contracts commit : {CONTRACTS_COMMIT}
            <br />
            DApp commit : {DAPP_COMMIT}
            <br />
            DApp version : {DAPP_VERSION}
            <br />
            API version: {apiVersion}
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container fluid className="ps-5 pe-5 pb-5">
      <Row className="gy-4 text-center">
        <Col xs="12" as={ListLTContracts} {...props} />
      </Row>
    </Container>
  );
};

function mapStateToProps(state: AppState) {
  const { apiVersion } = bootstrapSelector(state);

  const owners: Record<string, string> = {};

  if (state.directory !== undefined) {
    for (let i = 0; i < state.directory.projects.length; i++) {
      owners[state.directory.projects[i]] =
        state.directory.whitelistedProjectOwners[i];
    }
  }

  return {
    provider: providerSelector(state),
    contractsLoading: state.directory === undefined,
    contracts: contractsListSelector(state),
    projectNames: projectNamesMapSelector(state),
    chargedTokens: state.chargedTokens,
    delegableToLT: state.delegableToLTs,
    owners,
    directory:
      state.directory !== undefined ? state.directory.address : EMPTY_ADDRESS,
    directoryOwner:
      state.directory !== undefined ? state.directory.owner : EMPTY_ADDRESS,
    account: userAddressSelector(state),
    selectedProject: selectedProjectSelector(state),
    apiVersion,
  };
}

export default connect(mapStateToProps)(ListLTContractsWrapper);
