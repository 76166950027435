import React from "react";
import { Spinner } from "react-bootstrap";
import FlexScreen from "./FlexScreen";

interface LoadingScreenProps {
  msg: string;
}

export const LoadingScreen = ({ msg }: LoadingScreenProps) => (
  <FlexScreen>
    <h3 className="text-center">{msg}</h3>
    <Spinner animation="border">
      <span className="visually-hidden">{msg}</span>
    </Spinner>
  </FlexScreen>
);

export default LoadingScreen;
