import { TransactionState } from "../../types";
import {
  CLEAR_TRANSACTION,
  SET_TRANSACTION,
  SetTransactionAction,
} from "../actions/transaction";

export const initialState: TransactionState | null = null;

function setTransaction(
  prevState: TransactionState | null,
  action: SetTransactionAction
) {
  const { type, ...rest } = action;

  let msgStack: string[] = [];

  if (prevState !== null) {
    if (rest.msg !== undefined) {
      msgStack = [prevState.msg, ...prevState.msgStack];
    } else {
      msgStack = prevState.msgStack;
    }
  }

  return {
    index: 0,
    total: 0,
    ...prevState,
    ...rest,
    msgStack,
  } as TransactionState;
}

export function transactionReducer(
  prevState: TransactionState | null | undefined,
  action: any
): TransactionState | null {
  if (prevState === undefined) return null;

  switch (action.type) {
    case SET_TRANSACTION:
      return setTransaction(prevState, action);

    case CLEAR_TRANSACTION:
      return null;

    default:
      return prevState;
  }
}
