import { BigNumber } from "ethers";
import { useEffect, useMemo, useRef, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import {
  UPDATE_CLAIMS,
  UPDATE_CURRENT_REWARDS,
} from "../../../../store/actions";
import {
  createBalanceSelector,
  createCTSelector,
  createClaimsSelector,
  createDelegableSelector,
  createInterfaceSelector,
  createStakingSelector,
} from "../../../../store/selectors";
import { AppState, DateWrapper } from "../../../../types";
import { EMPTY_ADDRESS, formatAmount } from "../../../../utils";
import { ClaimSummary } from "../../tx-summary";

interface ClaimsSummaryProps {
  tokenAddress: string;
  decimals: number;
  disabled: boolean;
  balancePT: BigNumber;
  stakedLT: BigNumber;
  fullyChargedBalance: BigNumber;
  partiallyChargedBalance: BigNumber;
  valueProjectTokenToFullRecharge: BigNumber;
  claimFeesPerThousandForPT: number;
  claimableProjectToken: BigNumber;
  chargedClaimableProjectToken: BigNumber;
  projectSymbol: string;
  stakingStart: DateWrapper;
  stakingEnd: DateWrapper;
  vestingEnd: DateWrapper;
  currentRewards: BigNumber;
  isPreTGE: boolean;
  updateCurrentRewards: (address: string) => void;
  updateClaims: (address: string) => void;
}

const ClaimsSummary = ({
  tokenAddress,
  decimals,
  disabled,
  balancePT,
  stakedLT,
  fullyChargedBalance,
  partiallyChargedBalance,
  valueProjectTokenToFullRecharge,
  claimFeesPerThousandForPT,
  claimableProjectToken,
  chargedClaimableProjectToken,
  projectSymbol,
  stakingStart,
  stakingEnd,
  vestingEnd,
  currentRewards,
  isPreTGE,
  updateCurrentRewards,
  updateClaims,
}: ClaimsSummaryProps) => {
  const [finalComputationDone, setFinalComputationDone] = useState(false);
  const timerIdRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    // initial computation
    updateCurrentRewards(tokenAddress);
    updateClaims(tokenAddress);

    const timerId = setInterval(() => {
      // regular updates
      if (!stakedLT.isZero()) {
        updateCurrentRewards(tokenAddress);
      }
      if (!isPreTGE && vestingEnd.isFuture()) {
        updateClaims(tokenAddress);
      } else if (
        vestingEnd.isSet &&
        vestingEnd.isPast() &&
        !finalComputationDone
      ) {
        updateClaims(tokenAddress);
        setFinalComputationDone(true);
      }
    }, 10000);
    timerIdRef.current = timerId;

    return () => {
      if (timerIdRef.current !== undefined) {
        clearInterval(timerIdRef.current);
      }
    };
  }, [stakingStart, stakingEnd, isPreTGE, stakedLT]);

  const [showSummary, setShowSummary] = useState(false);

  const unlockedPTBN = useMemo(() => {
    if (vestingEnd.isSet && vestingEnd.isPast()) {
      return fullyChargedBalance
        .add(partiallyChargedBalance)
        .sub(valueProjectTokenToFullRecharge);
    }

    if (
      chargedClaimableProjectToken !== undefined &&
      claimableProjectToken !== undefined &&
      decimals !== undefined
    ) {
      return chargedClaimableProjectToken.add(claimableProjectToken);
    }
    return BigNumber.from(0);
  }, [
    fullyChargedBalance,
    partiallyChargedBalance,
    valueProjectTokenToFullRecharge,
    chargedClaimableProjectToken,
    claimableProjectToken,
  ]);

  const totalClaimed = useMemo(() => {
    if (balancePT !== undefined && decimals !== undefined) {
      return formatAmount(balancePT, decimals, 2).toString();
    }
    return "0";
  }, [balancePT]);

  const claimFees = useMemo(() => {
    if (!stakedLT.eq(unlockedPTBN)) {
      return unlockedPTBN
        .mul(BigNumber.from(claimFeesPerThousandForPT))
        .div(BigNumber.from(1000));
    }
    return BigNumber.from(0);
  }, [stakedLT, unlockedPTBN, claimFeesPerThousandForPT]);

  const totalClaimable = useMemo(() => {
    return isPreTGE
      ? "0"
      : formatAmount(
          unlockedPTBN.add(currentRewards).sub(claimFees),
          decimals,
          2
        ).toString();
  }, [isPreTGE, unlockedPTBN, currentRewards, claimFees]);

  return (
    <Card>
      <Card.Body>
        <Row className="align-items-center">
          <Col as="h5" className="text-center ms-2">
            <p>
              <b>Total claimable</b>
            </p>
            <>
              {totalClaimable} {projectSymbol}
            </>
          </Col>

          <Col
            as={Button}
            className="ms-5 me-2"
            size="lg"
            disabled={disabled || isPreTGE}
            onClick={() => setShowSummary(true)}
          >
            <i className="bi bi-layer-backward h1 mb-2"></i>
            <br />
            Claim
          </Col>

          <Col xs={12} as="h5" className="text-center mt-4">
            Wallet :{" "}
            <>
              {totalClaimed} {projectSymbol}
            </>
          </Col>
        </Row>
        <ClaimSummary
          tokenAddress={tokenAddress}
          unlockedPT={unlockedPTBN}
          rewardsAmount={currentRewards}
          stakedLT={stakedLT}
          claimFeesPerThousandForPT={claimFeesPerThousandForPT}
          handleClose={() => setShowSummary(false)}
          decimals={decimals}
          projectSymbol={projectSymbol}
          show={showSummary}
        />
      </Card.Body>
    </Card>
  );
};

function mapStateToProps(
  state: AppState,
  ownProps: Pick<ClaimsSummaryProps, "tokenAddress">
) {
  const ct = createCTSelector(ownProps.tokenAddress)(state);
  const staking = createStakingSelector(ownProps.tokenAddress)(state);
  const iface = createInterfaceSelector(ct.interfaceProjectToken)(state);

  let disabled = ct.areUserFunctionsDisabled;

  let delegable;
  if (iface !== undefined && iface.projectToken !== EMPTY_ADDRESS) {
    delegable = createDelegableSelector(iface.projectToken)(state);
    if (
      delegable !== undefined &&
      !delegable.validatedInterfaceProjectToken.includes(iface.address)
    ) {
      disabled = true;
    }
  }
  const balances = createBalanceSelector(ownProps.tokenAddress)(state);
  const claims = createClaimsSelector(ownProps.tokenAddress)(state);

  return {
    ...ownProps,
    decimals: ct.decimals,
    disabled,
    balancePT: balances !== undefined ? balances.balancePT : BigNumber.from(0),
    fullyChargedBalance:
      balances !== undefined ? balances.fullyChargedBalance : BigNumber.from(0),
    partiallyChargedBalance:
      balances !== undefined
        ? balances.partiallyChargedBalance
        : BigNumber.from(0),
    valueProjectTokenToFullRecharge:
      balances !== undefined
        ? balances.valueProjectTokenToFullRecharge
        : BigNumber.from(0),
    stakedLT: ct.stakedLT,
    claimFeesPerThousandForPT:
      iface !== undefined ? iface.claimFeesPerThousandForPT : 0,
    claimableProjectToken:
      claims !== undefined ? claims.claimableProjectToken : BigNumber.from(0),
    chargedClaimableProjectToken:
      claims !== undefined
        ? claims.chargedClaimableProjectToken
        : BigNumber.from(0),
    projectSymbol: delegable !== undefined ? delegable.symbol : "",
    stakingStart: ct.stakingStartDate,
    stakingEnd: ct.stakingEndDate,
    vestingEnd:
      iface !== undefined
        ? iface.vestingEnd
        : DateWrapper.fromBlockchainTimestamp(0),
    currentRewards: staking.currentRewards,
    isPreTGE:
      iface === undefined ||
      iface.dateLaunch.isSet !== true ||
      iface.dateLaunch.isFuture(),
  };
}

function mapDispatchToProps(dispatch: (action: any) => void) {
  return {
    updateCurrentRewards: (address: string) =>
      dispatch({ type: UPDATE_CURRENT_REWARDS, address }),
    updateClaims: (address: string) =>
      dispatch({ type: UPDATE_CLAIMS, address }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimsSummary);
