import { BigNumber } from "ethers";
import {
  AppState,
  ChargedToken,
  DateWrapper,
  Delegable,
  Interface,
  StakingEntry,
  UserBalancesEntry,
  UserClaimsEntry,
} from "../../types";

export * from "./bootstrap";
export * from "./transaction";

export const appStateSelector = (state: AppState): AppState => state;

export const directorySelector = (state: AppState) => state.directory;

export const contractsListSelector = (state: AppState): string[] =>
  Object.keys(state.chargedTokens);

export const projectNamesMapSelector = (
  state: AppState
): Record<string, string> =>
  Object.assign(
    {},
    ...Object.entries(state.chargedTokens).map(([address, ct]) => ({
      [address]: ct.projectName,
    }))
  );

export function createBalanceSelector(address: string) {
  return (state: AppState): UserBalancesEntry => {
    return (
      state.balances[address] ?? {
        chainId: state.bootstrap.chainId!,
        user: state.bootstrap.account,
        address,
        balance: BigNumber.from(0),
        balancePT: BigNumber.from(0),
        fullyChargedBalance: BigNumber.from(0),
        partiallyChargedBalance: BigNumber.from(0),
        dateOfPartiallyCharged: new DateWrapper(),
        claimedRewardPerShare1e18: BigNumber.from(0),
        valueProjectTokenToFullRecharge: BigNumber.from(0),
      }
    );
  };
}

export function createClaimsSelector(address: string) {
  return (state: AppState): UserClaimsEntry => {
    return (
      state.claims[address] ?? {
        chargedClaimableProjectToken: BigNumber.from(0),
        claimableProjectToken: BigNumber.from(0),
        ptNeededToRecharge: BigNumber.from(0),
      }
    );
  };
}

export function createStakingSelector(address: string) {
  return (state: AppState): StakingEntry =>
    state.staking[address] ?? { currentRewards: BigNumber.from(0) };
}

export function createCTSelector(address: string) {
  return (state: AppState): ChargedToken => state.chargedTokens[address];
}

export function createInterfaceSelector(address: string) {
  return (state: AppState): Interface | undefined => state.interfaces[address];
}

export function createDelegableSelector(address: string) {
  return (state: AppState): Delegable | undefined =>
    state.delegableToLTs[address];
}
