import { FixedNumber } from "ethers";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import {
  createCTSelector,
  createInterfaceSelector,
} from "../../../store/selectors";
import { AppState, DateWrapper } from "../../../types";
import { EMPTY_ADDRESS } from "../../../utils";

interface LiquidTokenScheduleViewProps {
  valid: boolean;
  tokenAddress: string;
  fractionInitialUnlockPerThousand: number;
  unlockStart: DateWrapper;
  linearVestingStart: DateWrapper;
  vestingEnd: DateWrapper;
  cliffDuration: DateWrapper;
  vestingDuration: DateWrapper;
}

function getDurationStringsState(
  cliffDuration: DateWrapper,
  vestingDuration: DateWrapper
) {
  const fullVestingDuration =
    cliffDuration.blockchainTimestamp + vestingDuration.blockchainTimestamp;
  return {
    cliffDurationString:
      cliffDuration.isSet !== true
        ? "TGE"
        : `TGE + ${cliffDuration.toDurationString()}`,
    vestingDurationString:
      fullVestingDuration === 0
        ? "TGE"
        : `TGE + ${DateWrapper.fromBlockchainTimestamp(
            fullVestingDuration
          ).toDurationString()}`,
  };
}

export const LiquidTokenScheduleView = ({
  valid,
  fractionInitialUnlockPerThousand,
  unlockStart,
  linearVestingStart,
  vestingEnd,
  cliffDuration,
  vestingDuration,
}: LiquidTokenScheduleViewProps) => {
  // TODO : check auto schedule refresh // probably not needed anymore

  const [{ cliffDurationString, vestingDurationString }, setDurations] =
    useState(getDurationStringsState(cliffDuration, vestingDuration));

  useEffect(() => {
    setDurations(getDurationStringsState(cliffDuration, vestingDuration));
  }, [cliffDuration, vestingDuration]);

  const percentUnlocked = FixedNumber.from(fractionInitialUnlockPerThousand)
    .divUnsafe(FixedNumber.from(10))
    .toUnsafeFloat()
    .toFixed(2);

  return (
    <Row>
      <Col xs={6} className="text-end">
        <p>
          <b>Launch (TGE) :</b>
        </p>

        <p>
          <b>Tokens unlocked at TGE :</b>
        </p>

        <p>
          <b>Linear vesting start : </b>
        </p>

        <p>
          <b>Vesting end :</b>
        </p>
      </Col>

      <Col xs={6} className="text-start">
        <p>
          {valid && unlockStart.isSet ? (
            <span>{unlockStart.toString()}</span>
          ) : (
            "N/A"
          )}
        </p>

        <p>
          <b>{percentUnlocked} %</b>
        </p>

        <p>
          {valid ? (
            <span>{linearVestingStart.toString()}</span>
          ) : (
            cliffDurationString
          )}
        </p>

        <p>
          {valid ? <span>{vestingEnd.toString()}</span> : vestingDurationString}
        </p>
      </Col>
    </Row>
  );
};

function mapStateToProps(
  state: AppState,
  ownProps: Pick<LiquidTokenScheduleViewProps, "tokenAddress">
) {
  const ct = createCTSelector(ownProps.tokenAddress)(state);
  const iface = createInterfaceSelector(ct.interfaceProjectToken)(state);

  const valid =
    ct.interfaceProjectToken !== EMPTY_ADDRESS &&
    iface !== undefined &&
    iface.dateLaunch.isSet;

  const schedule =
    iface === undefined
      ? {
          unlockStart: new DateWrapper(),
          linearVestingStart: new DateWrapper(),
          vestingEnd: new DateWrapper(),
        }
      : {
          unlockStart: iface.dateLaunch,
          linearVestingStart: iface.dateEndCliff,
          vestingEnd: iface.vestingEnd,
        };

  return {
    ...ownProps,
    valid,
    fractionInitialUnlockPerThousand: ct.fractionInitialUnlockPerThousand,
    ...schedule,
    cliffDuration: ct.durationCliff,
    vestingDuration: ct.durationLinearVesting,
  };
}

export default connect(mapStateToProps)(LiquidTokenScheduleView);
