const ADVANCED_MODE_STORAGE_KEY = "advancedMode";

export function getCurrentAdvancedMode(): boolean {
  const storedAdvancedMode = localStorage.getItem(ADVANCED_MODE_STORAGE_KEY);
  if (storedAdvancedMode === "true") {
    return true;
  }
  saveCurrentAdvancedMode(false);
  return false;
}

export function saveCurrentAdvancedMode(enabled: boolean): void {
  localStorage.setItem(ADVANCED_MODE_STORAGE_KEY, enabled.toString());
}
