import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import {
  advancedModeSelector,
  createCTSelector,
} from "../../../store/selectors";
import { AppState } from "../../../types";
import { EMPTY_ADDRESS } from "../../../utils";
import {
  ClaimsSummary,
  FullyChargedLTSummary,
  PartiallyChargedLTSummary,
} from "./includes";

export interface BalancesTokenMgmtProps {
  tokenAddress: string;
  hasInterface: boolean;
  advancedMode: boolean;
}

export const BalancesTokenMgmt = ({
  tokenAddress,
  hasInterface,
  advancedMode,
}: BalancesTokenMgmtProps) => {
  if (!advancedMode) {
    return (
      <Row className="gy-4">
        <Col xs="12" md={{ span: 6, offset: 3 }}>
          <ClaimsSummary tokenAddress={tokenAddress} />
        </Col>
      </Row>
    );
  }

  if (!hasInterface) {
    return (
      <Row className="gy-4">
        <Col xs="12">
          <FullyChargedLTSummary tokenAddress={tokenAddress} />
        </Col>
        <Col xs="12" md={{ span: 6, offset: 3 }}>
          <ClaimsSummary tokenAddress={tokenAddress} />
        </Col>
      </Row>
    );
  }

  return (
    <Row className="gy-4">
      <Col xs="12" md="6">
        <FullyChargedLTSummary tokenAddress={tokenAddress} />
      </Col>
      <Col xs="12" md="6">
        <PartiallyChargedLTSummary tokenAddress={tokenAddress} />
      </Col>
      <Col xs="12" md={{ span: 6, offset: 3 }}>
        <ClaimsSummary tokenAddress={tokenAddress} />
      </Col>
    </Row>
  );
};

function mapStateToProps(
  state: AppState,
  ownProps: Pick<BalancesTokenMgmtProps, "tokenAddress">
) {
  const ct = createCTSelector(ownProps.tokenAddress)(state);

  return {
    ...ownProps,
    hasInterface: ct.interfaceProjectToken !== EMPTY_ADDRESS,
    advancedMode: advancedModeSelector(state),
  };
}

export default connect(mapStateToProps)(BalancesTokenMgmt);
