import { BigNumber, ethers } from "ethers";
import { useEffect, useState } from "react";
import { Button, Card, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ERC20 } from "../../../contracts";
import {
  SHOW_ALERT,
  TERMINATE_ALLOCATIONS,
  TOGGLE_FUNDRAISING,
} from "../../../store/actions";
import {
  createCTSelector,
  createInterfaceSelector,
  providerSelector,
  userAddressSelector,
} from "../../../store/selectors";
import { AppState, DateWrapper } from "../../../types";
import { EMPTY_ADDRESS, getContractError } from "../../../utils";
import { AllocateLTForm, SetFundraisingForm } from "../../admin";
import { Invest } from "./includes";

interface AllocationsAndFundraisingProps {
  owner: string;
  account: string;
  provider: ethers.providers.JsonRpcProvider;
  tokenAddress: string;
  symbol: string;
  decimals: number;
  dateLaunch: DateWrapper;
  maxInitialAllocation: BigNumber;
  totalStakingRewards: BigNumber;
  totalAllocated: BigNumber;
  areAllocationsTerminated: boolean;
  isFundraisingContract: boolean;
  fundraisingTokenSymbol: string;
  priceTokenPer1e18: BigNumber;
  fundraisingToken: string;
  isFundraisingActive: boolean;

  terminateAllocations: (
    tokenAddress: string,
    navigate: (path: string) => void
  ) => void;
  toggleFundraising: (tokenAddress: string) => void;
  showError: (title: string, err: Error) => void;
}

export const AllocationsAndFundraising = ({
  owner,
  account,
  provider,
  tokenAddress,
  symbol,
  decimals,
  dateLaunch,
  maxInitialAllocation,
  totalStakingRewards,
  totalAllocated,
  areAllocationsTerminated,
  isFundraisingContract,
  fundraisingTokenSymbol,
  priceTokenPer1e18,
  fundraisingToken,
  toggleFundraising,
  isFundraisingActive,
  terminateAllocations,
  showError,
}: AllocationsAndFundraisingProps) => {
  const navigate = useNavigate();

  const [balance, setBalance] = useState("0");
  const [fundraisingDecimals, setFundraisingDecimals] = useState(0);

  const [showAllocationForm, setShowAllocationForm] = useState(false);
  const [showFundraisingConditionsForm, setShowFundraisingConditionsForm] =
    useState(false);

  useEffect(() => {
    if (isFundraisingContract && fundraisingToken !== EMPTY_ADDRESS) {
      const FT = new ethers.Contract(fundraisingToken, ERC20.abi, provider);
      FT.decimals()
        .then((bnDecimals: number) => {
          setFundraisingDecimals(bnDecimals);
        })
        .catch((err: Error) => {
          showError("Error reading token decimals", err);
        });
    }
  }, [isFundraisingContract, fundraisingToken, account]);

  useEffect(() => {
    if (isFundraisingContract && fundraisingToken !== EMPTY_ADDRESS) {
      const FT = new ethers.Contract(fundraisingToken, ERC20.abi, provider);
      FT.balanceOf(account)
        .then((bnBalance: BigNumber) => setBalance(bnBalance.toString()))
        .catch((err: Error) => {
          showError("Error reading user balance", err);
        });
    }
  }, [isFundraisingContract, fundraisingToken, account]);

  return (
    <Card>
      <Card.Header as="h5" className="text-center">
        Allocations &amp; Fundraising
      </Card.Header>

      <Card.Body>
        {fundraisingToken !== EMPTY_ADDRESS && (
          <Invest
            balance={balance}
            max={maxInitialAllocation
              .add(totalStakingRewards)
              .sub(totalAllocated)
              .toString()}
            tokenAddress={tokenAddress}
            symbol={symbol}
            decimals={decimals}
            disabled={!isFundraisingActive}
            fundraisingSymbol={fundraisingTokenSymbol}
            fundraisingDecimals={fundraisingDecimals}
            priceTokenPer1e18={priceTokenPer1e18}
          />
        )}

        {owner === account && (
          <Col className="pt-4 d-grid d-flex gap-3 justify-content-evenly align-items-stretch">
            {isFundraisingContract && (
              <Button
                type="button"
                size="lg"
                className="col-2"
                onClick={() => setShowFundraisingConditionsForm(true)}
                disabled={
                  (dateLaunch.isSet && !dateLaunch.isFuture()) ||
                  areAllocationsTerminated
                }
              >
                <p className="h1 mb-2">
                  <i className="bi bi-coin"></i>{" "}
                  <i className="bi bi-card-list"></i>
                </p>
                Set fundraising conditions
              </Button>
            )}

            {fundraisingToken !== EMPTY_ADDRESS && (
              <Button
                type="button"
                variant={isFundraisingActive ? "danger" : "success"}
                size="lg"
                className="col-2"
                onClick={() => toggleFundraising(tokenAddress)}
                disabled={
                  (dateLaunch.isSet && !dateLaunch.isFuture()) ||
                  areAllocationsTerminated
                }
              >
                <p className="h1 mb-2">
                  <i className="bi bi-coin"></i>{" "}
                  {isFundraisingActive ? (
                    <i className="bi bi-lock"></i>
                  ) : (
                    <i className="bi bi-unlock"></i>
                  )}
                </p>
                {isFundraisingActive ? "Pause" : "Activate"} fundraising
              </Button>
            )}

            <Button
              type="button"
              size="lg"
              className="col-2"
              onClick={() => setShowAllocationForm(true)}
              disabled={
                (dateLaunch.isSet && !dateLaunch.isFuture()) ||
                areAllocationsTerminated
              }
            >
              <p className="h1 mb-2">
                <i className="bi bi-coin"></i> <i className="bi bi-people"></i>
              </p>
              Allocate {symbol}
            </Button>

            <Button
              type="button"
              variant="danger"
              size="lg"
              className="col-2"
              onClick={() => terminateAllocations(tokenAddress, navigate)}
              disabled={
                (dateLaunch.isSet && !dateLaunch.isFuture()) ||
                areAllocationsTerminated
              }
            >
              <p className="h1 mb-2">
                <i className="bi bi-person-lock"></i>
              </p>
              {isFundraisingContract
                ? "Terminate allocations & fundraising"
                : "Terminate allocations"}
            </Button>

            <AllocateLTForm
              show={showAllocationForm}
              handleClose={() => setShowAllocationForm(false)}
              tokenAddress={tokenAddress}
              symbol={symbol}
              decimals={decimals}
              maxAllocationAvailable={maxInitialAllocation
                .add(totalStakingRewards)
                .sub(totalAllocated)}
            />

            {isFundraisingContract && (
              <SetFundraisingForm
                show={showFundraisingConditionsForm}
                handleClose={() => setShowFundraisingConditionsForm(false)}
                tokenAddress={tokenAddress}
              />
            )}
          </Col>
        )}
      </Card.Body>
    </Card>
  );
};

function mapStateToProps(
  state: AppState,
  ownProps: Pick<AllocationsAndFundraisingProps, "tokenAddress">
) {
  const ct = createCTSelector(ownProps.tokenAddress)(state);
  const iface = createInterfaceSelector(ct.interfaceProjectToken)(state);
  const account = userAddressSelector(state);
  const provider = providerSelector(state)!;

  return {
    ...ownProps,
    account,
    provider,
    owner: ct.owner,
    symbol: ct.symbol,
    decimals: ct.decimals,
    maxInitialAllocation: ct.maxInitialTokenAllocation,
    totalStakingRewards: ct.totalStakingRewards,
    totalAllocated: ct.totalTokenAllocated,
    areAllocationsTerminated: ct.areAllocationsTerminated,
    dateLaunch: iface !== undefined ? iface.dateLaunch : new DateWrapper(),
    isFundraisingContract: ct.isFundraisingContract,
    fundraisingTokenSymbol: ct.fundraisingTokenSymbol,
    priceTokenPer1e18: ct.priceTokenPer1e18,
    fundraisingToken: ct.fundraisingToken,
    isFundraisingActive: ct.isFundraisingActive,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    terminateAllocations: (
      tokenAddress: string,
      navigate: (path: string) => void
    ) =>
      dispatch({
        type: TERMINATE_ALLOCATIONS,
        tokenAddress,
        navigate,
      }),
    toggleFundraising: (tokenAddress: string) =>
      dispatch({
        type: TOGGLE_FUNDRAISING,
        tokenAddress,
      }),
    showError: (title: string, err: Error) =>
      dispatch({
        type: SHOW_ALERT,
        title,
        message: getContractError(err),
        level: "error",
      }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllocationsAndFundraising);
