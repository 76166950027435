import { BigNumber, ethers } from "ethers";
import createDecorator from "final-form-calculate";
import { useCallback, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { Field, Form } from "react-final-form";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Action } from "redux";
import { ERC20 } from "../../contracts";
import { SET_FUNDRAISING_CONDITIONS } from "../../store/actions";
import { providerSelector } from "../../store/selectors";
import { AppState } from "../../types";
import {
  required,
  requiredAddressValidator,
  requiredPositiveDecimalValidator,
} from "../../utils";
import { NumberInput, TextInput } from "../forms";
import { SetFundraisingConditionsSummary } from "../projects/tx-summary";

interface SetFundraisingFormProps {
  show: boolean;
  tokenAddress: string;
  provider: ethers.providers.JsonRpcProvider;
  handleClose: () => void;
  setFundraisingConditions: (
    values: any,
    navigate: (path: string) => void
  ) => void;
}

const SetFundraisingForm = ({
  show,
  tokenAddress,
  provider,
  handleClose,
  setFundraisingConditions,
}: SetFundraisingFormProps) => {
  const navigate = useNavigate();
  const onSubmit = useCallback(
    ({ tokenPrice, ...rest }: any) => {
      const tokenPrice1e18 = BigNumber.from(
        Math.round(Number(tokenPrice) * Math.pow(10, 18)).toString()
      );

      setFundraisingConditions(
        {
          ...rest,
          tokenAddress,
          tokenPrice1e18,
        },
        navigate
      );
      handleClose();
    },
    [setFundraisingConditions, handleClose]
  );

  const tokenSymbolLoadDecorator = useMemo(
    () =>
      createDecorator({
        field: "fundraisingToken",
        updates: {
          fundraisingTokenSymbol: async (fundraisingToken?: string) => {
            if (fundraisingToken === undefined) return "N/A";
            if (!ethers.utils.isAddress(fundraisingToken)) return "N/A";
            try {
              const erc20 = new ethers.Contract(
                fundraisingToken,
                ERC20.abi,
                provider
              );
              return await erc20.symbol();
            } catch (err) {
              return "N/A";
            }
          },
        },
      }),
    [provider]
  );

  return (
    <Modal show={show} onHide={handleClose} centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Fundraising Conditions</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form
          onSubmit={onSubmit}
          decorators={[tokenSymbolLoadDecorator]}
          initialValues={{ fundraisingTokenSymbol: "N/A" }}
          render={({ handleSubmit, form, submitting, pristine }) => (
            <form
              onSubmit={handleSubmit}
              noValidate
              className="needs-validation d-grid gap-2"
            >
              <Field
                name="fundraisingToken"
                validate={requiredAddressValidator}
              >
                {({ input, meta }) => (
                  <TextInput
                    label="Fundraising Token address"
                    meta={meta}
                    {...input}
                  />
                )}
              </Field>

              <Field name="fundraisingTokenSymbol" validate={required}>
                {({ input }) => <p>Fundraising Token symbol : {input.value}</p>}
              </Field>

              <Field
                name="tokenPrice"
                validate={requiredPositiveDecimalValidator}
              >
                {({ input, meta }) => {
                  return (
                    <NumberInput
                      label="Token price"
                      placeholder="Token price"
                      meta={meta}
                      {...input}
                    />
                  );
                }}
              </Field>

              <button
                type="submit"
                className="btn btn-lg btn-primary mt-3"
                disabled={pristine || submitting || form.getState().invalid}
              >
                Set Fundraising Conditions
              </button>
            </form>
          )}
        ></Form>
      </Modal.Body>
    </Modal>
  );
};

function mapStateToProps(
  state: AppState,
  ownProps: Omit<
    SetFundraisingFormProps,
    "provider" | "setFundraisingConditions"
  >
) {
  const provider = providerSelector(state)!;

  return {
    ...ownProps,
    provider,
  };
}

function mapDispatchToProps(dispatch: (action: Action<any>) => void) {
  return {
    setFundraisingConditions: (
      values: any,
      navigate: (path: string) => void
    ) => {
      dispatch({
        type: SET_FUNDRAISING_CONDITIONS,
        ...values,
        summary: <SetFundraisingConditionsSummary {...values} />,
        navigate,
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SetFundraisingForm);
