import { BigNumber } from "ethers";
import { useCallback } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { Field, Form } from "react-final-form";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Action } from "redux";
import { DEPLOY_LT } from "../../store/actions";
import { DateWrapper } from "../../types";
import {
  required,
  requiredDecimalValidator,
  requiredNullableIntegerValidator,
  requiredSingleDecimalPercentValidator,
} from "../../utils";
import { NumberInput, TextInput } from "../forms";
import { DeployLTSummary } from "../projects/tx-summary";

interface AddLTFormProps {
  show: boolean;
  handleClose: () => void;
  deployLiquidityToken: (values: any, navigate: (path: string) => void) => void;
}

const AddLTForm = ({
  show,
  handleClose,
  deployLiquidityToken,
}: AddLTFormProps) => {
  const navigate = useNavigate();
  const onSubmit = useCallback(
    ({
      initialUnlock,
      durationCliff,
      durationLinearVesting,
      maxWithdrawFees,
      maxClaimFees,
      maxStakingAPR,
      maxStakingTokenAmount,
      maxInitialTokenAllocation,
      ...rest
    }: any) => {
      const fractionInitialUnlockPerThousand = Math.floor(
        Number(initialUnlock) * 10
      ).toString();
      const maxWithdrawFeesPerThousand = Math.floor(
        Number(maxWithdrawFees) * 10
      ).toString();
      const maxClaimFeesPerThousand = Math.floor(
        Number(maxClaimFees) * 10
      ).toString();

      deployLiquidityToken(
        {
          ...rest,
          durationCliff: DateWrapper.days(durationCliff),
          durationLinearVesting: DateWrapper.days(durationLinearVesting),
          fractionInitialUnlockPerThousand,
          maxWithdrawFeesPerThousand,
          maxClaimFeesPerThousand,
          maxStakingAPR,
          maxStakingTokenAmount: BigNumber.from(maxStakingTokenAmount)
            .mul(BigNumber.from(10).pow(18))
            .toString(),
          maxInitialTokenAllocation: BigNumber.from(maxInitialTokenAllocation)
            .mul(BigNumber.from(10).pow(18))
            .toString(),
        },
        navigate
      );
      handleClose();
    },
    [deployLiquidityToken, handleClose]
  );

  return (
    <Modal show={show} onHide={handleClose} centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>New Charged Token</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, form, submitting, pristine }) => (
            <form
              onSubmit={handleSubmit}
              noValidate
              className="needs-validation d-grid gap-2"
            >
              <Row>
                <Col xs={8}>
                  <Field name="name" validate={required}>
                    {({ input, meta }) => (
                      <TextInput label="Token name" meta={meta} {...input} />
                    )}
                  </Field>
                </Col>
                <Col xs={4}>
                  <Field name="symbol" validate={required}>
                    {({ input, meta }) => (
                      <TextInput label="Token symbol" meta={meta} {...input} />
                    )}
                  </Field>
                </Col>
              </Row>

              <Field
                name="maxInitialTokenAllocation"
                validate={requiredNullableIntegerValidator}
              >
                {({ input, meta }) => {
                  return (
                    <NumberInput
                      label="Maximum initial token allocation"
                      placeholder="Maximum initial token allocation"
                      meta={meta}
                      {...input}
                    />
                  );
                }}
              </Field>

              <Field
                name="initialUnlock"
                validate={requiredSingleDecimalPercentValidator}
              >
                {({ input, meta }) => {
                  return (
                    <NumberInput
                      label="Initial unlock (%)"
                      placeholder="Initial unlock"
                      meta={meta}
                      {...input}
                    />
                  );
                }}
              </Field>
              <Row>
                <Col>
                  <Field
                    name="durationCliff"
                    validate={requiredDecimalValidator}
                  >
                    {({ input, meta }) => {
                      return (
                        <NumberInput
                          label="Cliff duration (days)"
                          placeholder="Cliff duration"
                          meta={meta}
                          {...input}
                        />
                      );
                    }}
                  </Field>
                </Col>
                <Col>
                  <Field
                    name="durationLinearVesting"
                    validate={requiredDecimalValidator}
                  >
                    {({ input, meta }) => {
                      return (
                        <NumberInput
                          label="Vesting duration (days)"
                          placeholder="Vesting duration"
                          meta={meta}
                          {...input}
                        />
                      );
                    }}
                  </Field>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    name="maxWithdrawFees"
                    validate={requiredSingleDecimalPercentValidator}
                  >
                    {({ input, meta }) => {
                      return (
                        <NumberInput
                          label="Maximum withdrawal fee (%)"
                          placeholder="Maximum withdrawal fee"
                          meta={meta}
                          {...input}
                        />
                      );
                    }}
                  </Field>
                </Col>
                <Col>
                  <Field
                    name="maxClaimFees"
                    validate={requiredSingleDecimalPercentValidator}
                  >
                    {({ input, meta }) => {
                      return (
                        <NumberInput
                          label="Maximum claiming fee (%)"
                          placeholder="Maximum claiming fee"
                          meta={meta}
                          {...input}
                        />
                      );
                    }}
                  </Field>
                </Col>
              </Row>

              <Field
                name="maxStakingTokenAmount"
                validate={requiredNullableIntegerValidator}
              >
                {({ input, meta }) => {
                  return (
                    <NumberInput
                      label="Maximum token rewards per staking campaign"
                      placeholder="Maximum token rewards per staking campaign"
                      meta={meta}
                      {...input}
                    />
                  );
                }}
              </Field>

              <Field
                name="maxStakingAPR"
                validate={requiredNullableIntegerValidator}
              >
                {({ input, meta }) => {
                  return (
                    <NumberInput
                      label="Maximum staking APR (%)"
                      placeholder="Maximum staking APR"
                      meta={meta}
                      {...input}
                    />
                  );
                }}
              </Field>

              <button
                type="submit"
                className="btn btn-lg btn-primary mt-3"
                disabled={pristine || submitting || form.getState().invalid}
              >
                Deploy
              </button>
            </form>
          )}
        ></Form>
      </Modal.Body>
    </Modal>
  );
};

function mapDispatchToProps(dispatch: (action: Action<any>) => void) {
  return {
    deployLiquidityToken: (values: any, navigate: (path: string) => void) => {
      dispatch({
        type: DEPLOY_LT,
        ...values,
        summary: <DeployLTSummary {...values} />,
        navigate,
      });
    },
  };
}

export default connect(null, mapDispatchToProps)(AddLTForm);
