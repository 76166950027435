import { BigNumber } from "ethers";
import React, { ReactElement, useState } from "react";
import { Button } from "react-bootstrap";
import { Field, Form } from "react-final-form";
import { buildMaxAmountValidator, parseAmount } from "../../../../utils";
import { AmountInput } from "../../../forms";
import { DepositSummary } from "../../tx-summary";

interface DepositProps {
  max: string;
  tokenAddress: string;
  symbol: string;
  decimals: number;
  currentRewards: BigNumber;
  disabled: boolean;
  button?: ReactElement;
}

const Deposit = ({
  tokenAddress,
  symbol,
  decimals,
  disabled,
  max,
  currentRewards,
  button,
}: DepositProps) => {
  const [showSummary, setShowSummary] = useState(false);
  const [amount, setAmount] = useState(BigNumber.from(0));

  return (
    <>
      <Form
        onSubmit={({ amount }) => {
          setAmount(parseAmount(amount, decimals));
          setShowSummary(true);
        }}
        render={({ form, handleSubmit, pristine, submitting }) => (
          <form onSubmit={handleSubmit} noValidate className="needs-validation">
            <Field
              name="amount"
              validate={buildMaxAmountValidator(max, decimals)}
            >
              {({ input, meta }) => {
                return (
                  <AmountInput
                    placeholder="Amount to deposit"
                    symbol={symbol}
                    decimals={decimals}
                    max={max}
                    meta={meta}
                    {...input}
                  >
                    {React.cloneElement(
                      button || (
                        <Button type="submit" variant="primary">
                          Deposit
                        </Button>
                      ),
                      {
                        style: { minWidth: "7rem" },
                        disabled:
                          disabled ||
                          pristine ||
                          submitting ||
                          form.getState().invalid,
                      }
                    )}
                  </AmountInput>
                );
              }}
            </Field>
          </form>
        )}
      />
      <DepositSummary
        tokenAddress={tokenAddress}
        amount={amount}
        currentRewards={currentRewards}
        handleClose={() => setShowSummary(false)}
        decimals={decimals}
        symbol={symbol}
        show={showSummary}
      />
    </>
  );
};

export default Deposit;
