import { BigNumber } from "ethers";
import { Alert, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  advancedModeSelector,
  createCTSelector,
  createDelegableSelector,
  createInterfaceSelector,
  userAddressSelector,
} from "../../store/selectors";
import { AppState, DateWrapper } from "../../types";
import { EMPTY_ADDRESS } from "../../utils";
import { CopyButton } from "../common";
import {
  AllocationsAndFundraising,
  BalancesTokenMgmt,
  FeesAndRewards,
  TokenDistribution,
  Troubleshooting,
  VestingSchedule,
} from "./screens";

interface LiquidTokenScreensProps {
  tokenAddress: string;
  totalTokenAllocated: BigNumber;
  tab: string;
  account: string;
  name: string;
  owner: string;
  symbol: string;
  isFundraisingActive: boolean;
  projectName: string;
  projectToken: string;
  ltDisabled: boolean;
  hasInterface: boolean;
  interfaceDisabled: boolean;
  dateLaunch: DateWrapper;
  areAllocationsTerminated: boolean;
  advancedMode: boolean;
}

export const LiquidTokenScreens = ({
  tokenAddress,
  totalTokenAllocated,
  tab,
  account,
  name,
  owner,
  symbol,
  isFundraisingActive,
  projectName,
  projectToken,
  ltDisabled,
  hasInterface,
  interfaceDisabled,
  dateLaunch,
  areAllocationsTerminated,
  advancedMode,
}: LiquidTokenScreensProps) => {
  const navigate = useNavigate();

  return (
    <>
      <Container className="pb-5">
        <Row>
          <Col xs={6} as="h4">
            <b>{name}</b> ({symbol}){" "}
            <CopyButton
              value={tokenAddress}
              msg="Address copied to clipboard"
            />
          </Col>
          <Col xs={6} className="text-end">
            Project <b>{projectName}</b>{" "}
            {projectToken !== "" && (
              <CopyButton
                value={projectToken}
                msg="Address copied to clipboard"
                disabled={projectToken === EMPTY_ADDRESS}
                title={
                  projectToken === EMPTY_ADDRESS
                    ? "The project token has not been deployed yet."
                    : undefined
                }
              />
            )}
          </Col>

          {account === owner && ltDisabled && (
            <Col xs={12}>
              <Alert variant="warning">
                The LiquidityToken contract has user interactions disabled.
                Check Troubleshooting tab to fix it.
              </Alert>
            </Col>
          )}

          {account === owner && hasInterface && interfaceDisabled && (
            <Col xs={12}>
              <Alert variant="warning">
                The InterfaceProjectToken contract isn't in the ProjectToken's
                whitelist. Check Troubleshooting tab to fix it.
              </Alert>
            </Col>
          )}
        </Row>

        <Tabs
          activeKey={tab}
          onSelect={(selection) =>
            selection !== null && navigate(`/lt/${tokenAddress}/${selection}`)
          }
          variant="pills"
          className="mb-4"
        >
          <Tab
            eventKey="schedule"
            title={advancedMode ? "Vesting schedule" : "Vesting & Claim"}
          >
            {tab === "schedule" && (
              <>
                <VestingSchedule tokenAddress={tokenAddress} />
                <div className="mb-3"></div>
                {!advancedMode && (
                  <BalancesTokenMgmt tokenAddress={tokenAddress} />
                )}
              </>
            )}
          </Tab>

          {(!dateLaunch.isSet || dateLaunch.isFuture()) &&
            !areAllocationsTerminated &&
            (account === owner || isFundraisingActive) && (
              <Tab eventKey="allocations" title={"Allocations & Fundraising"}>
                {tab === "allocations" && (
                  <AllocationsAndFundraising tokenAddress={tokenAddress} />
                )}
              </Tab>
            )}

          {!totalTokenAllocated.isZero() && (
            <Tab eventKey="distribution" title="Token distribution">
              {tab === "distribution" && (
                <TokenDistribution
                  tokenAddress={tokenAddress}
                  symbol={symbol}
                />
              )}
            </Tab>
          )}

          <Tab eventKey="feesAndRewards" title="Fees &amp; rewards">
            {tab === "feesAndRewards" && (
              <FeesAndRewards tokenAddress={tokenAddress} />
            )}
          </Tab>

          {advancedMode && (
            <Tab
              eventKey="balancesAndTokenMgmt"
              title="Balances &amp; Token management"
            >
              {tab === "balancesAndTokenMgmt" && (
                <BalancesTokenMgmt tokenAddress={tokenAddress} />
              )}
            </Tab>
          )}

          {owner === account && (
            <Tab eventKey="troubleshooting" title="Troubleshooting">
              {tab === "troubleshooting" && (
                <Troubleshooting tokenAddress={tokenAddress} />
              )}
            </Tab>
          )}
        </Tabs>
      </Container>
    </>
  );
};

function mapStateToProps(
  state: AppState,
  ownProps: Pick<LiquidTokenScreensProps, "tokenAddress">
) {
  const ct = createCTSelector(ownProps.tokenAddress)(state);
  const iface = createInterfaceSelector(ct.interfaceProjectToken)(state);
  const delegable =
    iface !== undefined
      ? createDelegableSelector(iface.projectToken)(state)
      : undefined;

  return {
    ...ownProps,
    account: userAddressSelector(state),
    totalTokenAllocated: ct.totalTokenAllocated,
    name: ct.name,
    owner: ct.owner,
    symbol: ct.symbol,
    projectName: ct.projectName,
    projectToken: iface !== undefined ? iface.projectToken : EMPTY_ADDRESS,
    isFundraisingActive: ct.isFundraisingActive,
    ltDisabled: ct.areUserFunctionsDisabled,
    hasInterface: ct.interfaceProjectToken !== EMPTY_ADDRESS,
    dateLaunch: iface !== undefined ? iface.dateLaunch : new DateWrapper(),
    areAllocationsTerminated: ct.areAllocationsTerminated,
    interfaceDisabled:
      delegable === undefined
        ? false
        : !delegable.validatedInterfaceProjectToken.includes(
            ct.interfaceProjectToken
          ),
    advancedMode: advancedModeSelector(state),
  };
}

export default connect(mapStateToProps)(LiquidTokenScreens);
