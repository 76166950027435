import { BigNumber, ethers } from "ethers";
import { Card, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import {
  createBalanceSelector,
  createCTSelector,
  createClaimsSelector,
  createDelegableSelector,
  createInterfaceSelector,
  createStakingSelector,
} from "../../../../store/selectors";
import {
  AppState,
  UserBalancesEntry,
  UserClaimsEntry,
} from "../../../../types";
import { EMPTY_ADDRESS, formatAmount } from "../../../../utils";
import Recharge from "./Recharge";

export interface PartiallyChargedLTSummaryProps {
  tokenAddress: string;
  hasInterface: boolean;
  decimals: number;
  symbol: string;
  disabled: boolean;
  balances: UserBalancesEntry;
  claims: UserClaimsEntry;
  projectSymbol: string;
  currentRewards: BigNumber;
  validatedInterface: boolean;
  provider?: ethers.providers.Web3Provider;
}

// TODO cleanup props, pass only what is necessary
// TODO useMemo here for local variables refreshing

const PartiallyChargedLTSummary = ({
  tokenAddress,
  hasInterface,
  decimals,
  symbol,
  disabled,
  balances,
  claims,
  projectSymbol,
  currentRewards,
  validatedInterface,
}: PartiallyChargedLTSummaryProps) => {
  let partiallyChargedLT = "0";

  if (balances !== undefined) {
    partiallyChargedLT = formatAmount(
      balances.partiallyChargedBalance,
      decimals,
      2
    );
  }

  const walletPT =
    balances !== undefined
      ? formatAmount(balances?.balancePT, decimals, 2)
      : "0";

  let maxRecharge = "0";
  if (balances !== undefined && claims !== undefined) {
    if (balances.balancePT.lt(claims.ptNeededToRecharge)) {
      maxRecharge = balances.balancePT.toString();
    } else {
      maxRecharge = claims.ptNeededToRecharge.toString();
    }
  }

  const pt2lt = (amount: string) => {
    if (claims.ptNeededToRecharge.isZero()) return "0";

    const bnAmount = BigNumber.from(amount);

    const result = bnAmount
      .mul(balances.partiallyChargedBalance)
      .div(claims.ptNeededToRecharge)
      .toString();

    return result;
  };

  return (
    <Card style={{ height: "100%" }}>
      <Card.Header as="h5" className="text-center">
        Partially charged {symbol}
      </Card.Header>

      <Card.Body>
        <Row className="align-items-center">
          <Col xs={12} className="text-center">
            <b>Locked : </b>
            <>
              {partiallyChargedLT} {symbol}
            </>
          </Col>
          <Col xs={12}>
            <hr />
          </Col>
          {hasInterface && (
            <Col xs={12} className="d-grid">
              <Recharge
                loading={false}
                max={maxRecharge}
                ptNeededForFullRecharge={formatAmount(
                  claims.ptNeededToRecharge,
                  decimals,
                  2
                )}
                ptAvailable={walletPT}
                tokenAddress={tokenAddress}
                symbol={symbol}
                projectSymbol={projectSymbol}
                decimals={decimals}
                disabled={disabled}
                currentRewards={currentRewards}
                convert={pt2lt}
                validatedInterface={validatedInterface}
              />
            </Col>
          )}
        </Row>
      </Card.Body>
    </Card>
  );
};

function mapStateToProps(
  state: AppState,
  ownProps: Pick<PartiallyChargedLTSummaryProps, "tokenAddress">
) {
  const ct = createCTSelector(ownProps.tokenAddress)(state);
  const staking = createStakingSelector(ownProps.tokenAddress)(state);
  const iface = createInterfaceSelector(ct.interfaceProjectToken)(state);
  const delegable = createDelegableSelector(
    iface !== undefined ? iface.projectToken : EMPTY_ADDRESS
  )(state);
  const balances = createBalanceSelector(ownProps.tokenAddress)(state);
  const claims = createClaimsSelector(ownProps.tokenAddress)(state);

  return {
    ...ownProps,
    hasInterface: ct.interfaceProjectToken !== EMPTY_ADDRESS,
    decimals: ct.decimals,
    symbol: ct.symbol,
    projectSymbol: delegable !== undefined ? delegable.symbol : "",
    disabled: ct.areUserFunctionsDisabled,
    balances,
    claims,
    currentRewards: staking.currentRewards,
    validatedInterface:
      delegable !== undefined
        ? delegable.validatedInterfaceProjectToken.includes(
            ct.interfaceProjectToken
          )
        : false,
  };
}

export default connect(mapStateToProps)(PartiallyChargedLTSummary);
