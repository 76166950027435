import { useCallback, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Field, Form } from "react-final-form";
import { Validator } from "../../types";
import NumberInput from "./NumberInput";

interface EditableInputProps {
  label: string;
  placeholder: string;
  value: string;
  maxValue?: number;
  allowEdit?: boolean;
  validate?: Validator;
  format?: (value: string) => string;
  onSubmit: (newValue: string) => void;
}

export const EditableInput = ({
  label,
  placeholder,
  value,
  maxValue,
  allowEdit = true,
  validate,
  format,
  onSubmit,
}: EditableInputProps) => {
  const [editMode, setEditMode] = useState(false);

  const realValidate = useCallback(
    (value: string | undefined) => {
      if (validate !== undefined) {
        const stdValidate = validate(value);
        if (stdValidate !== undefined) return stdValidate;
      }

      if (
        maxValue !== undefined &&
        !isNaN(Number(value)) &&
        Number(value) > maxValue
      ) {
        return `Value exceeds maximum of ${maxValue}`;
      }
    },
    [validate]
  );

  const onConfirmSubmit = useCallback(
    ({ newValue }) => {
      onSubmit(newValue);
      setEditMode(false);
    },
    [onSubmit]
  );

  if (!editMode || !allowEdit) {
    return (
      <Row className="mb-3">
        <Col xs={8}>{label}</Col>
        <Col xs={4} className="text-end">
          <b>{format ? format(value) : value}</b>{" "}
          {allowEdit && (
            <Button type="button" size="sm" onClick={() => setEditMode(true)}>
              <i className="bi bi-pencil-square"></i>
            </Button>
          )}
        </Col>
      </Row>
    );
  }

  return (
    <Form
      onSubmit={onConfirmSubmit}
      initialValues={{ newValue: value }}
      render={({ handleSubmit, invalid }) => (
        <form
          onSubmit={handleSubmit}
          noValidate
          className="needs-validation mb-3"
        >
          <Field name="newValue" validate={realValidate}>
            {({ input, meta }) => (
              <NumberInput
                label={label}
                placeholder={placeholder}
                meta={meta}
                {...input}
                autoFocus
              >
                <Button type="submit" size="sm" disabled={invalid}>
                  <i className="bi bi-check-lg"></i> Save
                </Button>
                <Button
                  type="button"
                  size="sm"
                  variant="secondary"
                  onClick={() => {
                    setEditMode(false);
                  }}
                >
                  <i className="bi bi-x-lg"></i> Cancel
                </Button>
              </NumberInput>
            )}
          </Field>
        </form>
      )}
    ></Form>
  );
};

export default EditableInput;
