import { BigNumber } from "ethers";
import { ChargedToken, UserBalancesEntry } from "../../types";
import { EMPTY_ADDRESS } from "./addresses";

export function getUpdatedRewardPerShare1e18(
  ct: ChargedToken,
  blockTime: number
): BigNumber {
  const stakingDateLastCheckpointTs =
    ct.stakingDateLastCheckpoint.blockchainTimestamp;
  const stakingEndDateTs = ct.stakingEndDate.blockchainTimestamp;

  // TODO : handle cases outside of staking period
  if (
    blockTime > stakingDateLastCheckpointTs &&
    stakingDateLastCheckpointTs < stakingEndDateTs &&
    ct.stakedLT.gt(BigNumber.from(0))
  ) {
    return ct.currentRewardPerShare1e18.add(
      BigNumber.from(Math.min(blockTime, stakingEndDateTs))
        .sub(BigNumber.from(stakingDateLastCheckpointTs))
        .mul(ct.campaignStakingRewards)
        .mul(BigNumber.from("1000000000000000000"))
        .div(
          BigNumber.from(ct.stakingDuration.blockchainTimestamp).mul(
            ct.stakedLT
          )
        )
    );
  }

  return ct.currentRewardPerShare1e18;
}

export function getHodlRewards(
  ct: ChargedToken,
  userBalances: UserBalancesEntry,
  currentRewardPerShare1e18: BigNumber
): BigNumber {
  let totalTokenOwned = userBalances.fullyChargedBalance.add(
    userBalances.partiallyChargedBalance
  );

  if (ct.interfaceProjectToken != EMPTY_ADDRESS)
    totalTokenOwned = totalTokenOwned.sub(
      userBalances.valueProjectTokenToFullRecharge
    );

  return totalTokenOwned
    .mul(currentRewardPerShare1e18.sub(userBalances.claimedRewardPerShare1e18))
    .div(BigNumber.from(10).pow(18));
}

export function getValueProjectTokenPerVestingSchedule(
  fractionInitialUnlockPerThousand: number,
  balance: BigNumber,
  timestamp: number,
  dateStart: number,
  addInitialUnlock: boolean,
  durationLinearVesting: number
): BigNumber {
  let valueProjectToken = BigNumber.from(0);
  const bnFractionInitialUnlockPerThousand = BigNumber.from(
    fractionInitialUnlockPerThousand
  );

  if (addInitialUnlock)
    valueProjectToken = balance
      .mul(bnFractionInitialUnlockPerThousand)
      .div(1000);

  if (timestamp > dateStart && durationLinearVesting > 0) {
    valueProjectToken = valueProjectToken.add(
      balance
        .mul(BigNumber.from(1000).sub(bnFractionInitialUnlockPerThousand))
        .mul(BigNumber.from(timestamp - dateStart))
        .div(BigNumber.from(1000).mul(BigNumber.from(durationLinearVesting)))
    );
  }

  return valueProjectToken;
}
