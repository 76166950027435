/* eslint-disable max-lines */
import detectEthereumProvider from "@metamask/detect-provider";
import { ethers } from "ethers";
import { channel } from "redux-saga";
import { call, put, select, take, takeEvery } from "redux-saga/effects";
import { client } from "../../api";
import { GET_VERSION, SUBSCRIBE_USER_BALANCES } from "../../graphql";
import { BootstrapState } from "../../types";
import {
  DIRECTORY_PER_CHAINID,
  LIBRARIES_PER_NETWORK,
  deployContract,
  deployLibraryContract,
  getContractError,
  takeFirst,
} from "../../utils";
import {
  ACCOUNT_CHANGE_SUBSCRIBED,
  BOOTSTRAP_ERROR,
  CLEAR_BOOTSTRAP_ERROR,
  DEPLOY_DIRECTORY,
  DEPLOY_LIBRARY,
  ETH_ACCOUNTS_LISTED,
  INIT_CONTRACTS_STATE,
  LIBRARY_DEPLOYED,
  LIST_ETH_ACCOUNTS,
  METAMASK_DETECTED,
  METAMASK_DETECTION_REQUESTED,
  QUERY_API_VERSION,
  SET_API_VERSION,
  SHOW_ALERT,
  SUBSCRIBE_ACCOUNT_CHANGE,
  SUBSCRIBE_UPDATES,
} from "../actions";
import { bootstrapSelector } from "../selectors";

function* detectMetamask(): any {
  try {
    const metamask = yield call(detectEthereumProvider);

    yield put({ type: QUERY_API_VERSION });
    yield put({ type: METAMASK_DETECTED, metamask });
    yield put({ type: LIST_ETH_ACCOUNTS });
    yield put({ type: INIT_CONTRACTS_STATE });
  } catch (err) {
    yield put({ type: BOOTSTRAP_ERROR, error: "METAMASK NOT FOUND" });
  }
}

function* queryApiVersion(): any {
  const response = yield call([client, client.query], {
    query: GET_VERSION,
  });
  const version = response.data.version;

  if (version === undefined || version === null) {
    console.error("Couldn't retrieve api version !");
    return;
  }

  console.log("Got api version", version);

  yield put({ type: SET_API_VERSION, version });
}

function* listEthAccounts(): any {
  const { provider, contractsLoading }: BootstrapState = yield select(
    bootstrapSelector
  );

  const { chainId } = yield call([provider, provider!.getNetwork]);

  try {
    const accounts = yield call([window.ethereum!, window.ethereum!.request], {
      method: "eth_requestAccounts",
    });
    console.log("listed accounts :", accounts);
    yield put({ type: ETH_ACCOUNTS_LISTED, accounts });
    yield put({ type: SUBSCRIBE_ACCOUNT_CHANGE });

    if (accounts.length > 0) {
      let [account] = accounts;
      account = ethers.utils.getAddress(account);

      if (!contractsLoading) {
        yield put({
          type: SUBSCRIBE_UPDATES,
          query: SUBSCRIBE_USER_BALANCES,
          variables: {
            chainId,
            user: account,
          },
        });
      }
    }
  } catch (err) {
    console.error("Couldn't read accounts !", err, provider);
  }
}

function* subscribeAccountsChange(): any {
  const bootstrap = yield select(bootstrapSelector);
  const { metamask, accounts } = bootstrap;

  if (
    bootstrap.accountSubscription !== undefined ||
    bootstrap.chainChangeSubscription !== undefined ||
    bootstrap.metamaskMessageSubscription !== undefined
  ) {
    console.warn("ACCOUNT CHANGE ALREADY SUBSCRIBED ! SKIPPING");
    return;
  }

  const accountsChannel = channel();
  const accountSubscription = metamask.on(
    "accountsChanged",
    function (newAccounts: Array<string>) {
      if (accounts[0] !== newAccounts[0]) {
        console.log("account changed :", accounts, "=>", newAccounts);
        accountsChannel.put([...newAccounts]);
      }
    }
  );
  const chainChangeSubscription = metamask.on("chainChanged", function () {
    alert("Blockchain change detected, the current page will be reloaded");
    window.location.reload();
  });
  const metamaskMessageSubscription = metamask.on(
    "message",
    function (message: any) {
      console.warn("MetaMask message :", message);
    }
  );

  yield put({
    type: ACCOUNT_CHANGE_SUBSCRIBED,
    accountSubscription,
    chainChangeSubscription,
    metamaskMessageSubscription,
  });

  while (true) {
    const newAccounts = yield take(accountsChannel);
    if (newAccounts !== undefined) {
      alert("User account changed, the current page will be reloaded");
      window.location.reload();
      // yield put({ type: ETH_ACCOUNTS_LISTED, accounts: newAccounts });
    }
  }
}

function* deployLibrary(action: any): any {
  const { provider, network } = yield select(bootstrapSelector);
  const { name } = action;

  try {
    const libraryAddress = yield call(deployLibraryContract, name, provider);
    console.log(name, "deployed at", libraryAddress);

    LIBRARIES_PER_NETWORK[network] = {
      ...LIBRARIES_PER_NETWORK[network],
      [name]: libraryAddress,
    };

    yield put({
      type: LIBRARY_DEPLOYED,
      name,
      address: libraryAddress,
    });
  } catch (err) {
    console.error(err);
    yield put({
      type: SHOW_ALERT,
      title: "Library deployment error",
      message: getContractError(err),
      level: "error",
    });
  }
}

function* deployDirectory(): any {
  const { provider, network } = yield select(bootstrapSelector);

  try {
    const directoryAddress = yield call(
      deployContract,
      "ContractsDirectory",
      provider
    );

    DIRECTORY_PER_CHAINID[network] = directoryAddress;

    yield put({ type: INIT_CONTRACTS_STATE });

    yield put({
      type: CLEAR_BOOTSTRAP_ERROR,
    });
  } catch (err) {
    console.error(err);
    yield put({
      type: SHOW_ALERT,
      title: "Directory contract deployment error",
      message: getContractError(err),
      level: "error",
    });
  }
}

export const bootstrapSagas = [
  takeEvery(METAMASK_DETECTION_REQUESTED, detectMetamask),
  takeEvery(QUERY_API_VERSION, queryApiVersion),
  takeEvery(LIST_ETH_ACCOUNTS, listEthAccounts),
  takeFirst(SUBSCRIBE_ACCOUNT_CHANGE, subscribeAccountsChange),
  takeEvery(DEPLOY_LIBRARY, deployLibrary),
  takeEvery(DEPLOY_DIRECTORY, deployDirectory),
];
