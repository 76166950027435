import { Card } from "react-bootstrap";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Action } from "redux";
import {
  LT_SET_RATIO_FEE,
  LT_SET_WITHDRAW_FEE,
  SET_CLAIM_FEE,
} from "../../../../store/actions";
import {
  advancedModeSelector,
  createCTSelector,
  createDelegableSelector,
  createInterfaceSelector,
  userAddressSelector,
} from "../../../../store/selectors";
import { AppState, Delegable } from "../../../../types";
import {
  EMPTY_ADDRESS,
  formatAmount,
  requiredSingleDecimalPercentValidator,
} from "../../../../utils";
import { EditableInput } from "../../../forms";

interface FeesViewProps {
  tokenAddress: string;
  hasInterface: boolean;
  account: string;
  owner: string;
  symbol: string;
  withdrawFeesPerThousandForLT: number;
  projectSymbol: string;
  claimFeesPerThousandForPT?: number;
  ratioFeesToRewardHodlersPerThousand?: number;
  advancedMode: boolean;
  maxWithdrawFees: number;
  maxClaimFees: number;
  updateWithdrawFee: (values: any, navigate: (path: string) => void) => void;
  updateRatioFee: (values: any, navigate: (path: string) => void) => void;
  updateClaimFee: (values: any, navigate: (path: string) => void) => void;
}

export const FeesView = ({
  hasInterface,
  account,
  owner,
  symbol,
  withdrawFeesPerThousandForLT,
  projectSymbol,
  claimFeesPerThousandForPT,
  ratioFeesToRewardHodlersPerThousand,
  advancedMode,
  maxWithdrawFees,
  maxClaimFees,
  updateWithdrawFee,
  updateRatioFee,
  updateClaimFee,
}: FeesViewProps) => {
  const navigate = useNavigate();
  const feesFormatter = (value: string) =>
    `${formatAmount(Number(value) * 10, 1, 1)} %`;

  return (
    <Card style={{ height: "100%" }}>
      <Card.Header as="h5" className="text-center">
        Fees &amp; rewards for stakers
      </Card.Header>
      <Card.Body>
        <div className="d-grid gap-2">
          {advancedMode && (
            <div>
              <EditableInput
                label={`${symbol} withdrawal fee :`}
                placeholder="Fee percentage (1 digit)"
                format={feesFormatter}
                validate={requiredSingleDecimalPercentValidator}
                value={(withdrawFeesPerThousandForLT / 10).toString()}
                maxValue={maxWithdrawFees}
                allowEdit={account === owner}
                onSubmit={(newValue) =>
                  updateWithdrawFee(
                    {
                      valuePerThousand: Math.floor(Number(newValue) * 10),
                    },
                    navigate
                  )
                }
              />
            </div>
          )}
          {advancedMode && (
            <div>
              <EditableInput
                label={`Ratio of withdrawal fees for stakers :`}
                placeholder="Fee percentage (1 digit)"
                format={feesFormatter}
                validate={requiredSingleDecimalPercentValidator}
                value={(ratioFeesToRewardHodlersPerThousand! / 10).toString()}
                allowEdit={account === owner}
                onSubmit={(newValue) =>
                  updateRatioFee(
                    {
                      valuePerThousand: Math.floor(Number(newValue) * 10),
                    },
                    navigate
                  )
                }
              />
            </div>
          )}

          {hasInterface && claimFeesPerThousandForPT !== undefined && (
            <>
              {advancedMode && <hr />}
              <div>
                <EditableInput
                  label={`${projectSymbol} claiming fee :`}
                  placeholder="Fee percentage (1 digit)"
                  format={feesFormatter}
                  validate={requiredSingleDecimalPercentValidator}
                  value={(claimFeesPerThousandForPT / 10).toString()}
                  maxValue={maxClaimFees}
                  allowEdit={account === owner}
                  onSubmit={(newValue) =>
                    updateClaimFee(
                      {
                        valuePerThousand: Math.floor(Number(newValue) * 10),
                      },
                      navigate
                    )
                  }
                />
              </div>
            </>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

function mapStateToProps(
  state: AppState,
  ownProps: Pick<FeesViewProps, "tokenAddress">
) {
  const ct = createCTSelector(ownProps.tokenAddress)(state);
  const iface = createInterfaceSelector(ct.interfaceProjectToken)(state);
  let delegable: Delegable | undefined;

  let claimFeesPerThousandForPT;
  if (iface !== undefined && iface.projectToken !== EMPTY_ADDRESS) {
    claimFeesPerThousandForPT = iface.claimFeesPerThousandForPT;
    delegable = createDelegableSelector(iface.projectToken)(state);
  }

  return {
    ...ownProps,
    account: userAddressSelector(state),
    owner: ct.owner,
    hasInterface: ct.interfaceProjectToken !== EMPTY_ADDRESS,
    symbol: ct.symbol,
    withdrawFeesPerThousandForLT: ct.withdrawFeesPerThousandForLT,
    projectSymbol: delegable !== undefined ? delegable.symbol : "",
    claimFeesPerThousandForPT,
    ratioFeesToRewardHodlersPerThousand: ct.ratioFeesToRewardHodlersPerThousand,
    advancedMode: advancedModeSelector(state),
    maxWithdrawFees: ct.maxWithdrawFeesPerThousandForLT / 10,
    maxClaimFees: ct.maxClaimFeesPerThousandForPT / 10,
  };
}

function mapDispatchToProps(
  dispatch: (action: Action<any>) => void,
  ownProps: Pick<FeesViewProps, "tokenAddress">
) {
  const { tokenAddress } = ownProps;

  return {
    updateWithdrawFee: (values: any, navigate: (path: string) => void) => {
      dispatch({
        type: LT_SET_WITHDRAW_FEE,
        tokenAddress,
        ...values,
        navigate,
      });
    },
    updateRatioFee: (values: any, navigate: (path: string) => void) => {
      dispatch({
        type: LT_SET_RATIO_FEE,
        tokenAddress,
        ...values,
        navigate,
      });
    },
    updateClaimFee: (values: any, navigate: (path: string) => void) => {
      dispatch({
        type: SET_CLAIM_FEE,
        tokenAddress,
        ...values,
        navigate,
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FeesView);
