import { BigNumber } from "ethers";
import createDecorator from "final-form-calculate";
import React, { ReactElement, useCallback, useMemo } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Field, Form } from "react-final-form";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Action } from "redux";
import { INVEST_BY_USER, InvestByUserAction } from "../../../../store/actions";
import {
  buildMaxAmountValidator,
  convertFromCT,
  convertToCT,
  formatAmount,
  parseAmount,
} from "../../../../utils";
import { AmountInput } from "../../../forms";

interface InvestProps {
  balance: string;
  max: string;
  tokenAddress: string;
  symbol: string;
  fundraisingSymbol: string;
  fundraisingDecimals: number;
  priceTokenPer1e18: BigNumber;
  decimals: number;
  disabled: boolean;
  button?: ReactElement;
  doInvest: (
    tokenAddress: string,
    amount: BigNumber,
    navigate: (to: string) => void
  ) => void;
}

const Invest = ({
  balance,
  tokenAddress,
  symbol,
  decimals,
  fundraisingSymbol,
  fundraisingDecimals,
  priceTokenPer1e18,
  disabled,
  max,
  button,
  doInvest,
}: InvestProps) => {
  const navigate = useNavigate();

  const amountObtainedCalculator = useMemo(
    () =>
      createDecorator({
        field: "amount",
        updates: {
          amountObtained: (amount?: string) => {
            return amount !== undefined && amount !== ""
              ? convertToCT(
                  parseAmount(amount, fundraisingDecimals),
                  fundraisingDecimals,
                  priceTokenPer1e18,
                  decimals
                ).toString()
              : "0";
          },
          available: (amount?: string) => {
            const bnMax = BigNumber.from(max);
            const bnAmount =
              amount !== undefined && amount !== ""
                ? convertToCT(
                    parseAmount(amount, fundraisingDecimals),
                    fundraisingDecimals,
                    priceTokenPer1e18,
                    decimals
                  )
                : BigNumber.from("0");
            return bnMax.lt(bnAmount) ? "0" : bnMax.sub(bnAmount).toString();
          },
        },
      }),
    [fundraisingDecimals, priceTokenPer1e18, max]
  );

  const setPayload = useCallback(
    ({ amount }: any) =>
      doInvest(
        tokenAddress,
        parseAmount(amount, fundraisingDecimals),
        navigate
      ),
    [fundraisingDecimals, tokenAddress]
  );

  const maxInvest = useMemo(() => {
    const maxAvailable = convertFromCT(
      BigNumber.from(max),
      decimals,
      priceTokenPer1e18,
      fundraisingDecimals
    );
    if (BigNumber.from(balance).lt(maxAvailable)) {
      return balance;
    } else {
      return maxAvailable.toString();
    }
  }, [max, priceTokenPer1e18, balance]);

  return (
    <>
      <Form
        initialValues={{
          amountObtained: "0",
          available: max,
        }}
        onSubmit={setPayload}
        decorators={[amountObtainedCalculator]}
        render={({ form, handleSubmit, pristine, submitting }) => (
          <form onSubmit={handleSubmit} noValidate className="needs-validation">
            <Card>
              <Card.Body>
                <Row className="align-items-end justify-content-center">
                  <Col md={12} lg={6} xl={4}>
                    <p>
                      Token price : {formatAmount(priceTokenPer1e18, 18)}{" "}
                      {fundraisingSymbol}
                    </p>

                    <p>
                      Your balance :{" "}
                      {formatAmount(
                        BigNumber.from(balance),
                        fundraisingDecimals
                      )}{" "}
                      {fundraisingSymbol}
                    </p>

                    <Field name="available">
                      {({ input }) => (
                        <p>
                          Amount of tokens available :{" "}
                          {formatAmount(input.value, decimals)} {symbol}
                        </p>
                      )}
                    </Field>
                  </Col>

                  <Col md={12} lg={6} xl={4}>
                    <Field
                      name="amount"
                      validate={buildMaxAmountValidator(
                        maxInvest,
                        fundraisingDecimals,
                        true
                      )}
                    >
                      {({ input, meta }) => {
                        return (
                          <AmountInput
                            placeholder="Amount to invest"
                            symbol={fundraisingSymbol}
                            decimals={fundraisingDecimals}
                            max={maxInvest}
                            meta={meta}
                            {...input}
                          >
                            {React.cloneElement(
                              button || (
                                <Button type="submit" variant="primary">
                                  Invest
                                </Button>
                              ),
                              {
                                style: { minWidth: "7rem" },
                                disabled:
                                  disabled ||
                                  pristine ||
                                  submitting ||
                                  form.getState().invalid,
                              }
                            )}
                          </AmountInput>
                        );
                      }}
                    </Field>

                    <Field name="amountObtained">
                      {({ input }) => (
                        <p className="mt-3">
                          Amount of tokens you will get :{" "}
                          {formatAmount(BigNumber.from(input.value), decimals)}{" "}
                          {symbol}
                        </p>
                      )}
                    </Field>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </form>
        )}
      />
    </>
  );
};

function mapDispatchToProps(dispatch: (action: Action<any>) => void) {
  return {
    doInvest: (
      tokenAddress: string,
      amount: BigNumber,
      navigate: (path: string) => void
    ) => {
      dispatch({
        type: INVEST_BY_USER,
        tokenAddress,
        amount,
        navigate,
      } as InvestByUserAction);
    },
  };
}

export default connect(null, mapDispatchToProps)(Invest);
