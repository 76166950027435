import { applyMiddleware, createStore } from "redux";
import {
  crashReporterMiddleware,
  loggerMiddleware,
  reduxPromiseListener,
  sagaMiddleware,
} from "./middleware";
import { appReducer } from "./reducers/root";

export const store = createStore(
  appReducer,
  applyMiddleware(
    loggerMiddleware,
    crashReporterMiddleware,
    sagaMiddleware,
    reduxPromiseListener.middleware
  )
);
