/* eslint-disable max-lines */
import { gql } from "@apollo/client";

export interface gqlEntry {
  key: string;
  value: string;
}

interface gqlContract {
  chainId: number;
  address: string;
  owner: string;
}

interface gqlErc20 extends gqlContract {
  name: string;
  symbol: string;
  decimals: string;
  totalSupply: string;
}

export interface gqlDirectory extends gqlContract {
  directory: string[];
  whitelistedProjectOwners: string[];
  projects: string[];
  projectRelatedToLT: gqlEntry[];
  whitelist: gqlEntry[];
  areUserFunctionsDisabled: boolean;
}

export interface gqlChargedToken extends gqlErc20 {
  fractionInitialUnlockPerThousand: string;
  durationCliff: string;
  durationLinearVesting: string;
  maxInitialTokenAllocation: string;
  maxWithdrawFeesPerThousandForLT: string;
  maxClaimFeesPerThousandForPT: string;
  maxStakingAPR: string;
  maxStakingTokenAmount: string;

  stakingStartDate: string;
  stakingDuration: string;
  stakingDateLastCheckpoint: string;
  campaignStakingRewards: string;
  totalStakingRewards: string;

  areUserFunctionsDisabled: boolean;
  isInterfaceProjectTokenLocked: boolean;
  areAllocationsTerminated: boolean;

  interfaceProjectToken: string;
  ratioFeesToRewardHodlersPerThousand: string;
  currentRewardPerShare1e18: string;
  stakedLT: string;
  totalLocked: string;
  totalTokenAllocated: string;
  withdrawFeesPerThousandForLT: string;

  isFundraisingContract: boolean;
  fundraisingTokenSymbol: string;
  priceTokenPer1e18: string;
  fundraisingToken: string;
  isFundraisingActive: boolean;
}

export interface gqlInterfaceProjectToken extends gqlContract {
  liquidityToken: string;
  projectToken: string;
  dateLaunch: string;
  dateEndCliff: string;
  claimFeesPerThousandForPT: string;
}

export interface gqlDelegableToLT extends gqlErc20 {
  validatedInterfaceProjectToken: string[];
  isListOfInterfaceProjectTokenComplete: boolean;
}

export interface gqlUserBalancesEntry {
  chainId: number;
  user: string;
  address: string;
  balance: string;
  balancePT: string;
  fullyChargedBalance: string;
  partiallyChargedBalance: string;
  dateOfPartiallyCharged: string;
  valueProjectTokenToFullRecharge: string;
}

export interface gqlHealth {
  index: number;
  rpc: string;
  directory: string;
  name?: string;
  chainId?: number;
  providerStatus: string;
  workerStatus: string;
  wsStatus: string;
  restartCount: number;
}

export interface gqlEvent {
  status: string;
  chainId: number;
  address: string;
  blockNumber: number;
  blockDate: string;
  txHash: string;
  txIndex: number;
  logIndex: number;
  name: string;
  contract: string;
  topics: string[];
  args: string[];
}

export interface gqlTransaction {
  chainId: number;
  hash: string;
}

export const TYPES = gql`
  type IEntry {
    key: String!
    value: String!
  }

  interface IOwnable {
    address: String!
    owner: String!
  }

  interface IERC20 {
    address: String!
    owner: String!

    name: String!
    symbol: String!
    decimals: String!
    totalSupply: String!
  }

  type IDirectory implements IOwnable {
    chainId: Int!
    address: String!
    owner: String!

    directory: [String!]!
    whitelistedProjectOwners: [String!]!
    projects: [String!]!
    projectRelatedToLT: [IEntry!]!
    whitelist: [IEntry!]!
    areUserFunctionsDisabled: Boolean!
  }

  type IChargedToken implements IERC20 {
    chainId: Int!
    address: String!
    owner: String!

    name: String!
    symbol: String!
    decimals: String!
    totalSupply: String!

    fractionInitialUnlockPerThousand: String!
    durationCliff: String!
    durationLinearVesting: String!
    maxInitialTokenAllocation: String!
    maxWithdrawFeesPerThousandForLT: String!
    maxClaimFeesPerThousandForPT: String!
    maxStakingAPR: String!
    maxStakingTokenAmount: String!

    stakingStartDate: String!
    stakingDuration: String!
    stakingDateLastCheckpoint: String!
    campaignStakingRewards: String!
    totalStakingRewards: String!

    areUserFunctionsDisabled: Boolean!
    isInterfaceProjectTokenLocked: Boolean!
    areAllocationsTerminated: Boolean!

    interfaceProjectToken: String!
    ratioFeesToRewardHodlersPerThousand: String!
    currentRewardPerShare1e18: String!
    stakedLT: String!
    totalLocked: String!
    totalTokenAllocated: String!
    withdrawFeesPerThousandForLT: String!

    isFundraisingContract: Boolean!
    fundraisingTokenSymbol: String!
    priceTokenPer1e18: String!
    fundraisingToken: String!
    isFundraisingActive: Boolean!
  }

  type IInterfaceProjectToken implements IOwnable {
    chainId: Int!
    address: String!
    owner: String!

    liquidityToken: String!
    projectToken: String!
    dateLaunch: String!
    dateEndCliff: String!
    claimFeesPerThousandForPT: String!
  }

  type IDelegableToLT implements IERC20 {
    chainId: Int!
    address: String!
    owner: String!

    name: String!
    symbol: String!
    decimals: String!
    totalSupply: String!

    validatedInterfaceProjectToken: [String!]!
    isListOfInterfaceProjectTokenComplete: Boolean
  }

  type IUserBalancesEntry {
    chainId: Int!
    user: String!
    address: String!
    balance: String!
    balancePT: String!
    fullyChargedBalance: String!
    partiallyChargedBalance: String!
    dateOfPartiallyCharged: String!
    claimedRewardPerShare1e18: String!
    valueProjectTokenToFullRecharge: String!
  }

  type IWorkerHealth {
    index: Int!
    rpc: String!
    directory: String!
    name: String
    chainId: Int
    providerStatus: String!
    workerStatus: String!
    wsStatus: String!
  }

  type IEvent {
    status: String!
    chainId: Int!
    address: String!
    blockNumber: Int!
    blockDate: String!
    txHash: String!
    txIndex: Int!
    logIndex: Int!
    name: String!
    contract: String!
    topics: [String!]!
    args: [String!]!
  }

  type ITransaction {
    chainId: Int!
    hash: String!
  }

  type Query {
    version: String!
    Directory(chainId: Int!): IDirectory
    allChargedTokens(chainId: Int!): [IChargedToken!]!
    ChargedToken(chainId: Int!, address: String!): IChargedToken!
    allInterfaceProjectTokens(chainId: Int!): [IInterfaceProjectToken!]!
    InterfaceProjectToken(
      chainId: Int!
      address: String!
    ): IInterfaceProjectToken!
    allDelegableToLTs(chainId: Int!): [IDelegableToLT!]!
    DelegableToLT(chainId: Int!, address: String!): IDelegableToLT!
    userBalances(
      chainId: Int!
      user: String!
      address: String
    ): [IUserBalancesEntry!]!
    events(chainId: Int!, offset: Int, count: Int): [IEvent!]!
    countEvents(chainId: Int!): Int!
    health: [IWorkerHealth!]!
  }

  type Subscription {
    Directory(chainId: Int!): IDirectory
    ChargedToken(chainId: Int!, address: String!): IChargedToken!
    InterfaceProjectToken(
      chainId: Int!
      address: String!
    ): IInterfaceProjectToken!
    DelegableToLT(chainId: Int!, address: String!): IDelegableToLT!
    userBalances(chainId: Int!, user: String!): [IUserBalancesEntry!]!
    health: [IWorkerHealth!]!
    transaction(chainId: Int!, hash: String!): ITransaction!
  }
`;

export const GET_VERSION = gql`
  query version {
    version
  }
`;

export const GET_DIRECTORY = gql`
  query getDirectory($chainId: Int!) {
    Directory(chainId: $chainId) {
      address
      owner

      directory
      whitelistedProjectOwners
      projects
      projectRelatedToLT {
        key
        value
      }
      whitelist {
        key
        value
      }
      areUserFunctionsDisabled
    }
  }
`;

export const SUBSCRIBE_DIRECTORY = gql`
  subscription subscribeDirectory($chainId: Int!) {
    Directory(chainId: $chainId) {
      address
      owner

      directory
      whitelistedProjectOwners
      projects
      projectRelatedToLT {
        key
        value
      }
      whitelist {
        key
        value
      }
      areUserFunctionsDisabled
    }
  }
`;

export const GET_ALL_CHARGED_TOKENS = gql`
  query getAllChargedTokens($chainId: Int!) {
    allChargedTokens(chainId: $chainId) {
      address
      owner

      name
      symbol
      decimals
      totalSupply

      fractionInitialUnlockPerThousand
      durationCliff
      durationLinearVesting
      maxInitialTokenAllocation
      maxWithdrawFeesPerThousandForLT
      maxClaimFeesPerThousandForPT
      maxStakingAPR
      maxStakingTokenAmount

      stakingStartDate
      stakingDuration
      stakingDateLastCheckpoint
      campaignStakingRewards
      totalStakingRewards

      areUserFunctionsDisabled
      isInterfaceProjectTokenLocked
      areAllocationsTerminated

      interfaceProjectToken
      ratioFeesToRewardHodlersPerThousand
      currentRewardPerShare1e18
      stakedLT
      totalLocked
      totalTokenAllocated
      withdrawFeesPerThousandForLT

      isFundraisingContract
      fundraisingTokenSymbol
      priceTokenPer1e18
      fundraisingToken
      isFundraisingActive
    }
  }
`;

export const GET_CHARGED_TOKEN = gql`
  query getChargedToken($chainId: Int!, $address: String!) {
    ChargedToken(chainId: $chainId, address: $address) {
      address
      owner

      name
      symbol
      decimals
      totalSupply

      fractionInitialUnlockPerThousand
      durationCliff
      durationLinearVesting
      maxInitialTokenAllocation
      maxWithdrawFeesPerThousandForLT
      maxClaimFeesPerThousandForPT
      maxStakingAPR
      maxStakingTokenAmount

      stakingStartDate
      stakingDuration
      stakingDateLastCheckpoint
      campaignStakingRewards
      totalStakingRewards

      areUserFunctionsDisabled
      isInterfaceProjectTokenLocked
      areAllocationsTerminated

      interfaceProjectToken
      ratioFeesToRewardHodlersPerThousand
      currentRewardPerShare1e18
      stakedLT
      totalLocked
      totalTokenAllocated
      withdrawFeesPerThousandForLT

      isFundraisingContract
      fundraisingTokenSymbol
      priceTokenPer1e18
      fundraisingToken
      isFundraisingActive
    }
  }
`;

export const SUBSCRIBE_CHARGED_TOKEN = gql`
  subscription subscribeChargedToken($chainId: Int!, $address: String!) {
    ChargedToken(chainId: $chainId, address: $address) {
      address
      owner

      name
      symbol
      decimals
      totalSupply

      fractionInitialUnlockPerThousand
      durationCliff
      durationLinearVesting
      maxInitialTokenAllocation
      maxWithdrawFeesPerThousandForLT
      maxClaimFeesPerThousandForPT
      maxStakingAPR
      maxStakingTokenAmount

      stakingStartDate
      stakingDuration
      stakingDateLastCheckpoint
      campaignStakingRewards
      totalStakingRewards

      areUserFunctionsDisabled
      isInterfaceProjectTokenLocked
      areAllocationsTerminated

      interfaceProjectToken
      ratioFeesToRewardHodlersPerThousand
      currentRewardPerShare1e18
      stakedLT
      totalLocked
      totalTokenAllocated
      withdrawFeesPerThousandForLT

      isFundraisingContract
      fundraisingTokenSymbol
      priceTokenPer1e18
      fundraisingToken
      isFundraisingActive
    }
  }
`;

export const GET_ALL_INTERFACE_PROJECTS = gql`
  query getAllInterfaceProjects($chainId: Int!) {
    allInterfaceProjectTokens(chainId: $chainId) {
      address
      owner

      liquidityToken
      projectToken
      dateLaunch
      dateEndCliff
      claimFeesPerThousandForPT
    }
  }
`;

export const GET_INTERFACE_PROJECT = gql`
  query getInterfaceProject($chainId: Int!, $address: String!) {
    InterfaceProjectToken(chainId: $chainId, address: $address) {
      address
      owner

      liquidityToken
      projectToken
      dateLaunch
      dateEndCliff
      claimFeesPerThousandForPT
    }
  }
`;

export const SUBSCRIBE_INTERFACE_PROJECT = gql`
  subscription subscribeInterfaceProjects($chainId: Int!, $address: String!) {
    InterfaceProjectToken(chainId: $chainId, address: $address) {
      address
      owner

      liquidityToken
      projectToken
      dateLaunch
      dateEndCliff
      claimFeesPerThousandForPT
    }
  }
`;

export const GET_ALL_DELEGABLE_TOKENS = gql`
  query getAllDelegableToLTs($chainId: Int!) {
    allDelegableToLTs(chainId: $chainId) {
      address
      owner

      name
      symbol
      decimals
      totalSupply

      validatedInterfaceProjectToken
      isListOfInterfaceProjectTokenComplete
    }
  }
`;

export const GET_DELEGABLE_TOKEN = gql`
  query getDelegableToLT($chainId: Int!, $address: String!) {
    DelegableToLT(chainId: $chainId, address: $address) {
      address
      owner

      name
      symbol
      decimals
      totalSupply

      validatedInterfaceProjectToken
      isListOfInterfaceProjectTokenComplete
    }
  }
`;

export const SUBSCRIBE_DELEGABLE_TOKEN = gql`
  subscription subscribeDelegableToLT($chainId: Int!, $address: String!) {
    DelegableToLT(chainId: $chainId, address: $address) {
      address
      owner

      name
      symbol
      decimals
      totalSupply

      validatedInterfaceProjectToken
      isListOfInterfaceProjectTokenComplete
    }
  }
`;

export const GET_USER_BALANCES = gql`
  query getUserBalances($chainId: Int!, $user: String!) {
    userBalances(chainId: $chainId, user: $user) {
      user
      address
      balance
      balancePT
      fullyChargedBalance
      partiallyChargedBalance
      dateOfPartiallyCharged
      claimedRewardPerShare1e18
      valueProjectTokenToFullRecharge
    }
  }
`;

export const GET_USER_BALANCE = gql`
  query getUserBalance($chainId: Int!, $user: String!, $address: String!) {
    UserBalance(chainId: $chainId, user: $user, address: $address) {
      user
      address
      balance
      balancePT
      fullyChargedBalance
      partiallyChargedBalance
      dateOfPartiallyCharged
      claimedRewardPerShare1e18
      valueProjectTokenToFullRecharge
    }
  }
`;

export const SUBSCRIBE_USER_BALANCES = gql`
  subscription subscribeUserBalances($chainId: Int!, $user: String!) {
    userBalances(chainId: $chainId, user: $user) {
      user
      address
      balance
      balancePT
      fullyChargedBalance
      partiallyChargedBalance
      dateOfPartiallyCharged
      claimedRewardPerShare1e18
      valueProjectTokenToFullRecharge
    }
  }
`;

export const QUERY_HEALTH = gql`
  query queryHealth {
    health {
      index
      rpc
      directory
      name
      chainId
      providerStatus
      workerStatus
      wsStatus
      restartCount
    }
  }
`;

export const GET_EVENTS = gql`
  query getEvents($chainId: Int!, $offset: Int, $count: Int) {
    events(chainId: $chainId, offset: $offset, count: $count) {
      status
      chainId
      address
      blockNumber
      blockDate
      txHash
      txIndex
      logIndex
      name
      contract
      topics
      args
    }
  }
`;

export const COUNT_EVENTS = gql`
  query countEvents($chainId: Int!) {
    countEvents(chainId: $chainId)
  }
`;

export const SUBSCRIBE_TRANSACTION = gql`
  subscription subscribeTransaction($chainId: Int!, $hash: String!) {
    transaction(chainId: $chainId, hash: $hash) {
      chainId
      hash
    }
  }
`;
