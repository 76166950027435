import {
  ApolloClient,
  DefaultOptions,
  HttpLink,
  InMemoryCache,
  from,
  split,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import { WEBSOCKET_CLOSED, WEBSOCKET_CONNECTED } from "./store/actions";
import { store } from "./store/store";

declare global {
  interface Window {
    __runtime_configuration: {
      apiHost: string;
      apiEndpoint: string;
      wsApiEndpoint: string;
    };
  }
}

const httpLink = new HttpLink({
  uri: window.__runtime_configuration.apiEndpoint,
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: window.__runtime_configuration.wsApiEndpoint,
    on: {
      connected: () => {
        store.dispatch({ type: WEBSOCKET_CONNECTED });
      },
      closed: () => {
        store.dispatch({ type: WEBSOCKET_CLOSED });
      },
      error: (err) => {
        console.error("Websocket disconnected !", err);
      },
    },
  })
);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: 60000,
    jitter: true,
  },
  attempts: {
    max: 100,
  },
});

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const finalLink = from([errorLink, retryLink, splitLink]);

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

export const client = new ApolloClient({
  link: finalLink,
  cache: new InMemoryCache({ resultCaching: false }),
  defaultOptions,
});
