import { client } from "../../api";
import { SUBSCRIBE_TRANSACTION, gqlTransaction } from "../../graphql";

export async function waitForTransaction(
  chainId: number,
  hash: string
): Promise<gqlTransaction> {
  console.log("subscribing to transaction :", chainId, hash);
  const subscription = client.subscribe({
    query: SUBSCRIBE_TRANSACTION,
    variables: {
      chainId,
      hash,
    },
  });

  return new Promise((resolve, reject) => {
    subscription.subscribe(
      (response) => {
        console.warn("Transaction mined :", chainId, hash, response);
        resolve(response.data);
      },
      (error) => {
        console.error(
          "transaction subscription failed :",
          chainId,
          hash,
          error
        );
        reject(error);
      },
      () => {
        console.log("transaction subscription ended :", chainId, hash);
      }
    );
  });
}
