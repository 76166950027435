import { useMemo } from "react";
import { Button, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DEPLOY_DIRECTORY, DEPLOY_LIBRARY } from "../../store/actions";
import { bootstrapSelector, directorySelector } from "../../store/selectors";
import { AppState } from "../../types";
import {
  allLibrariesDeployed,
  isDirectoryDeployed,
  validAddress,
} from "../../utils";

interface LibraryDeployerProps {
  name: string;
  address?: string;
  deployLibrary: (name: string, navigate: (path: string) => void) => void;
}

const LibraryDeployer = ({
  name,
  address,
  deployLibrary,
}: LibraryDeployerProps) => {
  const navigate = useNavigate();

  return address !== undefined && validAddress(address) === undefined ? (
    <div>
      <h3>
        {name} deployed at {address}
      </h3>
    </div>
  ) : (
    <div>
      <Button role="button" onClick={() => deployLibrary(name, navigate)}>
        Deploy {name}
      </Button>
    </div>
  );
};

interface DeployProps {
  network: string;
  directory?: string;
  libraries?: Record<string, string>;
  deployLibrary: (name: string, navigate: (path: string) => void) => void;
  deployDirectory: (navigate: (path: string) => void) => void;
}

const Deploy = ({
  network,
  directory,
  libraries,
  deployLibrary,
  deployDirectory,
}: DeployProps) => {
  const navigate = useNavigate();

  const librariesDump = useMemo(
    () => JSON.stringify({ [network]: libraries }, null, 2),
    [libraries]
  );

  return location.hostname !== "localhost" ? null : (
    <Container>
      {["SafeMath", "AddressSet", "StringSet"].map((name) => (
        <LibraryDeployer
          key={name}
          name={name}
          address={libraries !== undefined ? libraries[name] : undefined}
          deployLibrary={deployLibrary}
        />
      ))}
      <pre>{librariesDump}</pre>
      {isDirectoryDeployed(directory) && (
        <div>
          <h3>Directory deployed at {directory}</h3>
        </div>
      )}
      {!isDirectoryDeployed(directory) && allLibrariesDeployed(libraries) && (
        <div>
          <Button role="button" onClick={() => deployDirectory(navigate)}>
            Deploy Directory
          </Button>
        </div>
      )}
    </Container>
  );
};

function mapStateToProps(state: AppState) {
  const { network, libraries } = bootstrapSelector(state);
  const directory = directorySelector(state);

  return {
    network,
    directory: directory !== undefined ? directory.address : "",
    libraries,
  };
}

function mapDispatchToProps(dispatch: (action: any) => void) {
  return {
    deployLibrary: (name: string, navigate: (path: string) => void) =>
      dispatch({ type: DEPLOY_LIBRARY, name, navigate }),
    deployDirectory: (navigate: (path: string) => void) =>
      dispatch({ type: DEPLOY_DIRECTORY, navigate }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Deploy);
