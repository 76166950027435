import {
  getThemeBrand,
  getThemeEntry,
  isThemeProjectFiltered,
} from "charged-token-themes";
import React, { useState } from "react";
import {
  Col,
  Container,
  Form,
  Nav,
  NavDropdown,
  Navbar,
} from "react-bootstrap";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  CHANGE_DISPLAYED_PROJECT,
  TOGGLE_ADVANCED_MODE,
} from "../../store/actions";
import { bootstrapSelector, directorySelector } from "../../store/selectors";
import { AppState } from "../../types";
import { AddLTForm, WhitelistLTForm } from "../admin";
import { NewMetamaskBootstrap } from "../metamask";
import Wallet from "./Wallet";

interface HomepageProps {
  connected: boolean;
  directoryOwner: string;
  projectsNames: string[];
  account: string;
  network: string;
  whitelist: string[];
  advancedMode: boolean;
  selectedProjectName?: string;
  setSelectedProject: (name: string | undefined) => void;
  toggleAdvancedMode: () => void;
  pageComponent: React.ElementType;
  pageComponentProps?: any;
}

export const Homepage = ({
  connected,
  projectsNames,
  account,
  network,
  whitelist,
  directoryOwner,
  advancedMode,
  selectedProjectName,
  setSelectedProject,
  toggleAdvancedMode,
  pageComponent,
  pageComponentProps,
}: HomepageProps) => {
  const [showWhitelistDialog, setShowWhitelistDialog] = useState(false);
  const [showAddLTDialog, setShowAddLTDialog] = useState(false);

  const filterProject = isThemeProjectFiltered();
  const themeEntry = getThemeEntry();
  const brand = getThemeBrand();
  const uri = themeEntry.uri;
  const routeParams = useParams();

  pageComponentProps = { ...pageComponentProps, ...routeParams };

  return (
    <>
      <Navbar variant="dark" bg="primary" expand="lg" className="mb-4">
        <Container fluid>
          <Navbar.Brand href={uri} target="_blank">
            <img src={`/dist/brand/${brand}.png`} className="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbar-content" />
          <Navbar.Collapse id="navbar-content">
            <Nav className="me-auto">
              <Link to="/" className="nav-link">
                Home
              </Link>
              {filterProject !== true && projectsNames.length > 1 && (
                <NavDropdown
                  menuVariant={themeEntry?.dark === true ? "dark" : "light"}
                  title={
                    selectedProjectName === undefined ||
                    selectedProjectName === ""
                      ? "All projects"
                      : selectedProjectName
                  }
                  id="basic-nav-dropdown"
                >
                  {projectsNames.map((name, index) => (
                    <NavDropdown.Item
                      key={index}
                      type="button"
                      onClick={() => setSelectedProject(name)}
                      component={NavDropdown.Item}
                    >
                      {name}
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
              )}
              {account === directoryOwner && (
                <>
                  <Nav.Link
                    type="button"
                    onClick={() => setShowWhitelistDialog(true)}
                  >
                    Add project
                  </Nav.Link>
                  <Link to="/admin/directory" className="nav-link">
                    Directory admin
                  </Link>
                  <Link to="/admin/status" className="nav-link">
                    Status
                  </Link>
                </>
              )}
              {whitelist.includes(account) && (
                <Nav.Link
                  type="button"
                  onClick={() => setShowAddLTDialog(true)}
                >
                  Create Token
                </Nav.Link>
              )}
            </Nav>
            {account === directoryOwner && (
              <Nav className="me-3">Connected : {connected.toString()}</Nav>
            )}
            <Nav className="me-3">
              <Form.Switch
                label={advancedMode ? "Advanced" : "Standard"}
                checked={advancedMode}
                onChange={toggleAdvancedMode}
              />
            </Nav>
            <Nav>
              <NewMetamaskBootstrap>
                <Wallet network={network} address={account} />
              </NewMetamaskBootstrap>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <AddLTForm
        show={showAddLTDialog}
        handleClose={() => setShowAddLTDialog(false)}
      />
      <WhitelistLTForm
        show={showWhitelistDialog}
        handleClose={() => setShowWhitelistDialog(false)}
      />

      {React.createElement(pageComponent, pageComponentProps)}

      <Navbar fixed="bottom" variant="dark" bg="primary">
        <Container>
          <Col xs={{ span: 4, offset: 4 }} className="text-center">
            <Navbar.Brand
              href="https://www.yz-network.com"
              target="_blank"
              className="footer-brand"
            >
              Powered by <img src="/logo.png" className="logo" />
            </Navbar.Brand>
          </Col>
        </Container>
      </Navbar>
    </>
  );
};

function mapStateToProps(state: AppState, ownProps: any) {
  const { network, advancedMode, account, selectedProjectName } =
    bootstrapSelector(state);
  const directory = directorySelector(state);
  const { connected } = state;

  return {
    ...ownProps,
    connected,
    projectsNames: directory !== undefined ? directory.projects : [],
    account,
    network,
    whitelist:
      directory !== undefined ? directory.whitelistedProjectOwners : [],
    directoryOwner: directory !== undefined ? directory.owner : "",
    advancedMode,
    selectedProjectName,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    setSelectedProject: (name: string | undefined) =>
      dispatch({ type: CHANGE_DISPLAYED_PROJECT, name }),
    toggleAdvancedMode: () => dispatch({ type: TOGGLE_ADVANCED_MODE }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
