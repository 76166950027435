import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { SHOW_ALERT } from "../../store/actions";

interface CopyButtonProps {
  msg: string;
  value: string;
  disabled?: boolean;
  title?: string;
  notifyCopy: (message: string) => void;
}

function copyToClipboard(
  event: any,
  msg: string,
  value: string,
  notifyCopy: (message: string) => void
) {
  event.preventDefault();
  event.stopPropagation();
  navigator.clipboard.writeText(value);
  notifyCopy(msg);
  return false;
}

export const CopyButton = (props: CopyButtonProps) => {
  return (
    <Button
      disabled={props.disabled === true}
      title={props.title}
      size="sm"
      onClick={(event) =>
        copyToClipboard(event, props.msg, props.value, props.notifyCopy)
      }
    >
      <i className="bi bi-clipboard"></i>
    </Button>
  );
};

function mapDispatchToProps(dispatch: (action: any) => void) {
  return {
    notifyCopy: (message: string) =>
      dispatch({ type: SHOW_ALERT, title: "Copy", message, level: "info" }),
  };
}

export default connect(null, mapDispatchToProps)(CopyButton);
