import React, { useCallback } from "react";
import { Button, Modal } from "react-bootstrap";
import { Field, Form } from "react-final-form";
import { connect } from "react-redux";
import { Action } from "redux";
import { WhitelistProjectAction, WHITELIST_PROJECT } from "../../store/actions";
import { required, requiredAddressValidator } from "../../utils";
import { AddressInput, TextInput } from "../forms";

interface WhitelistLTFormProps {
  show: boolean;
  handleClose: () => void;
  whitelistProject: (owner: string, project: string) => void;
}

const WhitelistLTForm = ({
  show,
  handleClose,
  whitelistProject,
}: WhitelistLTFormProps) => {
  const onSubmit = useCallback(
    ({ owner, project }: any) => {
      whitelistProject(owner, project);
      handleClose();
    },
    [handleClose, whitelistProject]
  );

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Whitelist Project owner</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, form, submitting, pristine }) => (
            <form
              onSubmit={handleSubmit}
              noValidate
              className="needs-validation d-grid gap-2"
            >
              <Field name="project" validate={required}>
                {({ input, meta }) => (
                  <TextInput label="Project name" meta={meta} {...input} />
                )}
              </Field>

              <Field name="owner" validate={requiredAddressValidator}>
                {({ input, meta }) => (
                  <AddressInput
                    label="Owner's address"
                    meta={meta}
                    {...input}
                  />
                )}
              </Field>

              <Button
                type="submit"
                size="lg"
                variant="primary"
                className="mt-4"
                disabled={pristine || submitting || form.getState().invalid}
              >
                Add to whitelist
              </Button>
            </form>
          )}
        ></Form>
      </Modal.Body>
    </Modal>
  );
};

function mapDispatchToProps(dispatch: (action: Action<any>) => void) {
  return {
    whitelistProject: (owner: string, project: string) => {
      dispatch({
        type: WHITELIST_PROJECT,
        owner,
        project,
      } as WhitelistProjectAction);
    },
  };
}

export default connect(null, mapDispatchToProps)(WhitelistLTForm);
