import { DEV_NETWORK } from "./blockchain";

function getOrDefault(value: string | undefined, defaultValue: string): string {
  return value !== undefined ? value : defaultValue;
}

export const CONTRACTS_COMMIT = getOrDefault(
  process.env.REACT_APP_CONTRACTS_COMMIT,
  "dev"
);
export const DAPP_VERSION = getOrDefault(
  process.env.REACT_APP_DAPP_VERSION,
  "dev"
);
export const DAPP_COMMIT = getOrDefault(
  process.env.REACT_APP_DAPP_COMMIT,
  "dev"
);

type ExplorerBlockCountDownFunction = (block: number) => string;
type ExplorerAddressFunction = (address: string) => string;
type ExplorerTxFunction = (txHash: string) => string;

export const BLOCK_COUNTDOWN_PREFIX: Record<
  string,
  ExplorerBlockCountDownFunction
> = {
  // mainnets
  "56": (block: number) => `https://bscscan.com/block/${block}`,
  "137": (block: number) => `https://polygonscan.com/block/countdown/${block}`,
  // testnets
  "11155111": (block: number) => `https://sepolia.etherscan.io/block/${block}`,
  "80002": (block: number) => `https://www.oklink.com/amoy/block/${block}`,
  // dev
  [DEV_NETWORK]: (block: number) => "",
};

export const ETHERSCAN_ADDRESS_PREFIX: Record<string, ExplorerAddressFunction> =
  {
    // mainnets
    "56": (address: string) => `https://bscscan.com/address/${address}`,
    "137": (address: string) => `https://polygonscan.com/address/${address}`,
    // testnets
    "11155111": (address: string) =>
      `https://sepolia.etherscan.io/address/${address}`,
    "80002": (address: string) =>
      `https://www.oklink.com/amoy/address/${address}`,
    // dev
    [DEV_NETWORK]: (address: string) => "",
  };

export const ETHERSCAN_TX_PREFIX: Record<string, ExplorerTxFunction> = {
  // mainnets
  "56": (txHash: string) => `https://bscscan.com/tx/${txHash}`,
  "137": (txHash: string) => `https://polygonscan.com/tx/${txHash}`,
  // testnets
  "11155111": (txHash: string) => `https://sepolia.etherscan.io/tx/${txHash}`,
  "80002": (txHash: string) => `https://www.oklink.com/amoy/tx/${txHash}`,
  // dev
  [DEV_NETWORK]: (txHash: string) => "",
};
