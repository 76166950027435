import { ethers } from "ethers";
import { connect } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { UnknownNetwork } from "./components/UnknownNetwork";
import { Health, ProjectsList } from "./components/admin";
import {
  Alerts,
  ConnectedHomepage,
  FlexScreen,
  LoadingScreen,
  TransactionSummary,
} from "./components/common";
import { LiquidTokenPage, ListLTContracts } from "./components/projects";
import { bootstrapSelector, providerSelector } from "./store/selectors";
import { AppState } from "./types";

interface AppProps {
  provider?: ethers.providers.Web3Provider;
  directory?: string | null;
  error?: string | React.ReactElement;
  chainId?: number;
  bootstrapLoading: boolean;
}

const App = ({ bootstrapLoading, error, chainId }: AppProps) => {
  if (error !== undefined) {
    if (chainId === undefined) {
      chainId = 0;
    }

    if (["UNKNOWN NETWORK", "UNSUPPORTED NETWORK"].includes(error as string)) {
      return (
        <FlexScreen>
          <UnknownNetwork chainId={chainId} />
        </FlexScreen>
      );
    }

    return (
      <FlexScreen>
        <>{error}</>
      </FlexScreen>
    );
  }

  if (bootstrapLoading) {
    return <LoadingScreen msg="Loading..." />;
  }

  return (
    <>
      <Routes>
        <Route
          path=""
          element={<ConnectedHomepage pageComponent={ListLTContracts} />}
        />

        <Route path="admin">
          <Route
            path="directory"
            element={<ConnectedHomepage pageComponent={ProjectsList} />}
          />
          <Route
            path="status"
            element={<ConnectedHomepage pageComponent={Health} />}
          />
        </Route>

        <Route path="lt">
          <Route path=":tokenAddress">
            <Route
              path=":tab"
              element={<ConnectedHomepage pageComponent={LiquidTokenPage} />}
            />
          </Route>
        </Route>

        <Route
          path="transaction"
          element={<ConnectedHomepage pageComponent={TransactionSummary} />}
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>

      <Alerts />
    </>
  );
};

function mapStateToProps(state: AppState) {
  const { bootstrapLoading, error, chainId } = bootstrapSelector(state);
  const directory = state.directory?.address;

  return {
    provider: providerSelector(state),
    directory,
    bootstrapLoading,
    error,
    chainId,
  };
}

function mapDispatchToProps(dispatch: any) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
