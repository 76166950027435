import { useCallback } from "react";
import { Button, Modal } from "react-bootstrap";
import { Form } from "react-final-form";
import { promiseListener } from "../../store";
import {
  FINALIZE_INVESTMENT_ROUNDS,
  FINALIZE_INVESTMENT_ROUNDS_ERROR,
  INVESTMENT_ROUNDS_FINALIZED,
} from "../../store/actions";

interface FinalizeRoundsFormProps {
  show: boolean;
  handleClose: () => void;
  projectToken: string;
  projectSymbol: string;
}

const FinalizeRoundsForm = ({
  show,
  handleClose,
  projectToken,
  projectSymbol,
}: FinalizeRoundsFormProps) => {
  const setPayload = useCallback(
    (action: any) => {
      return {
        ...action,
        projectToken,
      };
    },
    [projectToken]
  );

  const asyncFuncConfig = {
    start: FINALIZE_INVESTMENT_ROUNDS,
    resolve: INVESTMENT_ROUNDS_FINALIZED,
    reject: FINALIZE_INVESTMENT_ROUNDS_ERROR,
    setPayload,
  };

  const asyncFunction = promiseListener.createAsyncFunction(asyncFuncConfig);

  return (
    <Modal size="lg" show={show} onHide={handleClose} centered>
      <Modal.Header>
        <Modal.Title>Lock current list of Rounds and Interfaces</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form
          onSubmit={asyncFunction.asyncFunction}
          render={({ handleSubmit, submitting }) => (
            <form
              onSubmit={(event) => {
                return handleSubmit(event)!
                  .then(() => handleClose())
                  .catch(() => handleClose());
              }}
              noValidate
              className="needs-validation d-grid gap-3"
            >
              <div style={{ margin: "auto", width: "60%" }}>
                <div className="text-center">
                  <b>Project Token {projectSymbol}</b>
                </div>
                <div>
                  Before validating, make sure that :
                  <ol>
                    <li>you created the Charged Tokens for ALL the rounds</li>
                    <li>
                      you created the Interfaces for ALL the Charged Tokens
                    </li>
                  </ol>
                </div>
              </div>
              <Button
                type="submit"
                variant="primary"
                size="lg"
                disabled={submitting}
              >
                Lock current list of Rounds and Interfaces
              </Button>
            </form>
          )}
        ></Form>
      </Modal.Body>
    </Modal>
  );
};

export default FinalizeRoundsForm;
