import React from "react";
import { Form } from "react-bootstrap";
import { FieldMetaState } from "react-final-form";

interface TextInputProps {
  label: string;
  placeholder?: string;
  meta: FieldMetaState<any>;
  name: string;
  value?: string;
  onChange: (event: any) => void;
  onBlur: (event: any) => void;
  onFocus: (event: any) => void;
}

const TextInput = ({
  name,
  label,
  placeholder,
  value,
  meta,
  onChange,
  onBlur,
  onFocus,
}: TextInputProps) => {
  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        type="text"
        id={name}
        name={name}
        className={`text-end has-validation ${
          meta.touched === true &&
          (meta.error !== undefined ? "is-invalid" : "is-valid")
        }`}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
      />
      {meta.error !== undefined && meta.touched === true && (
        <Form.Control.Feedback type="invalid">
          {meta.error}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

export default TextInput;
