import { DocumentNode } from "graphql";
import { Action } from "redux";
import {
  ChargedToken,
  Delegable,
  Directory,
  Interface,
  StakingEntry,
  UserBalancesEntry,
  UserClaimsEntry,
} from "../../types";

export const INIT_CONTRACTS_STATE = "INIT_CONTRACTS_STATE";

export const CONTRACTS_LOADED = "CONTRACTS_LOADED";

export const INIT_DIRECTORY = "INIT_DIRECTORY";
export const INIT_CHARGED_TOKENS = "INIT_CHARGED_TOKENS";
export const INIT_INTERFACES = "INIT_INTERFACES";
export const INIT_DELEGABLES = "INIT_DELEGABLES";
export const INIT_BALANCES = "INIT_BALANCES";
export const INIT_STAKING = "INIT_STAKING";
export const INIT_CLAIMS = "INIT_CLAIMS";
export const INIT_SUBSCRIPTIONS = "INIT_SUBSCRIPTIONS";

export const SET_DIRECTORY = "SET_DIRECTORY";
export interface SetDirectoryAction extends Action<"SET_DIRECTORY"> {
  data: Directory;
}

export const SET_CHARGED_TOKEN = "SET_CHARGED_TOKEN";
export interface SetChargedTokenAction extends Action<"SET_CHARGED_TOKEN"> {
  data: ChargedToken;
}

export const REMOVE_CHARGED_TOKEN = "REMOVE_CHARGED_TOKEN";
export interface RemoveChargedTokenAction
  extends Action<"REMOVE_CHARGED_TOKEN"> {
  address: string;
}

export const REMOVE_INTERFACE = "REMOVE_INTERFACE";
export interface RemoveInterfaceAction extends Action<"REMOVE_INTERFACE"> {
  address: string;
}

export const SET_INTERFACE = "SET_INTERFACE";
export interface SetInterfaceAction extends Action<"SET_INTERFACE"> {
  address: string;
  data: Interface;
}

export const SET_DELEGABLE = "SET_DELEGABLE";
export interface SetDelegableAction extends Action<"SET_DELEGABLE"> {
  address: string;
  data: Delegable;
}

export const SET_BALANCES = "SET_BALANCES";
export interface SetBalancesAction extends Action<"SET_BALANCES"> {
  balances: UserBalancesEntry[];
}

export const SET_CLAIMS = "SET_CLAIMS";
export interface SetClaimsAction extends Action<"SET_CLAIMS"> {
  claims: Record<string, UserClaimsEntry>;
}

export const SET_STAKING = "SET_STAKING";
export interface SetStakingAction extends Action<"SET_STAKING"> {
  address: string;
  staking: StakingEntry;
}

export const SUBSCRIBE_UPDATES = "SUBSCRIBE_UPDATES";
export interface SubscribeUpdatesAction extends Action<"SUBSCRIBE_UPDATES"> {
  query: DocumentNode;
  address?: string;
  variables?: Record<string, any>;
}

export const UNSUBSCRIBE_UPDATES = "UNSUBSCRIBE_UPDATES";
export interface UnsubscribeUpdatesAction
  extends Action<"UNSUBSCRIBE_UPDATES"> {
  operation: string;
  address?: string;
}

export const UPDATE_CLAIMS = "UPDATE_CLAIMS";
export interface UpdateClaimsAction extends Action<"UPDATE_CLAIMS"> {
  address: string;
}

export const UPDATE_CURRENT_REWARDS = "UPDATE_CURRENT_REWARDS";
export interface UpdateCurrentRewardsAction
  extends Action<"UPDATE_CURRENT_REWARDS"> {
  address: string;
}

export const WEBSOCKET_CLOSED = "WEBSOCKET_CLOSED";
export const WEBSOCKET_CONNECTED = "WEBSOCKET_CONNECTED";
