import { ethers } from "ethers";
import { connect, DispatchProp } from "react-redux";
import { AnyAction } from "redux";
import {
  bootstrapSelector,
  createCTSelector,
  providerSelector,
} from "../../store/selectors";
import { AppState } from "../../types";
import { FlexScreen } from "../common";
import LoadingScreen from "../common/LoadingScreen";
import LiquidTokenScreens from "./LiquidTokenScreens";

interface LiquidTokenPageProps extends DispatchProp<AnyAction> {
  tokenAddress: string;
  tab: string;
  contractsLoading: boolean;
  foundContract: boolean;

  provider?: ethers.providers.Web3Provider;
}

const LiquidTokenPageWrapper = (props: LiquidTokenPageProps) => {
  const { tokenAddress, tab, provider, contractsLoading, foundContract } =
    props;

  if (!provider) {
    <LoadingScreen msg="Warming up..." />;
  }

  if (contractsLoading) {
    return <LoadingScreen msg="Loading projects list..." />;
  }

  if (!foundContract) {
    return (
      <FlexScreen>
        <h3 className="text-center">Unknown contract address</h3>
      </FlexScreen>
    );
  }

  return <LiquidTokenScreens tokenAddress={tokenAddress} tab={tab} />;
};

function mapStateToProps(
  state: AppState,
  ownProps: Pick<LiquidTokenPageProps, "tokenAddress">
) {
  const { contractsLoading } = bootstrapSelector(state);
  const ct = createCTSelector(ownProps.tokenAddress)(state);

  console.warn("looking for contract", ownProps, "got", ct);

  return {
    ...ownProps,
    contractsLoading,
    foundContract: ct !== undefined,
    provider: providerSelector(state),
  };
}

export default connect(mapStateToProps)(LiquidTokenPageWrapper);
