import { Transaction } from "ethers";
import React from "react";
import { Action } from "redux";

export const SET_TRANSACTION = "SET_TRANSACTION";
export interface SetTransactionAction extends Action<"SET_TRANSACTION"> {
  navigate: (path: string) => void;
  index?: number;
  total?: number;
  msg?: string;
  summary?: React.ReactElement;
  tx?: Transaction;
  returnTo?: string;
}

export const CLEAR_TRANSACTION = "CLEAR_TRANSACTION";
