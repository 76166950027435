import { useState } from "react";
import { Button, Card, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LOCK_INTERFACE } from "../../../store/actions";
import {
  createCTSelector,
  createInterfaceSelector,
  userAddressSelector,
} from "../../../store/selectors";
import { AppState, DateWrapper } from "../../../types";
import { EMPTY_ADDRESS } from "../../../utils";
import { AddInterfaceForm, StartInterfaceForm } from "../../admin";
import LiquidTokenScheduleView from "./LiquidTokenScheduleView";

interface VestingScheduleProps {
  tokenAddress: string;
  account: string;
  address: string;
  owner: string;
  interfaceAddress: string;
  dateLaunch: DateWrapper;
  isInterfaceProjectTokenLocked: boolean;

  lockInterface: (
    tokenAddress: string,
    navigate: (path: string) => void
  ) => void;
}

export const VestingSchedule = ({
  tokenAddress,
  account,
  owner,
  interfaceAddress,
  dateLaunch,
  isInterfaceProjectTokenLocked,
  lockInterface,
}: VestingScheduleProps) => {
  const navigate = useNavigate();

  const [showCreateInterfaceForm, setShowCreateInterfaceForm] = useState(false);
  const [showSetStartForm, setShowSetStartForm] = useState(false);

  return (
    <Card>
      <Card.Header as="h5" className="text-center">
        Vesting schedule
      </Card.Header>
      <Card.Body>
        <LiquidTokenScheduleView tokenAddress={tokenAddress} />

        {owner === account && (
          <Col className="pt-4 d-grid d-flex gap-3 justify-content-evenly align-items-stretch">
            {interfaceAddress === EMPTY_ADDRESS && (
              <Button
                type="button"
                size="lg"
                className="col-2"
                onClick={() => setShowCreateInterfaceForm(true)}
              >
                <p className="bi bi-diagram-2 h1 mb-2"></p>
                Create interface
              </Button>
            )}

            {interfaceAddress !== EMPTY_ADDRESS &&
              (dateLaunch.isSet !== true || dateLaunch.isFuture()) && (
                <Button
                  type="button"
                  size="lg"
                  className="col-2"
                  onClick={() => setShowSetStartForm(true)}
                >
                  <p className="bi bi-calendar-check h1 mb-2"></p>
                  Set start (TGE)
                </Button>
              )}

            {interfaceAddress === EMPTY_ADDRESS && (
              <AddInterfaceForm
                show={showCreateInterfaceForm}
                handleClose={() => setShowCreateInterfaceForm(false)}
                tokenAddress={tokenAddress}
              />
            )}

            {(dateLaunch.isSet !== true || dateLaunch.isFuture()) && (
              <>
                <StartInterfaceForm
                  show={showSetStartForm}
                  handleClose={() => setShowSetStartForm(false)}
                  tokenAddress={tokenAddress}
                />

                {!isInterfaceProjectTokenLocked &&
                  interfaceAddress !== EMPTY_ADDRESS && (
                    <Button
                      type="button"
                      variant="danger"
                      size="lg"
                      className="col-2"
                      onClick={() => lockInterface(tokenAddress, navigate)}
                    >
                      <p className="h1 mb-2">
                        <i className="bi bi-file-earmark-lock2"></i>
                      </p>
                      Lock interface
                    </Button>
                  )}
              </>
            )}
          </Col>
        )}
      </Card.Body>
    </Card>
  );
};

function mapStateToProps(
  state: AppState,
  ownProps: Pick<VestingScheduleProps, "tokenAddress">
) {
  const ct = createCTSelector(ownProps.tokenAddress)(state);
  const iface = createInterfaceSelector(ct.interfaceProjectToken)(state);

  return {
    ...ownProps,
    account: userAddressSelector(state),
    address: ct.address,
    owner: ct.owner,
    interfaceAddress: ct.interfaceProjectToken,
    isInterfaceProjectTokenLocked: ct.isInterfaceProjectTokenLocked,
    dateLaunch: iface !== undefined ? iface.dateLaunch : new DateWrapper(),
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    lockInterface: (tokenAddress: string, navigate: (path: string) => void) =>
      dispatch({
        type: LOCK_INTERFACE,
        tokenAddress,
        navigate,
      }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VestingSchedule);
