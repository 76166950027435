import { AppState, DateWrapper } from "../../types";
import {
  EMPTY_ADDRESS,
  buildClaimsState,
  getHodlRewards,
  getUpdatedRewardPerShare1e18,
} from "../../utils";
import {
  REMOVE_CHARGED_TOKEN,
  REMOVE_INTERFACE,
  REMOVE_LT_FROM_STATE,
  RemoveChargedTokenAction,
  RemoveInterfaceAction,
  RemoveLTFromStateAction,
  SET_BALANCES,
  SET_CHARGED_TOKEN,
  SET_CLAIMS,
  SET_DELEGABLE,
  SET_DIRECTORY,
  SET_INTERFACE,
  SET_STAKING,
  SetBalancesAction,
  SetChargedTokenAction,
  SetClaimsAction,
  SetDelegableAction,
  SetDirectoryAction,
  SetInterfaceAction,
  SetStakingAction,
  UPDATE_CLAIMS,
  UPDATE_CURRENT_REWARDS,
  UpdateClaimsAction,
  UpdateCurrentRewardsAction,
  WEBSOCKET_CLOSED,
  WEBSOCKET_CONNECTED,
} from "../actions";
import {
  bootstrapReducer,
  initialState as initialBootstrapState,
} from "./bootstrap";
import {
  initialState as initialTransactionState,
  transactionReducer,
} from "./transaction";

export const initialState: AppState = {
  bootstrap: initialBootstrapState,
  transaction: initialTransactionState,
  connected: false,
  directory: {
    address: "",
    owner: "",
    whitelistedProjectOwners: [],
    projects: [],
    projectRelatedToLT: [],
  },
  projects: [],
  chargedTokens: {},
  interfaces: {},
  delegableToLTs: {},
  balances: {},
  claims: {},
  staking: {},
};

function setDirectory(
  prevState: AppState,
  action: SetDirectoryAction
): AppState {
  return {
    ...prevState,
    directory: action.data,
  };
}

function setChargedToken(
  prevState: AppState,
  action: SetChargedTokenAction
): AppState {
  return {
    ...prevState,
    chargedTokens: {
      ...prevState.chargedTokens,
      [action.data.address]: action.data,
    },
  };
}

function removeChargedToken(
  prevState: AppState,
  action: RemoveChargedTokenAction
): AppState {
  const chargedTokens = Object.assign(
    {},
    ...Object.entries(prevState.chargedTokens)
      .filter(([key]) => key !== action.address)
      .map(([key, value]) => ({ [key]: value }))
  );

  return {
    ...prevState,
    chargedTokens,
  };
}

function setInterface(
  prevState: AppState,
  action: SetInterfaceAction
): AppState {
  return {
    ...prevState,
    interfaces: {
      ...prevState.interfaces,
      [action.data.address]: action.data,
    },
  };
}

function removeInterface(
  prevState: AppState,
  action: RemoveInterfaceAction
): AppState {
  const interfaces = Object.assign(
    {},
    ...Object.entries(prevState.interfaces)
      .filter(([key]) => key !== action.address)
      .map(([key, value]) => ({ [key]: value }))
  );

  return {
    ...prevState,
    interfaces,
  };
}

function setDelegable(
  prevState: AppState,
  action: SetDelegableAction
): AppState {
  return {
    ...prevState,
    delegableToLTs: {
      ...prevState.delegableToLTs,
      [action.data.address]: action.data,
    },
  };
}

function setBalances(prevState: AppState, action: SetBalancesAction) {
  const balancesToUpdate = Object.assign(
    {},
    ...action.balances.map((balance) => ({
      [balance.address]: balance,
    }))
  );

  return {
    ...prevState,
    balances: {
      ...prevState.balances,
      ...balancesToUpdate,
    },
  };
}

function setClaims(prevState: AppState, action: SetClaimsAction) {
  return {
    ...prevState,
    claims: {
      ...prevState.claims,
      ...action.claims,
    },
  };
}

function setStaking(prevState: AppState, action: SetStakingAction) {
  return {
    ...prevState,
    staking: {
      ...prevState.staking,
      [action.address]: action.staking,
    },
  };
}

function updateCurrentRewards(
  prevState: AppState,
  action: UpdateCurrentRewardsAction
): AppState {
  if (prevState.balances[action.address] !== undefined) {
    const copy = { ...prevState };

    const ct = copy.chargedTokens[action.address];

    const currentDate = DateWrapper.now();

    const currentRewards = getHodlRewards(
      ct,
      copy.balances[action.address],
      getUpdatedRewardPerShare1e18(ct, currentDate.blockchainTimestamp)
    );

    copy.staking[action.address] = { currentRewards };

    return copy;
  }

  return prevState;
}

function updateClaims(
  prevState: AppState,
  action: UpdateClaimsAction
): AppState {
  if (
    prevState.chargedTokens[action.address].interfaceProjectToken ===
    EMPTY_ADDRESS
  )
    return prevState;

  const copy = {
    ...prevState,
    claims: {
      ...prevState.claims,
    },
  };

  copy.claims[action.address] = buildClaimsState(
    copy.interfaces[copy.chargedTokens[action.address].interfaceProjectToken],
    copy.chargedTokens[action.address],
    copy.balances[action.address]
  );

  return copy;
}

function onWebsocketConnected(prevState: AppState) {
  return {
    ...prevState,
    connected: true,
  };
}

function onWebsocketClosed(prevState: AppState) {
  return {
    ...prevState,
    connected: false,
  };
}

function removeLTFromState(
  prevState: AppState,
  { tokenAddress, interfaceAddress, projectToken }: RemoveLTFromStateAction
) {
  const copy = {
    ...prevState,
    chargedTokens: { ...prevState.chargedTokens },
    interfaces: { ...prevState.interfaces },
    delegableToLTs: { ...prevState.delegableToLTs },
  };

  delete copy.chargedTokens[tokenAddress];

  if (interfaceAddress !== undefined) delete copy.interfaces[interfaceAddress];
  if (projectToken !== undefined) {
    let projectTokenStillReferenced = false;
    for (const iface of Object.values(copy.interfaces)) {
      if (iface.projectToken === projectToken) {
        projectTokenStillReferenced = true;
        break;
      }
    }

    if (!projectTokenStillReferenced) {
      console.log("Removing delegable since not used anymore", projectToken);
      delete copy.delegableToLTs[projectToken];
    }
  }

  return copy;
}

export function contractsReducer(
  prevState: AppState | undefined,
  action: any
): AppState {
  if (!prevState) return initialState;

  switch (action.type) {
    case SET_DIRECTORY:
      return setDirectory(prevState, action);

    case SET_CHARGED_TOKEN:
      return setChargedToken(prevState, action);

    case REMOVE_CHARGED_TOKEN:
      return removeChargedToken(prevState, action);

    case SET_INTERFACE:
      return setInterface(prevState, action);

    case REMOVE_INTERFACE:
      return removeInterface(prevState, action);

    case SET_DELEGABLE:
      return setDelegable(prevState, action);

    case SET_BALANCES:
      return setBalances(prevState, action);

    case SET_CLAIMS:
      return setClaims(prevState, action);

    case SET_STAKING:
      return setStaking(prevState, action);

    case UPDATE_CURRENT_REWARDS:
      return updateCurrentRewards(prevState, action);

    case UPDATE_CLAIMS:
      return updateClaims(prevState, action);

    case WEBSOCKET_CONNECTED:
      return onWebsocketConnected(prevState);

    case WEBSOCKET_CLOSED:
      return onWebsocketClosed(prevState);

    case REMOVE_LT_FROM_STATE:
      return removeLTFromState(prevState, action);

    default:
      return prevState;
  }
}

export function appReducer(
  prevState: AppState | undefined,
  action: any
): AppState {
  return {
    ...contractsReducer(prevState, action),
    bootstrap: bootstrapReducer(prevState?.bootstrap, action),
    transaction: transactionReducer(prevState?.transaction, action),
  };
}
