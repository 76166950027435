import { ethers } from "ethers";
import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { LIST_ETH_ACCOUNTS } from "../../store/actions";
import { bootstrapSelector } from "../../store/selectors";
import { AppState } from "../../types";

interface NewMetamaskBootstrapProps {
  account: string;
  connecting: boolean;
  error?: string | React.ReactElement;
  provider?: ethers.providers.Web3Provider;
  requestUserAccount: () => void;
  children: React.ReactElement;
}

const NewMetamaskBootstrap = (props: NewMetamaskBootstrapProps) => {
  const { account, connecting, error, provider, requestUserAccount, children } =
    props;

  const [show, setShow] = useState(false);

  const showPopup = () => {
    setShow(true);
    requestUserAccount();
  };
  const hidePopup = () => setShow(false);

  useEffect(() => {
    if (!provider && !connecting && error === undefined) {
      requestUserAccount();
    }
  }, [provider, connecting, error]);

  if (account !== "") {
    return <>{children}</>;
  }

  return (
    <>
      {account === "" && (
        <Button variant="warning" onClick={showPopup}>
          Connect your wallet
        </Button>
      )}

      <Modal show={show} onHide={hidePopup}>
        <Modal.Header closeButton>
          <Modal.Title>Connect your wallet</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {error === undefined && (provider !== undefined || connecting) && (
            <>
              <h3 className="mb-5">
                Please allow the app in MetaMask to continue...
              </h3>
              <Spinner animation="border" />
            </>
          )}

          {error !== undefined &&
            error !== "Couldn't read MetaMask account" && (
              <>
                {typeof error === "string" ? (
                  <h3 className="text-center">{error}</h3>
                ) : (
                  error
                )}
              </>
            )}

          {error !== undefined && error === "Couldn't read MetaMask account" && (
            <>
              <h3 className="mb-5">Couldn't access your wallet</h3>

              <h5 className="mb-5">
                Please click the button below to allow MetaMask access.
              </h5>

              <button
                type="button"
                className="btn btn-lg btn-primary"
                onClick={requestUserAccount}
                disabled={connecting}
              >
                Enable Metamask
              </button>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

function mapStateToProps(state: AppState) {
  return bootstrapSelector(state);
}

function mapDispatchToProps(dispatch: any) {
  return {
    requestUserAccount: () => dispatch({ type: LIST_ETH_ACCOUNTS }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewMetamaskBootstrap);
