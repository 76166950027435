/* eslint-disable max-lines */
import { DocumentNode, Kind, OperationDefinitionNode } from "graphql";
import { channel } from "redux-saga";
import { call, put, select, take, takeEvery } from "redux-saga/effects";
import { client } from "../../api";
import {
  GET_ALL_CHARGED_TOKENS,
  GET_ALL_DELEGABLE_TOKENS,
  GET_ALL_INTERFACE_PROJECTS,
  GET_CHARGED_TOKEN,
  GET_DELEGABLE_TOKEN,
  GET_DIRECTORY,
  GET_INTERFACE_PROJECT,
  GET_USER_BALANCE,
  GET_USER_BALANCES,
  SUBSCRIBE_CHARGED_TOKEN,
  SUBSCRIBE_DELEGABLE_TOKEN,
  SUBSCRIBE_DIRECTORY,
  SUBSCRIBE_INTERFACE_PROJECT,
  SUBSCRIBE_USER_BALANCES,
  gqlChargedToken,
  gqlDelegableToLT,
  gqlDirectory,
  gqlInterfaceProjectToken,
  gqlUserBalancesEntry,
} from "../../graphql";
import { AppState, Directory, Interface, UserClaimsEntry } from "../../types";
import {
  EMPTY_ADDRESS,
  buildBalanceState,
  buildChargedTokenState,
  buildClaimsState,
  buildDelegableState,
  buildInterfaceState,
  buildStakingState,
} from "../../utils";
import {
  BOOTSTRAP_ERROR,
  CONTRACTS_LOADED,
  INIT_BALANCES,
  INIT_CHARGED_TOKENS,
  INIT_CLAIMS,
  INIT_CONTRACTS_STATE,
  INIT_DELEGABLES,
  INIT_DIRECTORY,
  INIT_INTERFACES,
  INIT_STAKING,
  INIT_SUBSCRIPTIONS,
  REMOVE_CHARGED_TOKEN,
  REMOVE_INTERFACE,
  SET_BALANCES,
  SET_CHARGED_TOKEN,
  SET_CLAIMS,
  SET_DELEGABLE,
  SET_DIRECTORY,
  SET_INTERFACE,
  SET_NETWORK,
  SET_STAKING,
  SUBSCRIBE_UPDATES,
  SubscribeUpdatesAction,
  UNSUBSCRIBE_UPDATES,
  UPDATE_CURRENT_REWARDS,
} from "../actions";
import {
  appStateSelector,
  bootstrapSelector,
  directorySelector,
} from "../selectors";

function* loadNetwork(): any {
  // detecting network
  const { provider } = yield select(bootstrapSelector);

  const { chainId } = yield call([provider, provider.getNetwork]);
  const strChainId = `${chainId}`;

  yield put({ type: SET_NETWORK, network: strChainId });
  yield put({ type: INIT_DIRECTORY });
}

function* loadDirectory(): any {
  const { chainId } = yield select(bootstrapSelector);

  // loading directory for that network
  const response = yield call([client, client.query], {
    query: GET_DIRECTORY,
    variables: {
      chainId,
    },
  });
  const directoryData: gqlDirectory = response.data.Directory;

  if (directoryData === undefined || directoryData === null) {
    yield put({
      type: BOOTSTRAP_ERROR,
      error: "UNSUPPORTED NETWORK",
      chainId,
    });
    return;
  }

  yield put({
    type: SET_DIRECTORY,
    data: directoryData,
  });

  yield put({ type: INIT_CHARGED_TOKENS });
}

function* loadChargedTokens(): any {
  const { chainId } = yield select(bootstrapSelector);
  const { projectRelatedToLT }: Directory = yield select(directorySelector);

  // loading charged tokens for that network
  const response = yield call([client, client.query], {
    query: GET_ALL_CHARGED_TOKENS,
    variables: {
      chainId,
    },
  });

  const allChargedTokens: gqlChargedToken[] = response.data.allChargedTokens;

  for (const gqlCT of allChargedTokens) {
    const currentProjectEntry = projectRelatedToLT.find(
      (entry) => entry.key === gqlCT.address
    );

    if (currentProjectEntry === undefined) {
      console.warn(
        "Project entry not found in directory for CT",
        gqlCT.address,
        projectRelatedToLT
      );
      continue;
    }

    yield put({
      type: SET_CHARGED_TOKEN,
      data: buildChargedTokenState(currentProjectEntry.value, gqlCT),
    });
  }

  yield put({ type: INIT_INTERFACES });
}

function* loadInterfaces(): any {
  const { chainId } = yield select(bootstrapSelector);
  const state = yield select(appStateSelector);

  // loading interface contracts
  const response = yield call([client, client.query], {
    query: GET_ALL_INTERFACE_PROJECTS,
    variables: {
      chainId,
    },
  });

  const interfacesData: gqlInterfaceProjectToken[] =
    response.data.allInterfaceProjectTokens;

  for (const gqlInterface of interfacesData) {
    const data = buildInterfaceState(
      gqlInterface,
      Number(
        state.chargedTokens[gqlInterface.liquidityToken].durationLinearVesting
      )
    );

    yield put({
      type: SET_INTERFACE,
      data,
    });
  }

  yield put({ type: INIT_DELEGABLES });
}

function* loadDelegables(): any {
  const { chainId } = yield select(bootstrapSelector);

  // loading projects tokens
  const response = yield call([client, client.query], {
    query: GET_ALL_DELEGABLE_TOKENS,
    variables: {
      chainId,
    },
  });

  const delegableDatas: gqlDelegableToLT[] = response.data.allDelegableToLTs;

  for (const gqlDelegable of delegableDatas) {
    yield put({
      type: SET_DELEGABLE,
      data: buildDelegableState(gqlDelegable),
    });
  }

  yield put({ type: INIT_BALANCES });
  yield put({ type: CONTRACTS_LOADED });
  yield put({ type: INIT_SUBSCRIPTIONS });
}

function* initSubscriptions(): any {
  const { chainId } = yield select(bootstrapSelector);

  const state: AppState = yield select(appStateSelector);

  yield put({
    type: SUBSCRIBE_UPDATES,
    address: state.directory.address,
    query: SUBSCRIBE_DIRECTORY,
    variables: { chainId },
  });

  for (const ct of Object.values(state.chargedTokens)) {
    yield put({
      type: SUBSCRIBE_UPDATES,
      address: ct.address,
      query: SUBSCRIBE_CHARGED_TOKEN,
      variables: { chainId, address: ct.address },
    });
  }

  for (const iface of Object.values(state.interfaces)) {
    yield put({
      type: SUBSCRIBE_UPDATES,
      address: iface.address,
      query: SUBSCRIBE_INTERFACE_PROJECT,
      variables: { chainId, address: iface.address },
    });
  }

  for (const delegable of Object.values(state.delegableToLTs)) {
    yield put({
      type: SUBSCRIBE_UPDATES,
      address: delegable.address,
      query: SUBSCRIBE_DELEGABLE_TOKEN,
      variables: { chainId, address: delegable.address },
    });
  }
}

function* loadBalances(): any {
  const { chainId, account } = yield select(bootstrapSelector);

  if (account === "") {
    console.warn("no account found for loading balances !");
    return;
  }

  // loading balances
  const response = yield call([client, client.query], {
    query: GET_USER_BALANCES,
    variables: {
      chainId,
      user: account,
    },
  });

  const balancesData: gqlUserBalancesEntry[] = response.data.userBalances;
  const balances = balancesData.map(buildBalanceState);

  yield put({
    type: SET_BALANCES,
    balances,
  });

  yield put({
    type: "SUBSCRIBE_UPDATES",
    query: SUBSCRIBE_USER_BALANCES,
    variables: {
      chainId,
      user: account,
    },
  });

  yield put({ type: INIT_STAKING });
  yield put({ type: INIT_CLAIMS });
}

function* initStaking(): any {
  const state: AppState = yield select(appStateSelector);

  for (const ct of Object.values(state.chargedTokens)) {
    yield put({
      type: SET_STAKING,
      address: ct.address,
      staking: buildStakingState(
        state.chargedTokens[ct.address],
        state.balances[ct.address]
      ),
    });
  }
}

function* initClaims(): any {
  const state: AppState = yield select(appStateSelector);

  for (const ct of Object.values(state.chargedTokens)) {
    if (ct.interfaceProjectToken !== EMPTY_ADDRESS) {
      yield put({
        type: SET_CLAIMS,
        address: ct.address,
        claims: {
          [ct.address]: buildClaimsState(
            state.interfaces[ct.interfaceProjectToken],
            ct,
            state.balances[ct.address]
          ),
        },
      });
    }
  }
}

type subscriptionData =
  | gqlDirectory
  | gqlChargedToken
  | gqlInterfaceProjectToken
  | gqlDelegableToLT
  | gqlUserBalancesEntry;

const subscriptions: Record<string, Record<string, any>> = {};

function getOperationName(node: DocumentNode): string {
  const foundDefinition = node.definitions.find(
    (def) => def.kind === Kind.OPERATION_DEFINITION
  ) as OperationDefinitionNode | undefined;

  if (foundDefinition === undefined) {
    throw new Error(`Operation name not found for query ${node}`);
  }
  if (foundDefinition.name === undefined) {
    throw new Error(`Operation found with no name for query ${node}`);
  }

  return foundDefinition.name.value;
}

function* subscribeUpdates(action: SubscribeUpdatesAction) {
  const { provider } = yield select(bootstrapSelector);
  const { chainId } = yield call([provider, provider.getNetwork]);
  const updateChannel = channel<subscriptionData>();

  console.warn("Subscribing to updates", action);

  const subscription = client.subscribe({
    query: action.query,
    variables: {
      chainId,
      ...(action.variables !== undefined ? action.variables : {}),
    },
    fetchPolicy: "no-cache",
  });

  subscription.subscribe((response) => {
    console.warn("Subscription got update :", action, response);
    updateChannel.put(response.data);
  });

  if (action.address !== undefined) {
    const operationName = getOperationName(action.query);
    if (subscriptions[operationName] === undefined) {
      subscriptions[operationName] = {};
    }
    subscriptions[operationName][action.address] = subscription;
  }

  while (true) {
    const data: subscriptionData = yield take(updateChannel);
    console.warn("Got update from backend !", data);
    yield call(updateState, action, data);
  }
}

export function* updateState(action: SubscribeUpdatesAction, data: any) {
  const state: AppState = yield select(appStateSelector);

  switch (action.query) {
    case SUBSCRIBE_DIRECTORY:
      yield call(updateDirectory, action, state, data.Directory);
      break;
    case SUBSCRIBE_CHARGED_TOKEN:
      yield call(updateChargedToken, action, state, data.ChargedToken);
      break;
    case SUBSCRIBE_INTERFACE_PROJECT:
      yield call(updateInterface, action, state, data.InterfaceProjectToken);
      break;
    case SUBSCRIBE_DELEGABLE_TOKEN:
      yield put({
        type: SET_DELEGABLE,
        data: buildDelegableState(data.DelegableToLT),
      });
      break;
    case SUBSCRIBE_USER_BALANCES:
      yield call(updateBalance, state, data.userBalances);
      break;

    default:
      throw new Error(`Unknown subscription query : ${action.query}`);
  }
}

function* updateDirectory(
  action: SubscribeUpdatesAction,
  state: AppState,
  data: gqlDirectory
): any {
  const { account } = yield select(bootstrapSelector);

  const addedCT: string[] = data.directory.filter(
    (address) => state.chargedTokens[address] === undefined
  );
  const removedCT: string[] = Object.keys(state.chargedTokens).filter(
    (address) => !data.directory.includes(address)
  );

  // removed CT
  for (const address of removedCT) {
    const ct = state.chargedTokens[address];
    if (ct.interfaceProjectToken !== EMPTY_ADDRESS) {
      console.log("detected removed CT with interface", ct.address);
      // TODO detect last reference to delegable before removing it and subscription
      yield put({
        type: UNSUBSCRIBE_UPDATES,
        operationName: getOperationName(SUBSCRIBE_INTERFACE_PROJECT),
        address: ct.interfaceProjectToken,
      });

      yield put({
        type: REMOVE_INTERFACE,
        address: ct.interfaceProjectToken,
      });
    }

    yield put({
      type: UNSUBSCRIBE_UPDATES,
      operationName: getOperationName(SUBSCRIBE_CHARGED_TOKEN),
      address,
    });

    yield put({
      type: REMOVE_CHARGED_TOKEN,
      address,
    });
  }

  const updatedState = yield select();

  // added CT
  for (const address of addedCT) {
    console.log("detected added ct");

    let response: any;

    if (account !== "") {
      response = yield call([client, client.query], {
        query: GET_USER_BALANCE,
        variables: {
          chainId: action.variables!.chainId,
          user: account,
          address,
        },
      });

      if (response.data !== null && response.data.UserBalance !== null) {
        yield put({
          type: SET_BALANCES,
          balances: [buildBalanceState(response.data.UserBalance)],
        });

        if (
          updatedState.chargedTokens[address].interfaceProjectToken !==
          EMPTY_ADDRESS
        ) {
          yield put({
            type: SET_CLAIMS,
            claims: {
              [address]: buildClaimsState(
                updatedState.interfaces[
                  updatedState.chargedTokens[address].interfaceProjectToken
                ],
                updatedState.chargedTokens[address],
                updatedState.balances[address]
              ),
            },
          });
        }
      }
    }

    response = yield call([client, client.query], {
      query: GET_CHARGED_TOKEN,
      variables: {
        chainId: action.variables!.chainId,
        address,
      },
    });

    const newChargedToken: gqlChargedToken = response.data.ChargedToken;

    const projectName = data.projectRelatedToLT.find(
      (entry) => entry.key === address
    )!.value;

    console.log("updating token state from directory update");
    yield put({
      type: SET_CHARGED_TOKEN,
      data: buildChargedTokenState(projectName, newChargedToken),
    });

    yield put({
      type: SET_STAKING,
      address,
      staking: buildStakingState(
        updatedState.chargedTokens[address],
        updatedState.balances[address]
      ),
    });

    yield put({
      type: SUBSCRIBE_UPDATES,
      query: SUBSCRIBE_CHARGED_TOKEN,
      address,
      operationName: getOperationName(SUBSCRIBE_CHARGED_TOKEN),
      variables: {
        address,
      },
    });
  }

  for (const address of addedCT) {
    yield put({ type: UPDATE_CURRENT_REWARDS, address });
  }
}

function* updateChargedToken(
  action: SubscribeUpdatesAction,
  state: AppState,
  data: gqlChargedToken
): any {
  const { network } = yield select(bootstrapSelector);
  const chainId = Number(network);

  const address = data.address;

  const newCTState = buildChargedTokenState(
    state.chargedTokens[address].projectName,
    data
  );

  yield put({
    type: SET_CHARGED_TOKEN,
    data: newCTState,
  });

  yield put({
    type: SET_STAKING,
    address,
    staking: buildStakingState(newCTState, state.balances[address]),
  });

  let interfaceProjectToken: Interface | undefined =
    state.interfaces[data.interfaceProjectToken];

  const addedInterface =
    data.interfaceProjectToken !== EMPTY_ADDRESS &&
    interfaceProjectToken === undefined;

  if (addedInterface) {
    // first load of interface
    let response = yield call([client, client.query], {
      query: GET_INTERFACE_PROJECT,
      variables: {
        chainId,
        address: data.interfaceProjectToken,
      },
    });

    const interfaceData: gqlInterfaceProjectToken =
      response.data.InterfaceProjectToken;

    interfaceProjectToken = buildInterfaceState(
      interfaceData,
      newCTState.durationLinearVesting.blockchainTimestamp
    );

    yield put({
      type: SET_INTERFACE,
      data: interfaceProjectToken,
    });

    yield put({
      type: "SUBSCRIBE_UPDATES",
      address: newCTState.interfaceProjectToken,
      query: SUBSCRIBE_INTERFACE_PROJECT,
      variables: { chainId, address: newCTState.interfaceProjectToken },
    });

    // first load of delegable
    if (
      state.delegableToLTs[interfaceProjectToken.projectToken] === undefined
    ) {
      response = yield call([client, client.query], {
        query: GET_DELEGABLE_TOKEN,
        variables: {
          chainId,
          address: interfaceData.projectToken,
        },
      });

      const delegableData: gqlDelegableToLT = response.data.DelegableToLT;
      const delegable = buildDelegableState(delegableData);

      yield put({
        type: SET_DELEGABLE,
        data: delegable,
      });

      yield put({
        type: "SUBSCRIBE_UPDATES",
        address: delegable.address,
        query: SUBSCRIBE_DELEGABLE_TOKEN,
        variables: { chainId, address: delegable.address },
      });
    }
  }

  if (interfaceProjectToken !== undefined) {
    yield put({
      type: SET_CLAIMS,
      claims: {
        [address]: buildClaimsState(
          interfaceProjectToken,
          newCTState,
          state.balances[address]
        ),
      },
    });
  }
}

function* updateInterface(
  action: SubscribeUpdatesAction,
  state: AppState,
  data: gqlInterfaceProjectToken
) {
  const interfaceState = buildInterfaceState(
    data,
    state.chargedTokens[data.liquidityToken].durationLinearVesting
      .blockchainTimestamp
  );

  yield put({
    type: SET_CLAIMS,
    claims: {
      [data.liquidityToken]: buildClaimsState(
        interfaceState,
        state.chargedTokens[data.liquidityToken],
        state.balances[data.liquidityToken]
      ),
    },
  });

  yield put({
    type: SET_INTERFACE,
    address: action.address,
    data: interfaceState,
  });
}

export function* updateBalance(state: AppState, data: gqlUserBalancesEntry[]) {
  const balances = data.map(buildBalanceState);

  const claims: Record<string, UserClaimsEntry> = {};

  balances
    .filter(
      (balance) =>
        state.chargedTokens[balance.address] !== undefined &&
        state.chargedTokens[balance.address].interfaceProjectToken !==
          EMPTY_ADDRESS
    )
    .forEach(
      (balance) =>
        (claims[balance.address] = buildClaimsState(
          state.interfaces[
            state.chargedTokens[balance.address].interfaceProjectToken
          ],
          state.chargedTokens[balance.address],
          balance
        ))
    );

  yield put({ type: SET_BALANCES, balances });
  yield put({ type: SET_CLAIMS, claims });
}

export const contractsSagas = [
  takeEvery(INIT_CONTRACTS_STATE, loadNetwork),
  takeEvery(INIT_DIRECTORY, loadDirectory),
  takeEvery(INIT_CHARGED_TOKENS, loadChargedTokens),
  takeEvery(INIT_INTERFACES, loadInterfaces),
  takeEvery(INIT_DELEGABLES, loadDelegables),
  takeEvery(INIT_SUBSCRIPTIONS, initSubscriptions),
  takeEvery(INIT_BALANCES, loadBalances),
  takeEvery(INIT_STAKING, initStaking),
  takeEvery(INIT_CLAIMS, initClaims),
  takeEvery(SUBSCRIBE_UPDATES, subscribeUpdates),
];
