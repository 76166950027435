import { BigNumber } from "ethers";
import React, { useCallback } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Action } from "redux";
import { DepositLTAction, DEPOSIT_LT } from "../../../store/actions";
import { formatAmount } from "../../../utils";

export interface DepositSummaryProps {
  tokenAddress: string;
  amount: BigNumber;
  currentRewards: BigNumber;
  symbol: string;
  decimals: number;
  show: boolean;
  handleClose: () => void;
  doDeposit: (
    tokenAddress: string,
    amount: BigNumber,
    navigate: (path: string) => void
  ) => void;
}

export const DepositSummary = ({
  tokenAddress,
  amount,
  currentRewards,
  symbol,
  decimals,
  show,
  handleClose,
  doDeposit,
}: DepositSummaryProps) => {
  const navigate = useNavigate();

  const onSubmit = useCallback(() => {
    doDeposit(tokenAddress, amount, navigate);
    handleClose();
    navigate("/transaction");
  }, [amount, doDeposit, handleClose, navigate, tokenAddress]);

  const total = amount.add(currentRewards);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header>
        <Modal.Title>Deposit summary</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Col xs={{ span: 8, offset: 2 }}>
          <div className="d-grid gap-2">
            <p>
              <b>Deposit amount requested :</b> {formatAmount(amount, decimals)}{" "}
              {symbol}
            </p>
            <p>
              <b>Rewards :</b> {formatAmount(currentRewards, decimals)} {symbol}
            </p>
            <p>
              <b>Total :</b> {formatAmount(total, decimals)} {symbol}
            </p>
          </div>
        </Col>
        <Row>
          <Col className="d-grid gap-2">
            <button
              type="button"
              className="btn btn-lg btn-primary mt-3"
              onClick={onSubmit}
            >
              Confirm Deposit
            </button>
          </Col>
          <Col className="d-grid gap-2">
            <button
              type="button"
              className="btn btn-lg btn-secondary mt-3"
              onClick={handleClose}
            >
              Cancel
            </button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

function mapDispatchToProps(dispatch: (action: Action<any>) => void) {
  return {
    doDeposit: (
      tokenAddress: string,
      amount: BigNumber,
      navigate: (path: string) => void
    ) => {
      dispatch({
        type: DEPOSIT_LT,
        tokenAddress,
        amount,
        navigate,
      } as DepositLTAction);
    },
  };
}

export default connect(null, mapDispatchToProps)(DepositSummary);
