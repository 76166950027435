import { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import { client } from "../../api";
import { QUERY_HEALTH, gqlHealth } from "../../graphql";

const Health = () => {
  const [healthData, setHealthData] = useState<gqlHealth[]>([]);

  async function queryHealthAndUpdate() {
    const response = await client.query<{ health: gqlHealth[] }>({
      query: QUERY_HEALTH,
      variables: {},
      fetchPolicy: "no-cache",
    });

    setHealthData(response.data.health);
  }

  useEffect(() => {
    const timeout = setTimeout(queryHealthAndUpdate, 0);
    const interval = setInterval(queryHealthAndUpdate, 5000);

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, []);

  return (
    <Container>
      <h3>API status</h3>
      <Table>
        <thead>
          <tr>
            <th>#</th>
            <th>directory</th>
            <th>chainId</th>
            <th>name</th>
            <th>provider</th>
            <th>websocket</th>
            <th>worker</th>
            <th>restarts</th>
          </tr>
        </thead>
        <tbody>
          {healthData.length > 0 &&
            healthData.map(
              ({
                index,
                directory,
                chainId,
                name,
                providerStatus,
                wsStatus,
                workerStatus,
                restartCount,
              }) => (
                <tr key={index}>
                  <td>{index}</td>
                  <td>{directory}</td>
                  <td>{chainId}</td>
                  <td>{name}</td>
                  <td>{providerStatus}</td>
                  <td>{wsStatus}</td>
                  <td>{workerStatus}</td>
                  <td>{restartCount}</td>
                </tr>
              )
            )}
          {healthData.length === 0 && (
            <tr>
              <td colSpan={7} className="text-center">
                <i>There is no workers in the health check response.</i>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
};

export default Health;
