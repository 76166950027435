import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import {
  INIT_CONTRACTS_STATE,
  METAMASK_DETECTION_REQUESTED,
} from "../store/actions";
import { bootstrapSelector, directorySelector } from "../store/selectors";
import { AppState } from "../types";
import { FlexScreen } from "./common";

interface AppBootstrapProps {
  provider?: any;
  network: string;
  directory?: string;
  detectMetamask: () => void;
  initializeContracts: () => void;
}

const AppBootstrap: React.FC<AppBootstrapProps> = ({
  provider,
  directory,
  detectMetamask,
  initializeContracts,
  children,
}) => {
  useEffect(() => {
    if (provider === undefined) {
      detectMetamask();
    } else if (provider !== undefined && directory === undefined) {
      console.log("no directory");
      initializeContracts();
    } else {
      console.log("found directory :", directory);
    }
  }, [provider, directory]);

  if (provider === undefined) {
    return (
      <FlexScreen>
        <h3 className="mb-5">Detecting the network</h3>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </FlexScreen>
    );
  }

  return <>{children}</>;
};

function mapStateToProps(
  state: AppState,
  ownProps: Omit<
    AppBootstrapProps,
    "directory" | "detectMetamask" | "initializeContracts" | "network"
  >
) {
  const { provider, network } = bootstrapSelector(state);
  const directory = directorySelector(state);

  return {
    ...ownProps,
    provider,
    network,
    directory: directory?.address,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    detectMetamask: () => dispatch({ type: METAMASK_DETECTION_REQUESTED }),
    initializeContracts: () => dispatch({ type: INIT_CONTRACTS_STATE }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppBootstrap);
