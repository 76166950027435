import React, { useMemo } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { FieldMetaState } from "react-final-form";
import { formatAmountForInput } from "../../utils";

interface AmountInputProps {
  label?: string;
  placeholder?: string;
  max?: string;
  showMaxBtn?: boolean;
  meta: FieldMetaState<any>;
  symbol?: string;
  decimals: number;
  name: string;
  value?: string;
  onChange: (event: any) => void;
  onBlur: (event: any) => void;
  onFocus: (event: any) => void;
  children?: React.ReactElement;
}

const AmountInput = ({
  name,
  label,
  placeholder,
  value = "",
  max,
  showMaxBtn = true,
  meta,
  symbol,
  decimals,
  onChange,
  onBlur,
  onFocus,
  children,
}: AmountInputProps) => {
  const strMax = useMemo(() => {
    return max !== undefined ? formatAmountForInput(max, decimals) : "0";
  }, [decimals, max]);

  return (
    <Form.Group>
      {label !== undefined && <Form.Label>{label}</Form.Label>}
      <InputGroup hasValidation>
        {symbol !== undefined && <InputGroup.Text>{symbol}</InputGroup.Text>}
        <Form.Control
          name={name}
          className={`text-end ${
            meta.touched === true &&
            (meta.error !== undefined ? "is-invalid" : "is-valid")
          }`}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
        />
        {showMaxBtn && max !== undefined && (
          <Button
            type="button"
            variant="light"
            onClick={() => onChange(strMax)}
          >
            Max
          </Button>
        )}
        {children}
        {meta.error !== undefined && meta.touched === true && (
          <Form.Control.Feedback type="invalid">
            {meta.error}
          </Form.Control.Feedback>
        )}
      </InputGroup>
    </Form.Group>
  );
};

export default AmountInput;
